import React from 'react';
import { Grid, SemanticWIDTHSNUMBER } from 'semantic-ui-react';
import { GdsData } from '../../../../../api/types';
import './GdsInfoView.scss';
import '../../../../../styles/general.scss';

interface GdsDataParams {
  width: number;
  gdsInfos: GdsData[] | null | undefined;
}

export const GdsLabels: any = {
  SABRE: 'Sabre',
  APOLLO: 'Apollo',
  AMADEUS: 'Amadeus',
  WORLDSPAN: 'Worldspan',
  TRAVELPORT: 'Travelport',
};

export const FillingMethodLabels: any = {
  ATPCO: 'ATPCO',
  ABACUSDFF: 'Abacus DFF',
  AMADEUSFAREXPERT: 'Amadeus FareXpert',
  APOLLOAPF: 'Apollo APF',
  SABREDFF: 'Sabre DFF',
  WORLDSPANAPF: 'WorldSpan APF',
  COMPLEAT: 'COMPLEAT',
  NOLOAD: 'NO LOAD',
};

const GdsInfoView: React.FunctionComponent<GdsDataParams> = ({ width, gdsInfos }) => {
  return (
    <>
      {gdsInfos && gdsInfos.length > 0 && (
        <Grid className="gds-infos">
          {gdsInfos?.map((gdsInfo) => (
            <Grid.Column
              {...(width > 0 ? { width: width as SemanticWIDTHSNUMBER } : {})}
              className="gds-line"
              key={`${gdsInfo.Gds}_${gdsInfo.PricingIdCode}_${gdsInfo.FillingMethod}`}
            >
              {gdsInfo.Gds && <div className="display-header">{GdsLabels[gdsInfo.Gds]}</div>}
              {gdsInfo.PricingIdCode && (
                <div className="display-field">
                  <label>Pricing ID Code</label>
                  {gdsInfo.PricingIdCode}
                </div>
              )}
              {gdsInfo.FillingMethod && (
                <div className="display-field">
                  <label>Filling Method</label>
                  {FillingMethodLabels[gdsInfo.FillingMethod]}
                </div>
              )}
            </Grid.Column>
          ))}
        </Grid>
      )}
    </>
  );
};

export default GdsInfoView;
