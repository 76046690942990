import { isNumber } from 'lodash';

export default function (value?: number | null, wholeDecimalPlaces = 1) {
  if (!isNumber(value)) {
    return undefined;
  }

  if (Math.floor(value) === value) {
    return value.toFixed(wholeDecimalPlaces);
  }

  return value;
}
