import React, { useRef } from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';
import settings from '../../settings';
import './FileSelector.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

type SelectedFile = File | null;

interface FileSelectorProps {
  value: SelectedFile;
  onChange: (value: SelectedFile) => void;
  onRemoveButtonClick: () => void;
}

const FileSelector: React.FunctionComponent<FileSelectorProps> = ({
  value,
  onChange,
  onRemoveButtonClick,
}) => {
  const onDragPreventDefault = (event: DragEvent) => {
    event.preventDefault();
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const onChangeInternal = (selectedFile: SelectedFile) => {
    onChange(selectedFile);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    onChangeInternal(selectedFile);
  };

  const onFileDrop = (event: DragEvent) => {
    event.preventDefault();

    const file = event.dataTransfer?.files?.item(0) as SelectedFile;
    onChangeInternal(file);
  };

  return (
    <>
      {!value && (
        <Segment
          placeholder
          textAlign="center"
          onDragOver={onDragPreventDefault}
          onDragEnter={onDragPreventDefault}
          onDragLeave={onDragPreventDefault}
          onDrop={onFileDrop}
        >
          <div>
            <Icon name="upload" color={'blue'} size={'big'} />
          </div>
          <div>Drag and drop file here</div>
          <div>You can also</div>
          <Button className="browse" onClick={() => fileInputRef?.current?.click()}>
            Browse
          </Button>
        </Segment>
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept={settings.acceptFileExtensions.join(',')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileChange(e)}
        style={{ display: 'none' }}
      />
      {value?.name && (
        <div className="file-panel">
          <div>{value?.name}</div>
          <Button
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
              onChange(null);
              onRemoveButtonClick();
            }}
          >
            <MaterialIcon name="delete" />
          </Button>
        </div>
      )}
    </>
  );
};

export default FileSelector;
