import React from 'react';
import { Breadcrumb, Icon } from 'semantic-ui-react';
import { UIMatch, useMatches, useNavigate } from 'react-router-dom';
import './AomBreadcrumbs.scss';
import { contractSelector } from '../../store/contract/contractSelectors';
import { programSelector } from '../../store/program/programSelectors';
import { repositoryCurrentFileSelector } from '../../store/repository/repositorySelector';
import { BreadCrumbMap, Segment } from './types';
import { AomBreadcrumbSection } from './AomBreadcrumbSection';

export type BreadcrumbsProps = {
  showBack?: boolean;
}

export const AomBreadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({ showBack }) => {
  const matches = useMatches();
  const segments = matches.reduce((result: Segment[], match: UIMatch<unknown, unknown>) => {
    if (!breadcrumbContextMap.hasOwnProperty(match.id)) {
      return result;
    }
    result.push({ breadcrumb: breadcrumbContextMap[match.id], match: match })

    return result;
  }, []);

  const removedLastSegment = showBack ? segments.pop() : null;

  const navigate = useNavigate();

  return <>
    <Breadcrumb className="aom-breadcrumb">
      {
        segments.map((node: Segment, index: number) => {
          return <React.Fragment key={index + 'breadcrumb'}>
            {index > 0 && <Breadcrumb.Divider />}
            <AomBreadcrumbSection node={node}/>
          </React.Fragment>
        })
      }
    </Breadcrumb>
    {showBack && (
      <span className="aom-breadcrumb-back">
          <Icon name="arrow left" color="blue" link onClick={() => navigate(-1)} />
        {removedLastSegment?.breadcrumb.name}
        </span>
    )}
  </>;
};

const breadcrumbContextMap: BreadCrumbMap = {
  ["contracts"]: { name: 'Contracts', disableNavigation: false },
  ["contract"]: { name: null, selector: contractSelector, disableNavigation: false, paramsKey: 'contractId' },
  ["contracts_create"]: { name: 'Create new contract', disableNavigation: false },
  ["contract_edit"]: { name: 'Edit Contract', disableNavigation: false },
  ["contract_extend"]: { name: 'Extend Contract', disableNavigation: false },
  ["contract_versionComparison"]: { name: 'Version Comparison', disableNavigation: false },
  ["contract_update"]: { name: 'Update Contract', disableNavigation: false },
  ["contract_clone"]: { name: 'Clone Contract', disableNavigation: false },
  ["program_create"]: { name: 'Create Program', disableNavigation: false },
  ["program_view"]: { name: null, selector: programSelector, disableNavigation: false, paramsKey: 'programId' },
  ["program_edit"]: { name: 'Edit Program', disableNavigation: false },
  ["program_update"]: { name: 'Update Program', disableNavigation: false },
  ["program_clone"]: { name: 'Clone Program', disableNavigation: false },
  ["contract_changelogs"]: { name: 'Changelogs', disableNavigation: false },
  ["repository"]: { name: 'Repository', disableNavigation: false },
  ["repository_file"]: {
    name: 'File: ',
    disableNavigation: true,
    selector: repositoryCurrentFileSelector,
    paramsKey: 'fileName'
  },
  ["repository_file_contract"]: {
    name: 'Contract: ',
    disableNavigation: false,
    selector: contractSelector,
    paramsKey: 'contractId'
  },
  ["usage_report"]: { name: 'Usage Report', disableNavigation: true },
}

