import React from 'react';

export interface MaterialIconProps {
  name: string;
  // https://developers.google.com/fonts/docs/material_icons#sizing
  // https://developers.google.com/fonts/docs/material_icons#coloring
}

const MaterialIcon: React.FunctionComponent<MaterialIconProps> = ({ name }) => {
  return (
    <>
      <span className="material-icons-outlined">{name}</span>
    </>
  );
};

export default MaterialIcon;
