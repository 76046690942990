import { createSlice } from '@reduxjs/toolkit';
import { LoadStateType } from '../commonTypes';
import { ProgramListItem, ProgramListState } from './programListTypes';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { AppDispatch } from '../useAppDispatch';
import { State } from '../State';
import { programListLoadStateSelector } from './programListSelectors';
import { amplifyApi } from '../../api/AmplifyApi';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';
export const initialState: ProgramListState = {
  loadState: {
    type: LoadStateType.NotLoaded,
  },
  data: [],
};

const programListSlice = createSlice({
  initialState,
  name: "programList",
  reducers: {
    loading: (state) => {
      state.loadState.type = LoadStateType.Loading;
      state.loadState.errorMessage = null;
    },
    load: (state, { payload: programList }: PayloadAction<ProgramListItem[]>) => {
      state.loadState.type = LoadStateType.Loaded;
      state.data = programList.sort((i1, i2) =>
        i1.ProgramName.localeCompare(i2.ProgramName));
    },
    loadingError: (state, { payload: errorMessage }: PayloadAction<string>) => {
      state.loadState.type = LoadStateType.Error;
      state.loadState.errorMessage = errorMessage;
    },
    addProgram: (state, { payload: programListItem }: PayloadAction<ProgramListItem>) => {
      state.data?.push(programListItem);
    },
    removeProgram: (state, { payload: id }: PayloadAction<number>) => {
      const index = state.data?.findIndex(pli => pli.Id === id);
      if (index && index > 0) {
        state.data?.splice(index, 1);
      }
    },
    edit: (state, { payload: newProgram }: PayloadAction<ProgramListItem>) => {
      const index = state.data?.findIndex(p => p.Id == newProgram.Id);
      if (index && index > -1) {
        if (state.data) {
          state.data[index] = newProgram;
        }
      }
    },
    updateStatuses: (state, action: PayloadAction<Array<ProgramListItem>>) => {
      action.payload.forEach(s => {
        const program = state.data?.find(p => p.Id == s.Id);
        if (program) {
          program.Status = s.Status;
        }
      })
    }
  }
});

export const programListActions = programListSlice.actions;
export const programListReducer = programListSlice.reducer;

export const loadProgramList = () => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const loadState = programListLoadStateSelector(state);
    if (loadState.type === LoadStateType.Loaded || loadState.type === LoadStateType.Loading) {
      return;
    }

    dispatch(programListActions.loading());
    try {
      const programList = await amplifyApi.get<ProgramListItem[]>(
        apiNames.AomUI,
        apiPaths.programs,
        { queryStringParameters: { IncludingContract: true } });
      dispatch(programListActions.load(programList));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(programListActions.loadingError(errorMessage));
    }
  };
};