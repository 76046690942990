import React, { useEffect, useMemo } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Icon } from 'semantic-ui-react';
import { FormWithRules } from '../../../services/rules';
import { carriersWithGroupsSelector } from '../../../store/carriers/carriersSelectors';
import { marketDataSelector } from '../../../store/marketData/marketDataSelectors';
import { Column, ColumnData, defaultControl } from '../columns';
import './ExcludingForm.css';

export interface ExcludingFormProps {
  columns: Column[];
  form: UseFormReturn<FormWithRules>;
  formFieldName: string;
  formRowIndex: number;
  formColumnName: string;
  currentData?: any;
}

const ExcludingForm: React.FunctionComponent<ExcludingFormProps> = ({
  columns,
  form,
  formFieldName,
  formRowIndex,
  formColumnName,
  currentData,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: form?.control,
    name: `${formFieldName}.${formRowIndex}.${formColumnName}`,
  });

  useEffect(() => {
    if (!fields.length) {
      append({});
    }
  }, []);

  const marketData = useSelector(marketDataSelector);
  const carrierGroups = useSelector(carriersWithGroupsSelector);
  const columnData = useMemo(
    () => ({ marketData, carrierGroups } as ColumnData),
    [marketData, carrierGroups]
  );

  return (
    <>
      {fields.map((r, i) => (
        <Form.Group key={r.id}>
          {columns.map((c) => (
            <Form.Field
              key={c.name}
              error={
                (form.formState?.errors?.[formFieldName] as any)?.[formRowIndex]?.[formColumnName]?.[i]?.[
                  c.name
                ]
              }
            >
              <label>{c.displayName}</label>
              {(c.control || defaultControl)(
                form,
                `${formFieldName}.${formRowIndex}.${formColumnName}.${i}.`,
                c,
                currentData,
                columnData
              )}
              {(form.formState?.errors?.[formFieldName] as any)?.[formRowIndex]?.[formColumnName]?.[i]?.[
                c.name
              ] && (
                <p>
                  {
                    (form.formState?.errors?.[formFieldName] as any)?.[formRowIndex]?.[formColumnName]?.[i]?.[
                      c.name
                    ]?.message
                  }
                </p>
              )}
            </Form.Field>
          ))}
          {(i && (
            <Icon
              name="trash alternate outline"
              color="blue"
              className={`remove-icon ${
                ((form.formState?.errors?.[formFieldName] as any)?.[formRowIndex]?.[formColumnName]?.[i] &&
                  'error-row') ||
                ''
              }`}
              link
              title="Remove Exclusion"
              onClick={() => remove(i)}
            />
          )) ||
            undefined}
        </Form.Group>
      ))}
      <span
        className="link-button"
        onClick={() => {
          append({});
        }}
      >
        <Icon name="plus circle" />
        Add Exclusion
      </span>
    </>
  );
};

export default ExcludingForm;
