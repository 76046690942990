import { createSelector } from '@reduxjs/toolkit';
import { Contract } from '../../api/types';
import { getUniqueRuleValues, IRulesAssociation } from '../../services/rules/getRuleValues';
import { RuleName } from '../../services/rules/RuleName';
import {
  ArrayEmpty,
  IdParams,
  LoadState,
  LoadStateType,
  PaginatedDataParams,
  SortedDataParams,
} from '../commonTypes';
import { ContractStatus } from '../contractList/contractListTypes';
import { State } from '../State';
import { createParameterSelector } from '../useParamsSelector';
import { mapContractToViewModel } from './contractMapping';
import { newContractId } from './contractReducer';
import { ContractData, ContractStatusProps } from './contractTypes';

const idParamSelector = createParameterSelector<IdParams<string | number | null>, string | number | null>(
  (params) => params.id
);

const contractDataSelector = createSelector(
  (state: State) => state,
  idParamSelector,
  (state: State, id: number | string | null) => (id && state.contract[id]) || null
);

export const contractSelector = createSelector(contractDataSelector, (contract) => contract?.data);

export const contractProgramsSelector = createSelector(contractSelector, (contract) => contract?.Programs);

export const contractViewModelSelector = createSelector(
  contractSelector,
  (contract) => contract && mapContractToViewModel(contract)
);

const notLoadedState: LoadState = { type: LoadStateType.NotLoaded };

export const contractLoadStateSelector = createSelector(
  contractDataSelector,
  (contract) => contract?.loadState || notLoadedState
);

export const newContractLoadStateSelector = (state: State) =>
  state.contract[newContractId]?.loadState || notLoadedState;

export const contractCarriersSelector = createSelector(contractSelector, (contract) => getCarriers(contract));

export const getContractStatusProps = (contract?: Contract | null) => {
  const result: ContractStatusProps = {};

  if (!contract) {
    return result;
  }

  if (!contract.FileName) {
    result.attach = true;
  }

  if (
    (contract.Status === ContractStatus.Active || contract.Status === ContractStatus.Inactive || contract.Status === ContractStatus.Archived) &&
    contract.Versions &&
    contract.Versions.length >= 2
  ) {
    result.versionComparison = true;
  }

  if (contract.Status === ContractStatus.Draft) {
    result.delete = true;
    result.edit = true;
    result.clone = true;

    result.activate = {
      isAvailable: true,
    };

    if (contract.Programs?.length) {
      result.activate.isEnabled = true;
    } else {
      result.activate.disabledReason = 'Add at least one program to activate the contract';
    }
  }

  if (contract.Status === ContractStatus.Active) {
    result.extend = true;
    result.deactivate = true;
    result.update = true;
  }

  if (contract.Status === ContractStatus.Inactive || contract.Status === ContractStatus.Archived) {
    result.extend = true;
  }

  return result;
};

export const contractStatusPropsSelector = createSelector(contractSelector, getContractStatusProps);

const pageNumberParamSelector = createParameterSelector<PaginatedDataParams, number>(
  (params) => params.PageNumber || 0
);
const pageSizeParamSelector = createParameterSelector<PaginatedDataParams, number>(
  (params) => params.PageSize || 0
);
const orderByParamSelector = createParameterSelector<SortedDataParams, boolean | null>(
  (params) => params.OrderBy
);

export const contractChangelogsKeySelector = createSelector(
  pageSizeParamSelector,
  orderByParamSelector,
  (pageSize, orderBy) => `${pageSize}_${orderBy}`
);

const contractChangelogsPageStateSelector = createSelector(
  contractSelector,
  contractChangelogsKeySelector,
  pageNumberParamSelector,
  (contract, key, pageNumber) => {
    return contract?.changelogs?.key === key ? contract.changelogs.pages?.[pageNumber] : undefined;
  }
);

export const contractChangelogsPageLoadStateSelector = createSelector(
  contractChangelogsPageStateSelector,
  (page) => page?.loadState || notLoadedState
);

export const contractChangelogsPageSelector = createSelector(contractChangelogsPageStateSelector, (page) => {
  return page?.data;
});

export const contractChangelogsTotalCountSelector = createSelector(
  contractSelector,
  contractChangelogsKeySelector,
  (contract, key) => (contract?.changelogs?.key === key ? contract?.changelogs?.totalCount : 0)
);

export const getCarriers = (contract?: ContractData | null): string[] => {
  if (!contract) {
    return ArrayEmpty;
  }
  if (contract.ValidatingCarriers) {
    return contract.ValidatingCarriers;
  }
  const validatingCarrierArray = contract.Programs?.map((p) =>
    getUniqueRuleValues(p as IRulesAssociation, RuleName.validatingCarrierCodeIn)
  );
  if (validatingCarrierArray == null || validatingCarrierArray.length === 0) {
    return ArrayEmpty;
  }
  const flatCarriers = new Array<string>().concat.apply(new Array<string>(), validatingCarrierArray);
  return Array.from(new Set(flatCarriers));
};
