import {
  CommissionInfo,
  Discount,
  MarketRuleOperation,
  Program,
  Rule,
  RulesCombination,
} from '../../api/types';
import { RulesFields, RulesFieldsBase } from '../../services/rules';
import { RuleName } from '../../services/rules/RuleName';
import {
  CombinationRules,
  CommissionRules,
  DiscountRules,
  Market,
  ProgramRules,
  ProgramViewModel,
} from './programTypes';
import {
  distinct,
  formatMarketDataOption,
  mapCountriesToGeographicalEntites,
  mapCountryNameFromCode,
  mappingOptions,
  parseMarketDataOptions,
} from '../marketData/marketDataMapping';
import { MarketData, OptionCategory } from '../marketData/marketDataTypes';
import getUTCDate from '../../services/utils/getUTCDate';
import {
  arraysEqual,
  arrivalRules,
  departureRules,
} from '../../app/contracts/Contract/Program/RulesView/RulesView';
import { CarrierListItem } from '../carriers/carriersTypes';
import { sameLineFromToAreaOptions } from '../../components/RulesEditTable';
import { parseOptionCategory } from '../../components/LocationsSelector/LocationsSelector';
import { isEmpty } from 'lodash';

const mapPooValues =
  (category: OptionCategory) => (model: ProgramViewModel, rule: ProgramRules, data: MappingData) => {
    if (!model.PooCodes?.length) {
      return null;
    }

    const options = parseMarketDataOptions(model.PooCodes);
    if (options[0].type !== category) {
      return null;
    }

    return mappingOptions[category](
      options.map((o) => o.value),
      data.marketData
    );
  };

export interface MappingData {
  marketData: MarketData;
  carrierGroups: CarrierListItem[];
}

const mapCarriersWithGroups = (carrierGroups: CarrierListItem[], carriers?: string[]) =>
  carriers?.reduce((cr, c) => {
    const group = carrierGroups?.find((g) => g.code === c);
    if (group?.carriers) {
      cr.push(...group.carriers.filter((gc) => !cr.includes(gc.code)).map((gc) => gc.code));
    } else {
      if (!cr.includes(c)) {
        cr.push(c);
      }
    }
    return cr;
  }, new Array<string>());

const programRulesMap = {
  [RuleName.pooCountryCodeIn.toString()]: mapPooValues(OptionCategory.Country),
  [RuleName.pooZoneIn.toString()]: mapPooValues(OptionCategory.Zone),
  [RuleName.pooRegionIn.toString()]: mapPooValues(OptionCategory.Region),
  [RuleName.pooTrafficConferenceIn.toString()]: mapPooValues(OptionCategory.TrafficConference),
  [RuleName.posCountryCodeIn.toString()]: (model: ProgramViewModel, rule: ProgramRules) =>
    model.PosCountryCodes,
  [RuleName.validatingCarrierCodeIn.toString()]: (
    model: ProgramViewModel,
    rule: ProgramRules,
    data: MappingData
  ) => mapCarriersWithGroups(data.carrierGroups, rule.ValidatingCarriers),
  [RuleName.marketingAirlineCodeIn.toString()]: (
    model: ProgramViewModel,
    rule: ProgramRules,
    data: MappingData
  ) => mapCarriersWithGroups(data.carrierGroups, rule.MarketingCarriers),
  [RuleName.operatingAirlineCodeIn.toString()]: (
    model: ProgramViewModel,
    rule: ProgramRules,
    data: MappingData
  ) => mapCarriersWithGroups(data.carrierGroups, rule.OperatingCarriers),
  [RuleName.ticketDesignatorIn.toString()]: (model: ProgramViewModel, rule: ProgramRules) =>
    rule.TicketDesignators,
  [RuleName.tourCodeIn.toString()]: (model: ProgramViewModel, rule: ProgramRules) => rule.TourCodes,
  [RuleName.ticketedDateBetween.toString()]: (model: ProgramViewModel, _: ProgramRules) =>
    [model.FirstTicketDate?.toISOString() ?? null, model.LastTicketDate && getLastDateTimeView(model.LastTicketDate).toISOString()],
  [RuleName.travelCommencementDateBetween.toString()]: (model: ProgramViewModel, rule: ProgramRules) =>
    model.TravelCommencingDate &&
    (model.TravelCommencingOnAfter
      ? [model.TravelCommencingDate.toISOString(), null]
      : [null, getLastDateTimeView(model.TravelCommencingDate).toISOString()]),
  [RuleName.travelCompleteDateBetween.toString()]: (model: ProgramViewModel, rule: ProgramRules) =>
    model.TravelCompletedDate && [null, getLastDateTimeView(model.TravelCompletedDate).toISOString()],
};

const viewProgramRulesMap = {
  [RuleName.validatingCarrierCodeIn.toString()]: (model: Program, rule: ProgramRules, value: any) =>
    (rule.ValidatingCarriers = value),
  [RuleName.marketingAirlineCodeIn.toString()]: (model: Program, rule: ProgramRules, value: any) =>
    (rule.MarketingCarriers = value),
  [RuleName.operatingAirlineCodeIn.toString()]: (model: Program, rule: ProgramRules, value: any) =>
    (rule.OperatingCarriers = value),
  [RuleName.tourCodeIn.toString()]: (model: Program, rule: ProgramRules, value: any) =>
    (rule.TourCodes = value),
  [RuleName.ticketDesignatorIn.toString()]: (model: Program, rule: ProgramRules, value: any) =>
    (rule.TicketDesignators = value),
  [RuleName.flightNumberNotIn.toString()]: (model: Program, rule: ProgramRules, value: any) => {
    return;
  },
};

const viewEditsRulesMap = {
  [RuleName.marketIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.Markets = value),
  [RuleName.classOfServiceIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.ClassesOfService = value),
  [RuleName.validatingCarrierCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.ValidatingCarriers = value),
  [RuleName.marketingAirlineCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.MarketingCarriers = value),
  [RuleName.operatingAirlineCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.OperatingCarriers = value),
  [RuleName.posCountryCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.POS = value),
  [RuleName.pooCountryCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.POO = value.map((c: any) =>
      formatMarketDataOption(OptionCategory.Country, mapCountryNameFromCode(c, marketData) || c)
    )),
  [RuleName.pooZoneIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.POO = value.map((z: any) => formatMarketDataOption(OptionCategory.Zone, z))),
  [RuleName.pooRegionIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.POO = value.map((r: any) => formatMarketDataOption(OptionCategory.Region, r))),
  [RuleName.pooTrafficConferenceIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.POO = value.map((t: any) => formatMarketDataOption(OptionCategory.TrafficConference, t))),
  [RuleName.ticketDesignatorIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.TicketDesignators = value),
  [RuleName.fareBasisIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.FareBasis = value.join(', ')),
  [RuleName.ticketedDateBetween.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.TicketedDates = getLastDateTimeStore(new Date(value[1]))),
  [RuleName.travelCommencementDateBetween.toString()]: (
    marketData: MarketData,
    rule: RulesFields,
    value: any
  ) => (rule.TravelCommencementDates = getLastDateTimeStore(new Date(value[1]))),
  [RuleName.blackOutDatesBetween.toString()]: (marketData: MarketData, rule: RulesFields, value: any) => {
    return;
  },
  [RuleName.departureAirportCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.From = value.map((a: any) => formatMarketDataOption(OptionCategory.Airport, a))),
  [RuleName.arrivalAirportCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.To = value.map((a: any) => formatMarketDataOption(OptionCategory.Airport, a))),
  [RuleName.departureStateCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.From = value.map((s: any) => formatMarketDataOption(OptionCategory.State, s))),
  [RuleName.arrivalStateCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.To = value.map((s: any) => formatMarketDataOption(OptionCategory.State, s))),
  [RuleName.departureMetroAreaCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.From = value.map((ma: any) => formatMarketDataOption(OptionCategory.MetroArea, ma))),
  [RuleName.arrivalMetroAreaCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.To = value.map((ma: any) => formatMarketDataOption(OptionCategory.MetroArea, ma))),
  [RuleName.departureCountryCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.From = mapCountriesToGeographicalEntites(value, marketData)),
  [RuleName.arrivalCountryCodeIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.To = mapCountriesToGeographicalEntites(value, marketData)),
  [RuleName.departureZoneIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.From = value.map((a: any) => formatMarketDataOption(OptionCategory.Zone, a))),
  [RuleName.arrivalZoneIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.To = value.map((a: any) => formatMarketDataOption(OptionCategory.Zone, a))),
  [RuleName.departureRegionIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.From = value.map((a: any) => formatMarketDataOption(OptionCategory.Region, a))),
  [RuleName.arrivalRegionIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.To = value.map((a: any) => formatMarketDataOption(OptionCategory.Region, a))),
  [RuleName.departureTrafficConferenceIn.toString()]: (
    marketData: MarketData,
    rule: RulesFields,
    value: any
  ) => (rule.From = value.map((a: any) => formatMarketDataOption(OptionCategory.TrafficConference, a))),
  [RuleName.arrivalTrafficConferenceIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) =>
    (rule.To = value.map((a: any) => formatMarketDataOption(OptionCategory.TrafficConference, a))),
  [RuleName.flightNumberNotIn.toString()]: (model: MarketData, rule: RulesFields, value: any) => {
    return;
  },
  [RuleName.gdsIn.toString()]: (marketData: MarketData, rule: RulesFields, value: any) => (rule.GDS = value),
};

const getCombinationRuleValue = (model: ProgramViewModel, combinationRule?: string[] | null) => {
  return combinationRule?.[0] === CombinationRules.allPrograms
    ? null
    : combinationRule?.[0] === CombinationRules.onlyWithItself
    ? []
    : combinationRule?.map((c) => parseInt(c));
};

const getLastDateTimeView = (date: Date) => {
  const lastDateTime = new Date(date.getTime());
  lastDateTime.setHours(date.getHours() + 23);
  lastDateTime.setMinutes(date.getMinutes() + 59);
  lastDateTime.setSeconds(date.getSeconds() + 59);
  return lastDateTime;
};

const getLastDateTimeStore = (date: Date) => {
  const lastDateTime = new Date(date.getTime());
  lastDateTime.setHours(date.getHours() - 23);
  lastDateTime.setMinutes(date.getMinutes() - 59);
  lastDateTime.setSeconds(date.getSeconds() - 59);
  return lastDateTime;
};

const compensateTimeZone = (date: Date) => {
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date;
};

export const mapFromViewModel = (
  model: ProgramViewModel,
  marketData?: MarketData,
  carrierGroups?: CarrierListItem[]
): Program => {
  const mappingData = {
    marketData,
    carrierGroups,
  } as MappingData;

  const result = {
    Id: model?.Id,
    ContractId: model.ContractId,
    ProgramName: model.Name,
    OverTheWaterCarriers: mapCarriersWithGroups(
      carrierGroups as CarrierListItem[],
      model.OverTheWaterCarriers
    ),
    Status: model.Status,
    Definitions: model.Rules.map((r) => {
      const combination = {
        Rules: new Array<Rule>(),
      } as RulesCombination;
      for (const ruleName in programRulesMap) {
        const value = programRulesMap[ruleName](model, r, mappingData);
        if (value && value?.length > 0) {
          combination.Rules.push({
            RuleName: ruleName,
            Values: value,
          } as Rule);
        }
      }

      if (r.ExcludedFlightNumbers?.length) {
        const flightNumberRules: Rule[] = r.ExcludedFlightNumbers.map(
          (flightNumberRange) =>
            ({
              RuleName: RuleName.flightNumberNotIn,
              Values: [flightNumberRange[0], flightNumberRange[1] || null],
            } as Rule)
        );
        combination.Rules.push(...flightNumberRules);
      }

      return combination;
    }),
    CombinationRules: {
      LegProgramIds: getCombinationRuleValue(model, model.LegProgramIds),
      TicketProgramIds: getCombinationRuleValue(model, model.TicketProgramIds),
    },
    RelatedPrograms: model.RelatedPrograms
  } as Program;

  if (model.Markets) {
    result.MarketRules = mapMarketsFromViewModel(model.Markets, mappingData);
  }

  if (model.Discounts) {
    result.Discounts = mapDiscountsFromViewModel(model.Discounts, mappingData);

    if (model.BlackoutDates?.length) {
      const blackoutRules: Rule[] = model.BlackoutDates.map(
        (dates) =>
          ({
            RuleName: RuleName.blackOutDatesBetween,
            Values: [dates[0].toISOString(), getLastDateTimeView(dates[dates.length - 1]).toISOString()],
          } as Rule)
      );

      result.Discounts.forEach((d) => d.Definitions?.forEach((df) => df.Rules.push(...blackoutRules)));
    }
  }

  if (model.Commissions) {
    result.Commissions = mapCommissionsFromViewModel(model.Commissions, mappingData);
  }

  return result;
};

const destinationTypeFromMap = {
  [OptionCategory.Airport]: RuleName.departureAirportCodeIn,
  [OptionCategory.MetroArea]: RuleName.departureMetroAreaCodeIn,
  [OptionCategory.State]: RuleName.departureStateCodeIn,
  [OptionCategory.Country]: RuleName.departureCountryCodeIn,
  [OptionCategory.Zone]: RuleName.departureZoneIn,
  [OptionCategory.Region]: RuleName.departureRegionIn,
  [OptionCategory.TrafficConference]: RuleName.departureTrafficConferenceIn,
};

const destinationTypeToMap = {
  [OptionCategory.Airport]: RuleName.arrivalAirportCodeIn,
  [OptionCategory.MetroArea]: RuleName.arrivalMetroAreaCodeIn,
  [OptionCategory.State]: RuleName.arrivalStateCodeIn,
  [OptionCategory.Country]: RuleName.arrivalCountryCodeIn,
  [OptionCategory.Zone]: RuleName.arrivalZoneIn,
  [OptionCategory.Region]: RuleName.arrivalRegionIn,
  [OptionCategory.TrafficConference]: RuleName.arrivalTrafficConferenceIn,
};

const returnMap = {
  [RuleName.departureAirportCodeIn]: RuleName.arrivalAirportCodeIn,
  [RuleName.departureMetroAreaCodeIn]: RuleName.arrivalMetroAreaCodeIn,
  [RuleName.departureStateCodeIn]: RuleName.arrivalStateCodeIn,
  [RuleName.departureCountryCodeIn]: RuleName.arrivalCountryCodeIn,
  [RuleName.arrivalAirportCodeIn]: RuleName.departureAirportCodeIn,
  [RuleName.arrivalMetroAreaCodeIn]: RuleName.departureMetroAreaCodeIn,
  [RuleName.arrivalStateCodeIn]: RuleName.departureStateCodeIn,
  [RuleName.arrivalCountryCodeIn]: RuleName.departureCountryCodeIn,
  [RuleName.arrivalZoneIn]: RuleName.departureZoneIn,
  [RuleName.arrivalRegionIn]: RuleName.departureRegionIn,
  [RuleName.arrivalTrafficConferenceIn]: RuleName.departureTrafficConferenceIn,
  [RuleName.departureZoneIn]: RuleName.arrivalZoneIn,
  [RuleName.departureRegionIn]: RuleName.arrivalRegionIn,
  [RuleName.departureTrafficConferenceIn]: RuleName.arrivalTrafficConferenceIn,
} as { [key: string]: string };

type Category = {
  [key in OptionCategory]?: string[];
};

const destinationMapper =
  (typeMap: { [key in OptionCategory]?: string }) => (values: string[], mappingData: MappingData) => {
    const categories = values.reduce((categories, v) => {
      const destinationType = parseOptionCategory(v.split('_')[0]);
      const value = v.split('_')[1];
      if (Array.isArray(categories[destinationType])) {
        categories[destinationType]?.push(value);
      } else {
        categories[destinationType] = [value];
      }
      return categories;
    }, {} as Category);

    return Object.entries(categories).map(([type, values]) => {
      const destinationType = parseOptionCategory(type);
      const mappedValues = mappingOptions[destinationType](values, mappingData.marketData);
      return {
        RuleName: typeMap[destinationType],
        Values: mappedValues,
      } as Rule;
    });
  };

const pooTypeMap: { [key in OptionCategory]?: RuleName } = {
  [OptionCategory.Country]: RuleName.pooCountryCodeIn,
  [OptionCategory.Zone]: RuleName.pooZoneIn,
  [OptionCategory.Region]: RuleName.pooRegionIn,
  [OptionCategory.TrafficConference]: RuleName.pooTrafficConferenceIn,
};

const mapToExceptions = (exclusions: { Excluding?: RulesFieldsBase[] }, mappingData: MappingData): RulesCombination[] => {
  return (exclusions.Excluding ?? [])
    .filter(x => !(isEmpty(x.From) && isEmpty(x.To)))
    .flatMap(ruleFields => mapRulesFieldsToRules(ruleFields, mappingData));
}

export const RulesFieldsMap: any = {
  Markets: RuleName.marketIn,
  ValidatingCarriers: (values: string[], mappingData: MappingData) => [
    {
      RuleName: RuleName.validatingCarrierCodeIn,
      Values: mapCarriersWithGroups(mappingData.carrierGroups, values),
    },
  ],
  MarketingCarriers: (values: string[], mappingData: MappingData) => [
    {
      RuleName: RuleName.marketingAirlineCodeIn,
      Values: mapCarriersWithGroups(mappingData.carrierGroups, values),
    },
  ],
  OperatingCarriers: (values: string[], mappingData: MappingData) => [
    {
      RuleName: RuleName.operatingAirlineCodeIn,
      Values: mapCarriersWithGroups(mappingData.carrierGroups, values),
    },
  ],
  ClassesOfService: RuleName.classOfServiceIn,
  FareBasis: (values: string[]) => [
    {
      RuleName: RuleName.fareBasisIn,
      Values: values.map((v) => v.replace(/\s/g, '')),
    },
  ],
  From: destinationMapper(destinationTypeFromMap),
  To: destinationMapper(destinationTypeToMap),
  TicketedDates: (values: string[]) => [
    {
      RuleName: RuleName.ticketedDateBetween,
      Values: [null, getLastDateTimeView(getUTCDate(new Date(values[0]))).toISOString()],
    },
  ],
  TravelCommencementDates: (values: string[]) => [
    {
      RuleName: RuleName.travelCommencementDateBetween,
      Values: [null, getLastDateTimeView(getUTCDate(new Date(values[0]))).toISOString()],
    },
  ],
  TicketDesignators: RuleName.ticketDesignatorIn,
  POS: RuleName.posCountryCodeIn,
  POO: destinationMapper(pooTypeMap),
  GDS: RuleName.gdsIn,
};

export const mapMarketsFromViewModel = (
  markets: Market[],
  mappingData: MappingData
): MarketRuleOperation[] => {
  return markets.reduce((marketsRules, m) => {
    let market = marketsRules.find((mr) => mr.Market === m.Name);
    if (!market) {
      market = {
        Market: m.Name,
        Definitions: new Array<RulesCombination>(),
      } as MarketRuleOperation;
      marketsRules.push(market);
    }

    const newDefinitions = mapRulesFieldsToRules(m, mappingData);
    market.Definitions?.push(...newDefinitions);

    if (m.ToOnly !== 'true' && !arraysEqual(m.To, m.From)) {
      newDefinitions.forEach((d) => {
        const returnCombination: RulesCombination = {
          Rules: d.Rules.map(
            (r) =>
              ({
                RuleName: returnMap[r.RuleName] || r.RuleName,
                Values: r.Values,
              } as Rule)
          ),
        } as RulesCombination;
        market?.Definitions?.push(returnCombination);
      });
    }

    if (m.Excluding?.length && Object.keys(m.Excluding[0])?.length) {
      market.Exceptions = mapToExceptions(m, mappingData);
    }

    return marketsRules;
  }, new Array<MarketRuleOperation>());
};

export const mapDiscountsFromViewModel = (
  discounts: DiscountRules[],
  mappingData: MappingData
): Discount[] => {
  return discounts.map((discountRules) => ({
    DiscountPercentage: discountRules.DiscountPercentage,
    Definitions: mapRulesFieldsToRules(discountRules, mappingData),
    Exceptions: mapToExceptions(discountRules, mappingData)
  }));
};

export const mapRulesFieldsToRules = (fields: RulesFields, mappingData: MappingData): RulesCombination[] => {
  const combination = {
    Rules: new Array<Rule>(),
  } as RulesCombination;

  const fieldsObject = fields as any;

  const rulesForAdditionalCombination: { [key: string]: Array<Rule> } = {};

  for (const field in fieldsObject) {
    if (fieldsObject[field] == null || typeof fieldsObject[field] === 'undefined' || !RulesFieldsMap[field]) {
      continue;
    }

    const values: string[] = Array.isArray(fieldsObject[field])
      ? (fieldsObject[field] as Array<any>).map((v) => v.toString())
      : fieldsObject[field]
          .toString()
          .split(',')
          .map((v: string) => v.trim())
          .filter((v: string) => v);

    if (values.length) {
      if (typeof RulesFieldsMap[field] === 'string') {
        combination.Rules.push({
          RuleName: RulesFieldsMap[field],
          Values: values,
        } as Rule);
      } else {
        const ruleOptions = RulesFieldsMap[field](values, mappingData) as Rule[];
        const ruleToReplace = ruleOptions[0].RuleName;

        combination.Rules.push(...ruleOptions.splice(0, 1));
        if (ruleOptions.length) {
          rulesForAdditionalCombination[ruleToReplace] = ruleOptions;
        }
      }
    }
  }

  const result = [combination];

  // from/to different area types cases
  const rulesForAdditionalCombinationKeys = Object.keys(rulesForAdditionalCombination);
  rulesForAdditionalCombinationKeys.forEach((r) => {
    const ruleIndex = result[0].Rules.findIndex((rl) => rl.RuleName === r);

    rulesForAdditionalCombination[r].forEach((o) => {
      const newCombination = {
        Rules: result[0].Rules.slice(0),
      } as RulesCombination;
      newCombination.Rules.splice(ruleIndex, 1, o);
      result.push(newCombination);
    });
  });

  if (rulesForAdditionalCombinationKeys.length > 1) {
    const rules = result[0].Rules.slice(0);
    const indexDeparture = rules.map((r) => r.RuleName).findIndex((rn) => rn.includes('departure'));
    rules.splice(indexDeparture, 1);
    const indexArrival = rules.map((r) => r.RuleName).findIndex((rn) => rn.includes('arrival'));
    rules.splice(indexArrival, 1);
    const newCombination = {
      Rules: rules,
    } as RulesCombination;
    rulesForAdditionalCombinationKeys.forEach((k) => {
      rulesForAdditionalCombination[k].forEach((o) => {
        newCombination.Rules.push(o);
      });
    });
    result.push(newCombination);
  }

  return result;
};

export const mapCommissionsFromViewModel = (
  commissions: CommissionRules[],
  mappingData: MappingData
): CommissionInfo[] => {
  return commissions.map((c) => {
    const commission = {
      CommissionPercentage: c.CommissionPercentage,
    } as CommissionInfo;

    commission.Definitions = mapRulesFieldsToRules(c, mappingData);

    return commission;
  });
};

const mapPooCodes = (model: Program, marketData?: MarketData) => {
  const pooRule = model.Definitions?.[0]?.Rules?.find((rule) =>
    Object.values(pooTypeMap).includes(RuleName[rule.RuleName as keyof typeof RuleName])
  );
  if (!pooRule?.Values?.length) {
    return null;
  }

  const categoryString = Object.keys(pooTypeMap).find(
    (k) => pooTypeMap[parseOptionCategory(k)] === pooRule?.RuleName
  );
  if (!categoryString) {
    return null;
  }

  const category = parseOptionCategory(categoryString);
  return pooRule.Values.map(
    (v) =>
      v &&
      formatMarketDataOption(
        category,
        (category === OptionCategory.Country && marketData && mapCountryNameFromCode(v, marketData)) || v
      )
  );
};

export const mapFromStoreModel = (model: Program, marketData?: MarketData): ProgramViewModel => {
  const result = {
    Id: model.Id,
    CreatedBy: model.CreatedBy,
    UpdatedBy: model.UpdatedBy,
    ContractId: model.ContractId,
    Name: model.ProgramName,
    Status: model.Status,
    OverTheWaterCarriers: model.OverTheWaterCarriers,
    Discounts: mapDiscountsFromStoreModel(model, marketData),
    Commissions: mapCommissionsFromStoreModel(model, marketData),
    Markets: mapMarketsFromStoreModel(model, marketData),
    BlackoutDates: mapBlackoutDatesFromStoreModel(model),
    PosCountryCodes: model.Definitions?.[0]?.Rules?.find(
      (rule) => rule.RuleName === RuleName.posCountryCodeIn
    )?.Values,
    PooCodes: mapPooCodes(model, marketData),
    LegProgramIds: model.CombinationRules?.LegProgramIds
      ? model.CombinationRules.LegProgramIds?.length
        ? model.CombinationRules?.LegProgramIds.map((i) => i.toString())
        : [CombinationRules.onlyWithItself]
      : [CombinationRules.allPrograms],
    TicketProgramIds: model.CombinationRules?.TicketProgramIds
      ? model.CombinationRules.TicketProgramIds?.length
        ? model.CombinationRules?.TicketProgramIds.map((i) => i.toString())
        : [CombinationRules.onlyWithItself]
      : [CombinationRules.allPrograms],
    Rules: model.Definitions?.map((rc) => {
      const programRule = {} as ProgramRules;
      const excludedFlightNumbers: Array<Array<string>> = [];
      for (const rule of rc.Rules) {
        if (rule.Values && rule.RuleName in viewProgramRulesMap) {
          viewProgramRulesMap[rule.RuleName](model, programRule, rule.Values);
        }
        if (rule.RuleName == RuleName.flightNumberNotIn) {
          excludedFlightNumbers.push(rule.Values?.filter((x) => x) as string[]);
        }
      }
      if (excludedFlightNumbers.length > 0) {
        programRule.ExcludedFlightNumbers = excludedFlightNumbers;
      }
      return programRule;
    }),
    RelatedPrograms: model.RelatedPrograms
  } as ProgramViewModel;

  mapTicketDatesFromStoreModel(model, result);
  mapTravelCommencingDateFromStoreModel(model, result);
  mapTravelCompletedDateFromStoreModel(model, result);
  return result;
};

const blackoutDatesSameDay = (firstDate: string, secondDate: string) => {
  return getLastDateTimeView(new Date(firstDate)).getDate() == new Date(secondDate).getDate();
};

const mapMarketsFromStoreModel = (storeModel: Program, marketData?: MarketData) => {
  const allMarkets: Market[] = [];
  if (!marketData) {
    return allMarkets;
  }

  storeModel?.MarketRules?.forEach((m) => {
    const rulesCombinations: RulesCombination[] = [];
    let markets: Market[] = [];
    m.Definitions?.forEach((rc, index) => {
      if (index == 0) {
        const market = {
          Name: m.Market,
          ToOnly: 'true',
        } as Market;
        rulesCombinations.push(rc);
        rc.Rules.forEach((rule) => viewEditsRulesMap[rule.RuleName](marketData, market, rule.Values));
        markets.push(market);
      }

      if (index > 0) {
        const from = rc.Rules.find((r) => departureRules.includes(r.RuleName));
        const to = rc.Rules.find((r) => arrivalRules.includes(r.RuleName));
        if (from && to) {
          const previousIndex = rulesCombinations.findIndex((previousRuleCombination) => {
            const previousFrom = previousRuleCombination.Rules.find((r) =>
              departureRules.includes(r.RuleName)
            );
            const previousTo = previousRuleCombination.Rules.find((r) => arrivalRules.includes(r.RuleName));
            if (
              to &&
              from &&
              previousFrom &&
              previousTo &&
              previousFrom.RuleName === departureRules[arrivalRules.indexOf(to.RuleName)] &&
              previousTo.RuleName === arrivalRules[departureRules.indexOf(from.RuleName)] &&
              arraysEqual(previousFrom.Values, to.Values) &&
              arraysEqual(previousTo.Values, from.Values) &&
              rc.Rules.length == previousRuleCombination.Rules.length &&
              rc.Rules.filter(
                (r) => !departureRules.includes(r.RuleName) && !arrivalRules.includes(r.RuleName)
              ).every((r) =>
                arraysEqual(
                  r.Values,
                  previousRuleCombination.Rules.find((pr) => pr.RuleName === r.RuleName)?.Values
                )
              )
            ) {
              return previousRuleCombination;
            }
          });
          if (previousIndex < 0) {
            const market = {
              Name: m.Market,
              ToOnly: 'true',
            } as Market;
            rulesCombinations.push(rc);
            rc.Rules.forEach((rule) => viewEditsRulesMap[rule.RuleName](marketData, market, rule.Values));
            markets.push(market);
          } else {
            markets[previousIndex].ToOnly = 'false';
          }
        }
      }
    });

    if (m.Exceptions) {
      let excludings: RulesFieldsBase[] = [];
      m.Exceptions.forEach((ex) => {
        const excluding = {} as RulesFieldsBase;
        ex.Rules.forEach((rule) =>
          viewEditsRulesMap[rule.RuleName](marketData, excluding as any, rule.Values)
        );
        excludings.push(excluding);
      });

      excludings = mapMultipleAreasToOneLine(excludings, (rulesFields1, rulesFields2) => true);
      markets.forEach((m) => (m.Excluding = excludings));
    }

    markets = mapMultipleAreasToOneLine(markets, rulesFieldsEquals) as Market[];

    allMarkets.push(...markets);
  });

  return allMarkets;
};

const mapDiscountsFromStoreModel = (storeModel: Program, marketData?: MarketData) => {
  let discounts: DiscountRules[] = [];
  if (!marketData) {
    return discounts;
  }

  storeModel.Discounts?.forEach((d) => {
    d.Definitions?.forEach((rc) => {
      const discountRules = {
        DiscountPercentage: d.DiscountPercentage,
      } as DiscountRules;

      rc.Rules.forEach((rule) => viewEditsRulesMap[rule.RuleName](marketData, discountRules, rule.Values));

      if (d.Exceptions) {
        discountRules.Excluding = [];
        d.Exceptions.forEach((ex) => {
          const excluding = {} as RulesFieldsBase;
          ex.Rules.forEach((rule) =>
            viewEditsRulesMap[rule.RuleName](marketData, excluding as any, rule.Values)
          );
          discountRules?.Excluding?.push(excluding);
        });
        let excludings = discountRules?.Excluding;
        excludings = mapMultipleAreasToOneLine(
          excludings,
          (rulesFields1, rulesFields2) => rulesFields1.FareBasis == rulesFields2.FareBasis
        );
      }
      discounts.push(discountRules);
    });
  });

  discounts = mapMultipleAreasToOneLine(discounts, rulesFieldsEquals) as DiscountRules[];

  return discounts;
};

const mapCommissionsFromStoreModel = (storeModel: Program, marketData?: MarketData) => {
  const commissions: CommissionRules[] = [];
  if (!marketData) {
    return commissions;
  }

  storeModel.Commissions?.forEach((d) => {
    d.Definitions?.forEach((rc) => {
      const discountRules = {
        CommissionPercentage: d.CommissionPercentage,
      } as CommissionRules;

      rc.Rules.forEach((rule) => viewEditsRulesMap[rule.RuleName](marketData, discountRules, rule.Values));

      commissions.push(discountRules);
    });
  });

  return commissions;
};

const mapBlackoutDatesFromStoreModel = (storeModel: Program) => {
  const discountBlackoutRules = storeModel?.Discounts?.[0]?.Definitions?.[0]?.Rules?.filter(
    (r) => r.RuleName == RuleName.blackOutDatesBetween
  );
  if (!discountBlackoutRules?.length) {
    return null;
  }

  const blackoutDates: Date[][] = [];

  discountBlackoutRules.forEach((rule) => {
    if (blackoutDatesSameDay(rule?.Values?.[0] as string, rule?.Values?.[1] as string)) {
      blackoutDates.push([compensateTimeZone(new Date(rule?.Values?.[0] as string))]);
    } else {
      blackoutDates.push([
        compensateTimeZone(new Date(rule?.Values?.[0] as string)),
        compensateTimeZone(getLastDateTimeStore(new Date(rule?.Values?.[1] as string))),
      ]);
    }
  });

  return blackoutDates;
};

const mapTicketDatesFromStoreModel = (storeModel: Program, viewModel: ProgramViewModel) => {
  if (storeModel.Definitions) {
    for (const definition of storeModel.Definitions) {
      const rule = definition?.Rules.find(
        (rule) => rule.RuleName == RuleName.ticketedDateBetween.toString() && rule.Values
      );
      if (!rule) {
        return;
      }
      viewModel.FirstTicketDate = rule.Values?.[0] ? compensateTimeZone(new Date(rule.Values[0] as string)) : undefined;
      viewModel.LastTicketDate = compensateTimeZone(
        getLastDateTimeStore(new Date(rule?.Values?.[1] as string))
      );
      return;
    }
  }
};

const mapTravelCommencingDateFromStoreModel = (storeModel: Program, viewModel: ProgramViewModel) => {
  if (storeModel.Definitions) {
    for (const definition of storeModel.Definitions) {
      const rule = definition?.Rules.find(
        (rule) => rule.RuleName == RuleName.travelCommencementDateBetween.toString() && rule.Values
      );
      if (!rule) {
        return;
      }
      if (rule?.Values?.[1] == null) {
        viewModel.TravelCommencingOnAfter = 'true';
        viewModel.TravelCommencingDate = compensateTimeZone(new Date(rule?.Values?.[0] as string));
      } else {
        viewModel.TravelCommencingOnAfter = '';
        viewModel.TravelCommencingDate = compensateTimeZone(
          getLastDateTimeStore(new Date(rule?.Values?.[1]))
        );
      }
      return;
    }
  }
};

const mapTravelCompletedDateFromStoreModel = (storeModel: Program, viewModel: ProgramViewModel) => {
  if (storeModel.Definitions) {
    for (const definition of storeModel.Definitions) {
      const rule = definition?.Rules.find(
        (rule) => rule.RuleName == RuleName.travelCompleteDateBetween.toString() && rule.Values
      );
      if (!rule) {
        return;
      }
      viewModel.TravelCompletedDate = compensateTimeZone(
        getLastDateTimeStore(new Date(rule.Values?.[1] as string))
      );
      return;
    }
  }
};

const canBeInSameCell = (values: string[] | undefined) => {
  const allowedOnSameLine = sameLineFromToAreaOptions;

  return values
    ?.map((area) => parseOptionCategory(area?.split('_')[0]))
    .every((category) => allowedOnSameLine.includes(category));
};

const mapMultipleAreasToOneLine = (
  rulesFields: RulesFields[],
  equalityComparer: (rulesfield1: RulesFields, rulesfield2: RulesFields) => boolean
) => {
  if (rulesFields.length > 1) {
    for (let i = 1; i < rulesFields.length; i++) {
      const currentRules = rulesFields[i];
      const currentFrom = currentRules.From;
      const currentTo = currentRules.To;
      for (let j = 0; j < i; j++) {
        const previousRules = rulesFields[j];
        if (equalityComparer(previousRules, currentRules)) {
          const previousFrom = previousRules.From;
          const previousTo = previousRules.To;
          if (previousFrom && arraysEqual(currentTo, previousTo) && canBeInSameCell(currentFrom)) {
            currentRules?.From?.push(...previousFrom);
            if (currentRules.From) {
              currentRules.From = distinct(currentRules.From);
            }
            rulesFields.splice(j, 1);
            i = 0;
          } else if (previousTo && arraysEqual(currentFrom, previousFrom) && canBeInSameCell(currentTo)) {
            currentRules?.To?.push(...previousTo);
            if (currentRules.To) {
              currentRules.To = distinct(currentRules.To);
            }
            rulesFields.splice(j, 1);
            i = 0;
          }
        }
      }
    }
  }

  return rulesFields;
};

const excludingEqual = (excluding1?: RulesFieldsBase[], excluding2?: RulesFieldsBase[]): boolean => {
  if (!excluding1 && !excluding2) {
    return true;
  }

  if (!Array.isArray(excluding1) || !Array.isArray(excluding2)) {
    return false;
  }

  if (excluding1.length != excluding2.length) {
    return false;
  }

  excluding1.forEach((excluding, index) => {
    if (
      !arraysEqual(excluding.From, excluding2[index].From) ||
      !arraysEqual(excluding.To, excluding2[index].To)
    ) {
      return false;
    }
  });

  return true;
};

const rulesFieldsEquals = (rulesField1: RulesFields, rulesField2: RulesFields): boolean => {
  const fieldsObject1 = rulesField1 as any;
  const fieldsObject2 = rulesField2 as any;

  for (const field in fieldsObject1) {
    if (
      field == 'From' ||
      field == 'To' ||
      (fieldsObject1[field] == null && fieldsObject2[field] == null) ||
      (typeof fieldsObject1[field] === 'undefined' && typeof fieldsObject2[field] === 'undefined')
    ) {
      continue;
    }
    if (
      Array.isArray(fieldsObject1[field]) &&
      field != 'Excluding' &&
      !arraysEqual(fieldsObject1[field], fieldsObject2[field])
    ) {
      return false;
    } else if (
      (fieldsObject1[field] instanceof Date || fieldsObject2[field] instanceof Date) &&
      fieldsObject1[field]?.getDate() != fieldsObject2[field]?.getDate()
    ) {
      return false;
    } else if (
      (typeof fieldsObject1[field] == 'string' || typeof fieldsObject2[field] == 'string') &&
      fieldsObject1[field] != fieldsObject2[field]
    ) {
      return false;
    } else if (field == 'Excluding' && !excludingEqual(fieldsObject1[field], fieldsObject2[field])) {
      return false;
    } else if (
      (typeof fieldsObject1[field] == 'number' || typeof fieldsObject2[field] == 'number') &&
      fieldsObject1[field] != fieldsObject2[field]
    ) {
      return false;
    }
  }
  return true;
};
