import { createReducer } from '@reduxjs/toolkit';
import { load } from './currentUserActions';
import { UserState } from './currentUserState';

export const initialState: UserState = null;

const currentUserReducer = createReducer<UserState>(initialState, (builder) => {
  builder.addCase(load, (_, action) => action.payload);
});

export default currentUserReducer;
