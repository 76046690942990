import { State } from './State';
import { useAppSelector } from './useAppDispatch';

const useParamsSelector = <P, T>(selector: (state: State, params: P) => T, params: P) =>
  useAppSelector<T>((state) => selector(state, params));

export const createParameterSelector =
  <P, T>(selector: (params: P) => T) =>
  (_: unknown, params: P) =>
    selector(params);

export default useParamsSelector;
