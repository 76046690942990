import { createReducer } from '@reduxjs/toolkit';
import { ChangelogChangeItem, Program } from '../../api/types';
import { LoadStateType, PaginatedDataState } from '../commonTypes';
import * as actions from './programActions';
import { programChangelogsKeySelector } from './programSelectors';
import { ProgramChangelogPaginatedParams, ProgramData, ProgramState } from './programTypes';

export const newProgramId = -1;

const checkChangelogsStateKey = (
  state: PaginatedDataState<ChangelogChangeItem>,
  params: ProgramChangelogPaginatedParams
) => {
  const key = programChangelogsKeySelector({}, params);
  if (state.key !== key) {
    state.key = key;
    state.pages = [];
    state.totalCount = 0;
  }
};

export const initialState: ProgramState = {};

const programReducer = createReducer<ProgramState>(initialState, (builder) => {
  builder.addCase(actions.loading, (state, action) => {
    state[action.payload] = {
      loadState: {
        type: LoadStateType.Loading,
      },
    };
  });
  builder.addCase(actions.load, (state, action) => {
    state[action.payload.Id].loadState = {
      type: LoadStateType.Loaded,
    };
    state[action.payload.Id].data = action.payload;
  });
  builder.addCase(actions.loadingError, (state, action) => {
    state[action.payload.Id] = {
      loadState: {
        type: LoadStateType.Error,
        errorMessage: action.payload.errorMessage,
      },
    };
  });
  builder.addCase(actions.creating, (state) => {
    state[newProgramId] = {
      loadState: {
        type: LoadStateType.Loading,
      },
    };
  });
  builder.addCase(actions.create, (state, action) => {
    state[newProgramId] = {
      loadState: {
        type: LoadStateType.NotLoaded,
      },
    };
    state[action.payload.Id] = {
      loadState: {
        type: LoadStateType.Loaded,
      },
      data: action.payload,
    };
  });
  builder.addCase(actions.creatingError, (state, action) => {
    state[newProgramId] = {
      loadState: {
        type: LoadStateType.Error,
        errorMessage: action.payload,
      },
    };
  });
  builder.addCase(actions.editing, (state, action) => {
    state[action.payload.Id].loadState = {
      type: LoadStateType.Editing,
    };
  });
  builder.addCase(actions.edit, (state, action) => {
    state[action.payload.Id] = {
      loadState: {
        type: LoadStateType.Loaded,
      },
      data: action.payload,
    };
  });
  builder.addCase(actions.clearProgramData, (state, action) => {
    state[action.payload] = {
      loadState: {
        type: LoadStateType.NotLoaded,
      },
    };
  });
  builder.addCase(actions.clearProgramDataWithRelatedProgramId, (state, action) => {
    Object.keys(state).forEach(idString => {
      const id = parseInt(idString);
      if (state[id].data?.RelatedPrograms?.ProgramIds?.includes(action.payload)) {
        state[id] = {
          loadState: {
            type: LoadStateType.NotLoaded,
          },
        };
      }
    })
  });
  builder.addCase(actions.deleteProgramById, (state, action) => {
    delete state[action.payload];
  });
  builder.addCase(actions.deletePrograms, (state, action) => {
    action.payload.forEach((id) => {
      delete state[id];
    });
  });
  builder.addCase(actions.deletingError, (state, action) => {
    state[action.payload.programId] = {
      loadState: {
        type: LoadStateType.Error,
        errorMessage: action.payload.errorMessage,
      },
    };
  });
  builder.addCase(actions.editingError, (state, action) => {
    state[action.payload.program.Id] = {
      loadState: {
        type: LoadStateType.Error,
        errorMessage: action.payload.errorMessage,
      },
    };
  });
  builder.addCase(actions.updateStatuses, (state, action) => {
    action.payload.forEach((programStatusChanged) => {
      const programState = state[programStatusChanged.Id];
      if (programState?.data) {
        programState.data = {...programState.data, ...programStatusChanged, changelogs: undefined };
      }
    });
  });
  builder.addCase(actions.loadingChangelogs, (state, action) => {
    if (!state[action.payload.id]) {
      state[action.payload.id] = {
        loadState: {
          type: LoadStateType.NotLoaded,
        },
      };
    }

    const program = state[action.payload.id];
    if (!program.data) {
      program.data = { Id: action.payload.id } as Program;
    }

    if (!program.data.changelogs) {
      program.data.changelogs = {
        key: '',
        pages: [],
        totalCount: 0,
      };
    }

    checkChangelogsStateKey(program.data.changelogs, action.payload);

    program.data.changelogs.pages[action.payload.PageNumber || 0] = {
      loadState: {
        type: LoadStateType.Loading,
      },
    };
  });
  builder.addCase(actions.loadChangelogs, (state, action) => {
    const programData = state[action.payload.params.id].data as ProgramData;
    checkChangelogsStateKey(
      programData.changelogs as PaginatedDataState<ChangelogChangeItem>,
      action.payload.params
    );
    const changelogs = programData.changelogs as PaginatedDataState<ChangelogChangeItem>;
    changelogs.pages[action.payload.params.PageNumber || 0] = {
      loadState: {
        type: LoadStateType.Loaded,
      },
      data: action.payload.data.Data,
    };
    changelogs.totalCount = action.payload.data.TotalCount;
  });
  builder.addCase(actions.loadingChangelogsError, (state, action) => {
    const programData = state[action.payload.params.id].data as ProgramData;
    checkChangelogsStateKey(
      programData.changelogs as PaginatedDataState<ChangelogChangeItem>,
      action.payload.params
    );
    const changelogs = programData.changelogs as PaginatedDataState<ChangelogChangeItem>;
    changelogs.pages[action.payload.params.PageNumber || 0].loadState = {
      type: LoadStateType.Error,
      errorMessage: action.payload.errorMessage,
    };
  });
});

export default programReducer;
