import React, { ReactElement } from 'react';
import { Icon, Message, SemanticICONS } from 'semantic-ui-react';

export enum AomMessageType {
  Warning = 0,
  Success = 1
}

export interface AomMessageProps {
  type: AomMessageType;
  message: string | ReactElement;
  header?: string;
}

interface TypeSettings {
  defaultHeader?: string;
  icon?: SemanticICONS;
}

const typeSettings: { [key: number]: TypeSettings } = {
  [AomMessageType.Warning]: {
    defaultHeader: 'Important!',
    icon: 'exclamation circle',
  },
  [AomMessageType.Success]: {
    defaultHeader: 'Success',
    icon: 'info circle',
  },
};

const AomMessage: React.FunctionComponent<AomMessageProps> = ({ type, message, header }) => {
  const settings = typeSettings[type];

  return (
    <Message warning={type === AomMessageType.Warning} success={type == AomMessageType.Success}>
      <Message.Header>
        <Icon name={settings.icon} />
        {header || settings.defaultHeader}
      </Message.Header>
      <p>{message}</p>
    </Message>
  );
};

export default AomMessage;
