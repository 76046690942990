import { chunk } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Grid, Icon, Input, InputOnChangeData } from "semantic-ui-react";
import './ExcludedPccsView.scss';

interface ExcludedPccsViewParams {
  pccs: Array<string>;
}

const pccsPerColumn = 20;
const columnsPerPage = 5;

const ExcludedPccsView: React.FunctionComponent<ExcludedPccsViewParams> = ({ pccs }) => {
  const [settings, setSettings] = useState({ search: '', pageCount: 1 });

  const pccsList = useMemo(() => {
    let result: string[];
    if (settings.search) {
      let searchRegex = new RegExp(settings.search, "i");
      result = pccs.filter(p => searchRegex.test(p));
    }
    else {
      result = pccs.slice(0);
    }

    result.sort();
    return result;
  }, [pccs, settings.search]);

  const columns = useMemo(() => chunk(pccsList, pccsPerColumn).slice(0, columnsPerPage * settings.pageCount), [pccsList, settings.pageCount]);
  const showMoreButton = (settings.pageCount * columnsPerPage * pccsPerColumn < pccsList.length) || undefined;
  const showLessButton = settings.pageCount > 1 || undefined;

  const searchChangeHandler = useCallback((_: unknown, data: InputOnChangeData) => setSettings({ pageCount: 1, search: data.value }), []);
  const setPage = (addPage: number) => setSettings({ pageCount: settings.pageCount + addPage, search: settings.search });
  const clearSearchHandler = useCallback(() => setSettings({ pageCount: 1, search: '' }), []);

  return <>
    <Input
      className="pcc-search"
      size="small"
      icon
      iconPosition="left"
      placeholder="Enter PCC part"
      type="text"
      onChange={searchChangeHandler}
    >
      <Icon name="search" />
      <input value={settings.search} />
      {settings.search && <Icon link name="delete" onClick={clearSearchHandler} />}
    </Input>
    Found {pccsList.length}
    <Grid doubling columns="14" className="excluded-pccs">
      {columns.map(column => <Grid.Column>{column.map(p => <Grid.Row key={p}>{p}</Grid.Row>)}</Grid.Column>)}
      {(showMoreButton || showLessButton) && <Grid.Column>
        {showLessButton && <><Icon name="chevron up" link color="blue" onClick={() => setPage(-1)}>
          <span className="more-button">Less</span>
        </Icon><br /></>}
        {showMoreButton && <Icon name="chevron down" link color="blue" onClick={() => setPage(1)}>
          <span className="more-button">More</span>
        </Icon>}
      </Grid.Column>}
    </Grid>
    {columns.length == 0 && <label className="no-pccs-label">No PCCs found. {settings.search && "Please clear search"}</label>}
  </>;
};

export default ExcludedPccsView;