export type AccountInput = {
  Id: string,
  Name: string,
  GdsName: string,
  Lcn: string,
  Gcn: number,
  BookingPcc: string,
  TicketingPcc: string,
  Active: boolean,
  TestAccount: boolean,
  AccountType: string,
  Smid: string,
  Sqlid: string,
  CreateDate?: string | null,
  CountryName: string,
  CountryCode: string,
};

export type ContractIdVersion = {
  ContractId: number,
  Version: number,
}

export type Contract = {
  BusinessId: number,
  CreatedBy?: AuditInfo | null,
  EndDate?: string | null,
  ExcludedPccs?: Array<string> | null,
  FileName?: string | null,
  GdsInfos?: Array<GdsData> | null,
  Id: number,
  Name: string,
  OriginalId?: number | null,
  Programs?: Array<Program> | null,
  Status: number,
  Type?: string | null,
  UpdatedBy?: AuditInfo | null,
  ValidatingCarriers?: Array<string> | null,
  Accounts?: Array<AccountExtended> | null,
  Version?: number | null,
  Versions?: Array<ContractIdVersion> | null
};

export type AuditInfo = {
  Date?: string | null,
  Email?: string | null,
  FirstName?: string | null,
  LastName?: string | null,
  Username?: string | null,
};

export type GdsData = {
  FillingMethod?: string | null,
  Gds?: string | null,
  PricingIdCode?: string | null,
};

export type Program = {
  BusinessId: number,
  CombinationRules?: CombinationRules | null,
  Commissions?: Array<CommissionInfo> | null,
  ContractId: number,
  CreatedBy?: AuditInfo | null,
  Definitions?: Array<RulesCombination> | null,
  Discounts?: Array<Discount> | null,
  Exceptions?: Array<RulesCombination> | null,
  Id: number,
  MarketRules?: Array<MarketRuleOperation> | null,
  OverTheWaterCarriers?: Array<string | null> | null,
  ProgramDescription?: string | null,
  ProgramName: string,
  Contract: Contract,
  RelatedPrograms?: RelatedPrograms,
  Status?: number | null,
  UpdatedBy?: AuditInfo | null,
  Version?: number | null,
};

export type CombinationRules = {
  LegProgramIds?: Array<number> | null,
  TicketProgramIds?: Array<number> | null,
};

export type CommissionInfo = {
  CommissionPercentage: number,
  Definitions?: Array<RulesCombination> | null,
  Exceptions?: Array<RulesCombination> | null,
  Weight?: number | null,
};

export type RelatedPrograms = {
  Type?: number,
  ProgramIds?: Array<number> | null
}

export type RulesCombination = {
  Rules: Array<Rule>,
};

export type Rule = {
  RuleName: string,
  Values?: Array<string | null> | null,
};

export type Discount = {
  Definitions?: Array<RulesCombination> | null,
  DiscountPercentage: number,
  Exceptions?: Array<RulesCombination> | null,
  Weight?: number | null,
};

export type MarketRuleOperation = {
  Definitions?: Array<RulesCombination> | null,
  Exceptions?: Array<RulesCombination> | null,
  Market: string,
  Weight?: number | null,
};

export type RepositoryFile = {
  ContractId?: number | null,
  Name: string,
  Extension: string,
  UploadedBy?: AuditInfo | null,
};

export type Account = {
  Id: string,
  Name: string,
  GdsName: string,
  Lcn: string,
  Gcn: number,
  BookingPcc: string,
  TicketingPcc: string,
  Active: boolean,
  TestAccount: boolean,
  AccountType: string,
  Smid: string,
  Sqlid: string,
  CreateDate?: string | null,
};

export type AccountExtended = {
  Id: string,
  Name: string,
  GdsName: string,
  Lcn: string,
  Gcn: number,
  BookingPcc: string,
  TicketingPcc: string,
  Active: boolean,
  TestAccount: boolean,
  AccountType: string,
  Smid: string,
  Sqlid: string,
  CreateDate?: string | null,
  CountryName: string,
  CountryCode: string,
};

export type Airport = {
  City?: string | null,
  Code: string,
  CountryCode: string,
  CountryName?: string | null,
  MutiAptCityCode?: string | null,
  MutiAptCityName?: string | null,
  Name?: string | null,
  StateCode?: string | null,
};

export type AirportsSearchOptions = {
  Skip?: number | null,
  Limit?: number | null,
  SkipData?: boolean | null,
  IncludeTotalCount?: boolean | null,
}

export type AirportsSearchResult = {
  Data: Airport[] | null,
  TotalCount: number | null
}

export type Carrier = {
  Code: string,
  EquipmentType?: string | null,
  GroupName?: string | null,
  Name: string,
  Rules?: OperatingCarriersRule | null
};

export type OperatingCarriersRule = {
  OperatingCarrier: string,
  AllowedValidatingCarriers: AllowedValidatingCarrier[]
};

type AllowedValidatingCarrier = {
  ValidatingCarrier: string,
  CodeShareComment: string
}

export type ChangelogChangeItem = {
  Changed: AuditInfo,
  Changes: ChangedItem,
  EntityId: string,
  Version?: number | null,
};

export type ChangedItem = {
  FieldName: string,
  NewValue?: string | null,
  OldValue?: string | null,
};

export type FilePageSearchOptions = {
  FileName?: string | null,
  OrderBy?: boolean | null,
  PageSize?: number | null,
  SortFieldName?: string | null,
};

export type AccountSearchOptions = {
  PageNumber?: number | null,
  PageSize?: number | null,
  SeachType?: number | null,
  SearchQuery?: string | null,
};

export type ContractFilterOptions = {
  Status?: Array<number> | null,
  ValidatingCarriers?: Array<string> | null,
  Customers?: Array<string> | null,
  Version?: Array<number | null> | null,
  ContractTypes?: Array<string> | null,
};

export type Region = {
  CountryCode: string,
  CountryName: string,
  RegionName: string,
  StateCodes?: Array<string> | null,
  TrafficConference?: string | null,
  ValidatingCarrier?: string | null,
  ZoneName?: string | null,
};

export type UploadFileMutationVariables = {
  Name: string,
};

export enum ApiEnvironment {
  DEV = 'DEV03',
  TST = 'TST03',
  UAT = 'UAT01',
  PRD = 'PRD01',
}