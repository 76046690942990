import { API } from 'aws-amplify';
import { AccountInput, AccountSearchOptions, AccountExtended } from '../../../../api/types';
import {
  AccountByGroupsModel,
  AccountOptions,
  AccountSeachType,
  LcnModel,
  RadioOptions,
} from './AccountTypes';

import * as _ from 'lodash';
import apiNames from '../../../../services/apiNames';
import apiPaths from '../../../../services/apiPaths';

type SelectedAccounts = AccountOptions | AccountExtended | AccountInput;

export const loadOptions = async (searchParams: AccountSearchOptions) => {
  const response = await API.get(apiNames.AomUI, apiPaths.accounts, { queryStringParameters: searchParams });
  return response.Data as AccountExtended[];
};

export const groupByLcn = (
  selectedRowsIds?: string[],
  selectedAccounts?: SelectedAccounts[],
  newIds?: string[]
) => {
  const groupedViews = _.groupBy(selectedAccounts, (account) => [
    account.Name,
    account.Gcn,
    account.CountryName,
    account.BookingPcc,
    account.Smid,
  ]);

  return _.keys(groupedViews).map((item) => {
    let uniqId = groupedViews[item][0].Id;
    for (let i = 1; i < groupedViews[item].length; i++) {
      uniqId = uniqId.concat(groupedViews[item][i].Id);
    }

    return {
      UnitedIds: groupedViews[item].map((p) => p.Id),
      Id: uniqId,
      Name: groupedViews[item][0].Name,
      Gcn: groupedViews[item][0].Gcn,
      BookingPcc: groupedViews[item][0].BookingPcc,
      Smid: groupedViews[item][0].Smid,
      CountryName: groupedViews[item][0].CountryName,
      Lcn: groupedViews[item].map((p) => {
        return {
          id: p.Id,
          value: p.Lcn,
          checked: newIds?.find((id) => id === p.Id) || !!selectedRowsIds?.find((id) => id === p.Id),
        } as LcnModel;
      }),
    } as AccountByGroupsModel;
  });
};

export const getSelectedIds = (groups: AccountByGroupsModel[], selectedRows: string[]) => {
  let selectedIds: any[] = [];

  groups.forEach((group) => {
    if (selectedRows.find((row) => row === group.Id)) {
      const selected = group.Lcn.filter((lcn) => lcn.checked).map((l) => l.id);
      selectedIds = selectedIds.concat(selected);
    }
  });

  return selectedIds;
};

export const mapToAccountType = (values: AccountOptions[]) => {
  values.forEach((value) => {
    delete value['text'];
    delete value['key'];
    delete value['value'];
  });

  return values;
};

export const mapDataToOptions = (accounts?: SelectedAccounts[] | null) => {
  return accounts?.map((account) => ({
    text: `${account?.Name} - ${account?.Gcn} - ${account?.BookingPcc}`,
    key: account?.Id,
    value: account?.Id,
    ...account,
  }));
};

export const getSearchOptions = (radioValue: string) => {
  if (radioValue === RadioOptions.NameRadioValue) {
    return {
      placeholder: 'Type Customer Name',
      pattern: /^[A-Za-z0-9 _]*$/,
      searchType: AccountSeachType.Name,
    };
  }
  if (radioValue === RadioOptions.GcnRadioValue) {
    return { placeholder: 'Type GCN', pattern: /^\d*$/, searchType: AccountSeachType.Gcn };
  }

  return {
    placeholder: 'Type Booking PCC',
    pattern: /^[A-Za-z0-9]*$/,
    searchType: AccountSeachType.BookingPcc,
  };
};
