import { Action, createAction, Dispatch } from '@reduxjs/toolkit';
import {
  RepositoryFile,
  UploadFileMutationVariables,
} from '../../api/types';
import { State } from '../State';
import { API, Storage } from 'aws-amplify';
import { repositoryCountFilesStateSelector, repositoryLoadStateSelector } from './repositorySelector';
import { LoadStateType } from '../commonTypes';
import { downloadBlob } from '../../app/repository/helper';
import { attachContactToFile, setNotLoadedState } from '../contractList/contractListActions';
import { addModalFile, deleteModalFile } from '../modalFiles/modalFilesActions';
import { detachFile } from '../contractList/contractListActions';
import { attachFile, detachFileFromContract } from '../contract/contractActions';
import { repositoryGridModalPageSize } from '../../constants';
import { FilesPaginatedParams } from './repositoryTypes';
import { AppDispatch } from '../useAppDispatch';
import apiNames from '../../services/apiNames';
import apiPaths, { pathById } from '../../services/apiPaths';

export const loading = createAction<number>('repository/loading');
export const loadingCountFiles = createAction('repository/loadingCountFiles');
export const setNotLoadedCountFilesState = createAction('repository/setNotLoadedCountFilesState');
export const clearAllPages = createAction('repository/clearAllPages');
export const uploading = createAction('repository/uploading');
export const load = createAction<{ repositoryFiles: RepositoryFile[]; pageNumber: number }>(
  'repository/load'
);
export const loadCountFiles = createAction<number>('repository/loadCountFiles');
export const upload = createAction('repository/upload');
export const loadingError = createAction<{ errorMessage: string; pageNumber: number }>(
  'repository/loadingCountFilesError'
);
export const loadingCountFilesError = createAction<string>('repository/countFilesLoadingError');
export const uploadingError = createAction<string>('repository/uploadingError');
export const add = createAction<{ repositoryFile: RepositoryFile; lastPage: number; pageSize: number }>(
  'repository/add'
);
export const deleteFile = createAction<{ fileName: string; pageNumber: number }>('repository/deleteFile');
export const deletingError = createAction<{ errorMessage: string; pageNumber: number }>(
  'repository/deletingError'
);
export const downloadingError = createAction<string>('repository/downloadingError');

export const attachFileToContract = createAction<{
  fileName: string;
  contractId: number;
  repositoryActivePage: number;
}>('repository/attachContract/attachToContract');
export const attachmentStart = createAction('repository/attachContract/start');
export const attachmentCompleted = createAction('repository/attachContract/completed');
export const attachmentError = createAction<{ errorMessage: string }>('repository/attachContract/error');
export const attachmentReset = createAction('repository/attachContract/reset');

export const detachAttachedFile = createAction<number>('repository/detachFile');

export const loadRepositoryCountFiles = () => {
  return async (dispatch: Dispatch<Action>, getState: () => State) => {
    const state: State = getState();

    const countFilesState = repositoryCountFilesStateSelector(state);

    if (countFilesState?.type === LoadStateType.Loading || countFilesState?.type === LoadStateType.Loaded) {
      return;
    }

    dispatch(loadingCountFiles());
    try {
      let countFiles: number;
      const response = await API.get(apiNames.AomUI, apiPaths.files, { queryStringParameters: { CountOnly: true } });
      countFiles = response.Count as number;
      dispatch(loadCountFiles(countFiles));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(loadingCountFilesError(errorMessage));
    }
  };
};

export const loadRepository = (search: FilesPaginatedParams) => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const pageNumber = search.PageNumber!;

    const repositoryState = repositoryLoadStateSelector(pageNumber)(state);
    const repositoryCountFileState = repositoryCountFilesStateSelector(state);

    if (
      repositoryCountFileState?.type === LoadStateType.Loaded &&
      (repositoryState?.type === LoadStateType.Loading || repositoryState?.type === LoadStateType.Loaded)
    ) {
      return;
    }

    dispatch(loading(pageNumber));
    try {
      let repositoryFiles: RepositoryFile[], countFilesResponse: number;
      const response = await API.get(apiNames.AomUI, apiPaths.files, { queryStringParameters: search });
      repositoryFiles = response.Data as RepositoryFile[];
      countFilesResponse = response.Count as number;
      dispatch(load({ repositoryFiles, pageNumber }));
      dispatch(loadCountFiles(countFilesResponse));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(loadingError({ errorMessage, pageNumber }));
    }
  };
};

export const uploadContractFile = (
  contract: File,
  lastPage: number,
  pageSize: number,
  addToModal: boolean
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(uploading());
      await Storage.put(contract.name, contract, { level: 'public', contentType: contract.type });
      const uploadParams: UploadFileMutationVariables = { Name: contract.name };
      let uploadResult: RepositoryFile;
      uploadResult = await API.post(apiNames.AomUI, apiPaths.files, { queryStringParameters: uploadParams }) as RepositoryFile;

      const repositoryFile = {
        ContractId: uploadResult?.ContractId,
        Name: uploadResult?.Name as string,
        Extension: uploadResult?.Extension as string,
        UploadedBy: uploadResult?.UploadedBy,
      } as RepositoryFile;
      dispatch(add({ repositoryFile, lastPage, pageSize }));
      if (addToModal) {
        dispatch(addModalFile({ repositoryFile: repositoryFile, pageSize: repositoryGridModalPageSize }));
      }
      dispatch(upload());
      dispatch(setNotLoadedCountFilesState());
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(uploadingError(errorMessage));
    }
  };
};

export const deleteContractFile = (
  fileName: string,
  contractId: number | null | undefined,
  search: FilesPaginatedParams
) => {
  return async (dispatch: AppDispatch) => {
    const pageNumber = search.PageNumber!;
    try {
      await API.del(apiNames.AomUI, apiPaths.files, { queryStringParameters: { Name: fileName, ContractId: contractId } });

      await Storage.remove(fileName);

      dispatch(deleteFile({ fileName, pageNumber }));
      if (contractId) {
        dispatch(detachFile(contractId));
        dispatch(detachFileFromContract(contractId));
      }
      dispatch(deleteModalFile(fileName));
      await dispatch(loadRepository(search));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(deletingError({ errorMessage, pageNumber }));
      throw errorMessage;
    }
  };
};

export const downloadContractFile = (fileName: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = (await Storage.get(fileName, { download: true })) as any;
      downloadBlob(response.Body, fileName);
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(downloadingError(errorMessage));
      throw errorMessage;
    }
  };
};

export const attachContractToFile = (fileName: string, contractId: number, repositoryActivePage: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(attachmentStart());
      await API.post(apiNames.AomUI, pathById(apiPaths.contracts, contractId, 'attach'), { queryStringParameters: { Filename: fileName } });

      dispatch(attachFileToContract({ fileName, contractId, repositoryActivePage }));
      dispatch(attachContactToFile({ fileName, contractId }));
      dispatch(attachFile({ fileName, contractId }));
      dispatch(attachmentCompleted());
      dispatch(setNotLoadedState());
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(attachmentError({ errorMessage }));
    }
  };
};

export const deleteUploadedFileOnAttachmentModal = (fileName: string) => {
  return async () => {
    await API.del(apiNames.AomUI, apiPaths.files, { queryStringParameters: { Name: fileName, ContractId: null } });

    await Storage.remove(fileName);
  };
};

export const renewAttachedFileState = (fileName: string, contractId: number, pageSize?: number) => {
  return async (dispatch: AppDispatch) => {
    let pageNumber: number;
    const response = await API.get(apiNames.AomUI, apiPaths.files + '/page', {
      queryStringParameters: {
        FileName: fileName,
        PageSize: pageSize || 15,
      }
    });

    pageNumber = response.PageNumber || 1;
    dispatch(attachFileToContract({ fileName, contractId, repositoryActivePage: pageNumber }));
  };
};
