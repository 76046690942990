import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { User } from '../../store/currentUser/currentUserState';

const getAttributeValueByName = (attributes: CognitoUserAttribute[], name: string) =>
  attributes.find((attribute) => attribute.Name === name)?.Value || '';

export const createUserFromAttributes: (attributes: CognitoUserAttribute[]) => User = (
  attributes: CognitoUserAttribute[]
) => ({
  name: getAttributeValueByName(attributes, 'given_name'),
  surname: getAttributeValueByName(attributes, 'family_name'),
});
