import { FC, useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ContractStatus } from '../../../../store/contractList/contractListTypes';
import { ConfigurationContext } from '../../../../services/configurationContext';
import { Environment } from '../../../../services/utils/configuration';
import { ApiEnvironment } from '../../../../api/types';
import { API } from 'aws-amplify';
import { toolsApiPaths } from '../../../../services/apiPaths';
import ApiNames from '../../../../services/apiNames';

type PropagateContractResult = {
  sourceEnvironment: Environment,
  destinationEnvironment: Environment,
  contractId: number,
  isSuccessful: boolean
}

type PropagateContractDropdownItemsProps = {
  contractId: number,
  contractStatus: ContractStatus,
  callBack: (result: PropagateContractResult) => void
}
type PropagatedProgram = { id: number, name: string };
type PropagatedContract = { id: number, name: string, programs: Array<PropagatedProgram> };
type PropagatedCounter = { id: number, newId: number, businessId: number };
type PropagateContractSuccessfulApiResult = {
  contracts: Array<PropagatedContract> | null,
  files: Array<string> | null,
  errors: Array<string> | null,
  programCounter: PropagatedCounter | null
};
export const PropagateContractDropdownItems: FC<PropagateContractDropdownItemsProps> = ({
                                                                                          contractStatus,
                                                                                          contractId,
                                                                                          callBack
                                                                                        }) => {
  const configurationContext = useContext(ConfigurationContext);
  const onClick = async (sourceEnvironment: Environment, destinationEnvironment: Environment) => {
    const uiToApiEnvironmentLookup = new Map([
      [Environment.DEV, ApiEnvironment.DEV],
      [Environment.TST, ApiEnvironment.TST],
      [Environment.UAT, ApiEnvironment.UAT],
      [Environment.PRD, ApiEnvironment.PRD]
    ]);

    const isContractMoved = (apiResponse: unknown, id: number) => {
      const successfulResponse = apiResponse as PropagateContractSuccessfulApiResult;
      const isNullOrUndefined = (x: unknown) => x === null || x === undefined;

      if (isNullOrUndefined(successfulResponse)) {
        return false;
      }
      return !isNullOrUndefined(successfulResponse.contracts) &&
        successfulResponse.contracts!.length === 1 &&
        successfulResponse.contracts![0].id === id;
    }

    try {
      const requestBody = {
        contractIds: [contractId],
        sourceEnvironment: uiToApiEnvironmentLookup.get(sourceEnvironment),
        targetEnvironment: uiToApiEnvironmentLookup.get(destinationEnvironment)
      };
      const apiResponse: unknown = await API.post(ApiNames.Tools, toolsApiPaths.contractUpdate, { body: requestBody });
      const isSuccessful = isContractMoved(apiResponse as PropagateContractSuccessfulApiResult, contractId);
      callBack({ contractId, destinationEnvironment, isSuccessful: isSuccessful, sourceEnvironment });
    } catch (e) {
      callBack({ contractId, destinationEnvironment, isSuccessful: false, sourceEnvironment });
    }
  };

  const propagateToEnvironmentText = (status: ContractStatus, destinationEnvironment: Environment) => {
    const environmentLabel = destinationEnvironment.toUpperCase();
    const action = status == ContractStatus.Active ? "Sync" : "Send";

    return `${ action } to ${ environmentLabel }`;
  };

  switch (configurationContext?.environment) {
    case Environment.PRD:
      return <Dropdown.Item text={ propagateToEnvironmentText(contractStatus, Environment.UAT) }
                            onClick={ () => onClick(Environment.PRD, Environment.UAT) } />;
    case Environment.UAT:
      return <>
        <Dropdown.Item text={ propagateToEnvironmentText(contractStatus, Environment.TST) }
                       onClick={ () => onClick(Environment.UAT, Environment.TST) } />
        <Dropdown.Item text={ propagateToEnvironmentText(contractStatus, Environment.DEV) }
                       onClick={ () => onClick(Environment.UAT, Environment.DEV) } />
      </>;
    case Environment.TST:
      return <Dropdown.Item text={ propagateToEnvironmentText(contractStatus, Environment.DEV) }
                            onClick={ () => onClick(Environment.TST, Environment.DEV) } />;
    default:
      return <></>;
  }
}