import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AomLoader from '../../../../components/AomLoader';
import ErrorMessage from '../../../../components/ErrorMessage';
import { LoadStateType } from '../../../../store/commonTypes';
import { loadProgram } from '../../../../store/program/programActionCreators';
import { programLoadStateSelector } from '../../../../store/program/programSelectors';
import { loadProgramList } from '../../../../store/programList/programListSlice';
import { programListLoadStateSelector } from '../../../../store/programList/programListSelectors';
import { contractProgramsSelector } from '../../../../store/contract/contractSelectors';
import useParamsSelector from '../../../../store/useParamsSelector';
import { useAppDispatch } from '../../../../store/useAppDispatch';
import { useParams, Outlet } from 'react-router-dom';

export type ProgramParams = {
  contractId: string;
  programId: string;
}

export const Program: React.FunctionComponent = () => {
  const params = useParams<ProgramParams>();
  const programId = parseInt(params.programId!);
  const programLoadState = useParamsSelector(programLoadStateSelector, { id: programId });
  const programListLoadState = useSelector(programListLoadStateSelector);
  const contractPrograms = useParamsSelector(contractProgramsSelector, { id: params.contractId! });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadProgram(programId));
  }, []);
  useEffect(() => {
    dispatch(loadProgramList());
  }, []);

  const isProgramExists = !!contractPrograms?.find((p) => p.Id === programId);

  if (programLoadState.type === LoadStateType.Error || programListLoadState.type === LoadStateType.Error) {
    return <ErrorMessage message={ programLoadState.errorMessage || programListLoadState.errorMessage } />;
  }

  if (!isProgramExists) {
    return <ErrorMessage message="Incorrect program is selected" />;
  }

  if (
    programListLoadState.type === LoadStateType.NotLoaded ||
    programListLoadState.type === LoadStateType.Loading ||
    programLoadState.type === LoadStateType.NotLoaded ||
    programLoadState.type === LoadStateType.Loading
  ) {
    return <AomLoader />;
  }

  return <Outlet />;
};
