import { CarriersState } from './carriers/carriersTypes';
import { ConfirmationState } from './confirmation/confirmationTypes';
import { ContractState } from './contract/contractTypes';
import { ContractListState } from './contractList/contractListTypes';
import { MarketDataState } from './marketData/marketDataTypes';
import { ModalFilesState } from './modalFiles/modalFilesTypes';
import { ProgramState } from './program/programTypes';
import { ProgramListState } from './programList/programListTypes';
import { RepositoryState } from './repository/repositoryTypes';
import { UserState } from './currentUser/currentUserState';
import { RelatedProgramsState } from './relatedPrograms/relatedProgramsTypes';
import { ContractsChangelogsState } from './contractsChangelogs/contractsChangelogsTypes';

export type State = {
  contractList: ContractListState;
  contract: ContractState;
  carriers: CarriersState;
  program: ProgramState;
  marketData: MarketDataState;
  programList: ProgramListState;
  relatedProgramList: RelatedProgramsState;
  confirmation: ConfirmationState;
  repository: RepositoryState;
  modalFiles: ModalFilesState;
  currentUser: UserState;
  contractsChangelogs: ContractsChangelogsState
};

export const stateSelector = (state: State) => state;
