import { createSelector } from '@reduxjs/toolkit';
import { ArrayEmpty } from '../commonTypes';
import { State } from '../State';
import { CarrierListItem } from './carriersTypes';
import { memoize } from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import { formatCodeshareComment } from '../../app/repository/helper';
import { Carrier } from '../../api/types';

export const carriersSelector = (state: State) => state.carriers.data;

export const carriersLoadStateSelector = (state: State) => state.carriers.loadState;

const mapCarrier = (carrier: Carrier) =>
  ({
    code: carrier.Code,
    name: carrier.Name,
    equipmentType: carrier.EquipmentType,
    rules: carrier.Rules,
  } as CarrierListItem);

export const carriersWithGroupsSelector = createSelector([carriersSelector], (carriers) => {
  if (!carriers) {
    return ArrayEmpty;
  }

  const carriersList = Object.values(carriers).sort((a, b) => a.Name.localeCompare(b.Name));

  const carrierGroups = carriersList.reduce((groups, c) => {
    if (!c.GroupName) {
      return groups;
    }

    if (!groups[c.GroupName]) {
      groups[c.GroupName] = {
        name: c.GroupName,
        code: c.GroupName,
        equipmentType: c.EquipmentType,
        tooltipHeader: c.GroupName,
        tooltip: '',
        carriers: [],
        rules: c.Rules,
      } as CarrierListItem;
    }

    groups[c.GroupName].carriers?.push(mapCarrier(c));
    groups[c.GroupName].tooltip += `${(groups[c.GroupName].tooltip && '\n') || ''}${c.Name} (${c.Code})`;

    return groups;
  }, {} as { [key: string]: CarrierListItem });
  return Object.values(carrierGroups).concat(carriersList.map(mapCarrier));
});

export const carriersByCodeSelector = (codes: string[]) =>
  createSelector(
    carriersWithGroupsSelector,
    (carriers) =>
      (carriers &&
        codes?.map(
          (c) => carriers.find((cr) => cr.code === c) || ({ code: c, name: c } as CarrierListItem)
        )) ?? ArrayEmpty
  );

//TODO: Refactor for parameterized selectors
export const carriersWithTooltipSelector =
  (form: UseFormReturn<any> | undefined, validatingField: string) => (codes: string[]) =>
    createSelector([carriersByCodeSelector(codes)], (carriers) => {
      const result: CarrierListItem[] = [];
      carriers.forEach((carrier) => {
        if (!carrier.rules) {
          result.push(carrier);
        } else {
          const connectedValue = form?.getValues(validatingField);
          const validatingCarriers = carrier.rules?.AllowedValidatingCarriers.map(
            (avc) => avc.ValidatingCarrier
          );
          const intersection = connectedValue.filter((value: string) => validatingCarriers.includes(value));
          if (intersection?.length) {
            let tooltipText = 'Please note that booked ';
            intersection.forEach(
              (c: string) =>
                (tooltipText += `${c} flight can only be operated by ${formatCodeshareComment(
                  carrier.rules?.AllowedValidatingCarriers?.find((vc) => vc.ValidatingCarrier == c)
                    ?.CodeShareComment
                )};`)
            );
            tooltipText = tooltipText.slice(0, -1);

            result.push({ ...carrier, additionalRuleTooltip: tooltipText });
          } else {
            result.push(carrier);
          }
        }
      });
      return result;
    });

export const carriersByEquipmentTypeSelector = memoize((equipmentTypes: string[]) =>
  createSelector([carriersWithGroupsSelector], (carriers) => {
    return (carriers && carriers.filter((c) => equipmentTypes.includes(c.equipmentType))) || [];
  })
);
