import { createReducer } from '@reduxjs/toolkit';
import { LoadStateType } from '../commonTypes';
import { findFilesByContractId, findFileOnPage } from '../helpers';
import * as actions from './repositoryActions';
import { RepositoryState } from './repositoryTypes';

const removeFile = (state: RepositoryState, fileName: string, pageNumber: number) => {
  const files = state.files[pageNumber].data;
  const index = files ? files?.findIndex((c) => c.Name === fileName) : -1;
  if (index !== -1 && files?.length) {
    files.splice(index, 1);
  }
};

export const initialState: RepositoryState = {
  files: {},
  lastActionFile: {
    loadState: {
      type: LoadStateType.NotLoaded,
    },
  },
  lastAttachedFileState: {
    type: LoadStateType.NotLoaded,
    errorMessage: null,
  },
  countFiles: {
    loadState: {
      type: LoadStateType.NotLoaded,
    },
  },
};
const repositoryReducer = createReducer<RepositoryState>(
  initialState,
  (builder) => {
    builder.addCase(actions.loading, (state, action) => {
      state.files[action.payload] = {
        loadState: {
          type: LoadStateType.Loading,
        },
      };
    });
    builder.addCase(actions.loadingCountFiles, (state) => {
      state.countFiles.loadState.type = LoadStateType.Loading;
    });
    builder.addCase(actions.uploading, (state) => {
      state.lastActionFile.loadState.type = LoadStateType.Loading;
      state.lastActionFile.loadState.errorMessage = null;
    });
    builder.addCase(actions.loadCountFiles, (state, action) => {
      state.countFiles.loadState.type = LoadStateType.Loaded;
      state.countFiles.data = action.payload;
    });
    builder.addCase(actions.setNotLoadedCountFilesState, (state) => {
      state.countFiles.loadState.type = LoadStateType.NotLoaded;
    });
    builder.addCase(actions.load, (state, action) => {
      state.files[action.payload.pageNumber].loadState.type = LoadStateType.Loaded;
      state.files[action.payload.pageNumber].data = action.payload.repositoryFiles;
    });
    builder.addCase(actions.clearAllPages, (state) => {
      for (const pageNumber of Object.keys(state.files)) {
        state.files[pageNumber].loadState.type = LoadStateType.NotLoaded;
        state.files[pageNumber].data = [];
      }
    });
    builder.addCase(actions.upload, (state) => {
      state.lastActionFile.loadState.type = LoadStateType.Loaded;
    });
    builder.addCase(actions.deleteFile, (state, action) => {
      removeFile(state, action.payload.fileName, action.payload.pageNumber);
    });
    builder.addCase(actions.loadingError, (state, action) => {
      state.files[action.payload.pageNumber] = {
        loadState: {
          type: LoadStateType.Error,
          errorMessage: action.payload.errorMessage,
        },
      };
    });
    builder.addCase(actions.loadingCountFilesError, (state, action) => {
      state.countFiles = {
        loadState: {
          type: LoadStateType.Error,
          errorMessage: action.payload,
        },
      };
    });
    builder.addCase(actions.uploadingError, (state, action) => {
      state.lastActionFile.loadState.type = LoadStateType.Error;
      state.lastActionFile.loadState.errorMessage = action.payload;
    });
    builder.addCase(actions.downloadingError, (state, action) => {
      state.lastActionFile.loadState.type = LoadStateType.Error;
      state.lastActionFile.loadState.errorMessage = action.payload;
    });
    builder.addCase(actions.deletingError, (state, action) => {
      state.files[action.payload.pageNumber] = {
        loadState: {
          type: LoadStateType.Error,
          errorMessage: action.payload.errorMessage,
        },
      };
    });
    builder.addCase(actions.add, (state, action) => {
      let lastPage = action.payload.lastPage;
      if ((state?.files[lastPage]?.data?.length as number) < action.payload.pageSize) {
        state.files[lastPage].data?.push(action.payload.repositoryFile);
      } else if (lastPage === 0 && !state?.files[lastPage]?.data) {
        lastPage++;
        state.files[lastPage].data?.push(action.payload.repositoryFile);
      }
      if (state.countFiles.data) {
        state.countFiles.data += 1;
      }
    });
    builder.addCase(actions.attachmentStart, (state) => {
      state.lastAttachedFileState = {
        type: LoadStateType.Loading,
        errorMessage: null,
      };
    });
    builder.addCase(actions.attachFileToContract, (state, { payload }) => {
      const fileOnPage = findFileOnPage(state, payload.fileName);
      if (fileOnPage?.file) {
        fileOnPage.file.ContractId = payload.contractId;
      }
    });
    builder.addCase(actions.attachmentCompleted, (state) => {
      state.lastAttachedFileState = {
        type: LoadStateType.Loaded,
        errorMessage: null,
      };
    });
    builder.addCase(actions.attachmentReset, (state) => {
      state.lastAttachedFileState = {
        type: LoadStateType.NotLoaded,
        errorMessage: null,
      };
    });
    builder.addCase(actions.attachmentError, (state, action) => {
      state.lastAttachedFileState = {
        type: LoadStateType.Error,
        errorMessage: action.payload.errorMessage,
      };
    });
    builder.addCase(actions.detachAttachedFile, (state, action) => {
      const files = findFilesByContractId(state, action.payload);
      if (files.length) {
        files.forEach((f) => (f.ContractId = null));
      }
    });
  }
);

export default repositoryReducer;
