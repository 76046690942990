export type UploadFile = File | null;
export type UploadedFile = { fileName: string } | null;
export type FileToAttach = UploadFile | UploadedFile | null;
export type AttachToContract = { Name: string };

export const isUploadFileGuard = (file: FileToAttach): file is UploadFile => {
  return (file as File).lastModified !== undefined;
};

export const isSelectUploadedFileGuard = (file: FileToAttach): file is UploadedFile => {
  const selectedFile = file as UploadedFile;
  return selectedFile?.fileName !== undefined;
};
