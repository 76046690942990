import { createAction } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { Contract } from '../../api/types';
import { State } from '../State';
import { contractListPageLoadStateSelector } from './contractListSelectors';
import {
    ContractListItem,
    ContractListPaginatedDataLoadPayload,
    ContractListPaginatedParams
} from './contractListTypes';
import { LoadStateType, PaginatedDataLoadErrorPayload, PaginatedResult } from '../commonTypes';
import { AppDispatch } from '../useAppDispatch';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';
import { ContractListFilters } from '../../app/contracts/ContractList/ContractListFilterPanel';

export const loading = createAction<ContractListPaginatedParams>('contractList/loading');
export const setSelectedPage = createAction<{ activePage: number }>('contractList/setSelectedPage');
export const load = createAction<ContractListPaginatedDataLoadPayload>('contractList/load');
export const loadingError =
    createAction<PaginatedDataLoadErrorPayload<ContractListPaginatedParams>>('contractList/loadingError');
export const add = createAction<Contract>('contractList/add');
export const update = createAction<Contract>('contractList/update');
export const removeContract = createAction<{ contractId: number }>('contractList/delete');
export const attachContactToFile = createAction<{ fileName: string; contractId: number }>(
    'contractList/attach'
);

export const updateSelectedFilterValues = createAction<ContractListFilters>('contractList/updateSelectedFilters');
export const resetSelectedFilterValues = createAction('contractList/resetSelectedFilters');
export const detachFile = createAction<number>('contractList/detachFile');
export const setNotLoadedState = createAction('contractList/setNotLoadedState');

export const loadContractList = (searchParams?: ContractListPaginatedParams) => {
    return async (dispatch: AppDispatch, getState: () => State) => {
        const state: State = getState();
        const search = searchParams ?? ({} as ContractListPaginatedParams);
        const contractsState = contractListPageLoadStateSelector(state, search);
        if (contractsState.type === LoadStateType.Loaded || contractsState.type === LoadStateType.Loading) {
            return;
        }

        dispatch(loading(search));
        try {
            const response = await API.get(apiNames.AomUI, apiPaths.contracts, {queryStringParameters: createQueryParams(search)});
            dispatch(
                load({
                    params: search,
                    data: response as PaginatedResult<ContractListItem>,
                    filterOptions: response.FilterOptions,
                })
            );
        } catch (e: any) {
            const errorMessage = e.response?.errors?.length ? e.response.errors.map((er: any) => er.message).join('. ') : e.message;
            dispatch(loadingError({params: search, errorMessage}));
        }
    };
};

const createQueryParams = (params: ContractListPaginatedParams) => {
    let queryParams: { [key: string]: unknown } =
        params?.EndDate && params?.EndDate?.length !== 0
            ? {...params, EndDate: params?.EndDate?.map(x => x.toISOString()) ?? null}
            : {...params, EndDate: null};

    for (const key in queryParams) {
        if ((queryParams[key] === null || queryParams[key] === undefined)) {
            delete queryParams[key];
        }
    }

    return queryParams;
}
