import { createReducer } from '@reduxjs/toolkit';
import { loadingError, loadingMarketData, loadMarketData } from './marketDataActions';
import { MarketDataState } from './marketDataTypes';
import { LoadStateType } from '../commonTypes';

export const initialState: MarketDataState = {
  loadState: {
    type: LoadStateType.NotLoaded,
  },
  data: {
    destinations: {}
  }
};

const marketDataReducer = createReducer<MarketDataState>(
  initialState,
  (builder) => {
    builder.addCase(loadingMarketData, (state) => {
      state.loadState.type = LoadStateType.Loading;
      state.loadState.errorMessage = null;
    });
    builder.addCase(loadMarketData, (state, action) => {
      state.loadState.type = LoadStateType.Loaded;
      state.data = action.payload;
    });
    builder.addCase(loadingError, (state, action) => {
      state.loadState.type = LoadStateType.Error;
      state.loadState.errorMessage = action.payload;
    });
  }
);

export default marketDataReducer;
