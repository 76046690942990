import { createAction } from '@reduxjs/toolkit';
import { ConfirmationButton, ConfirmationState } from './confirmationTypes';
import { AppDispatch } from '../useAppDispatch';

export const show = createAction<ConfirmationState>('confirmation/show');
export const hide = createAction('confirmation/hide');
export const buttonLoading = createAction<ConfirmationButton>('confirmation/buttonLoading');

export const showConfirmation =
  (content: string, header?: string, buttons?: ConfirmationButton[]) => (dispatch: AppDispatch) => {
    dispatch(show({ content, header, buttons }));
  };
