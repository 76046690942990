import React, { useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownOnSearchChangeData } from 'semantic-ui-react';
import { FormWithRules } from '../../services/rules';
import { Destination, OptionCategory } from '../../store/marketData/marketDataTypes';
import { marketDataSearchSelector } from '../../store/marketData/marketDataSelectors';
import Category from './Category';
import './LocationsSelector.scss';
import { formatMarketDataOption, parseMarketDataOptions } from '../../store/marketData/marketDataMapping';
import { arraysEqual } from '../../app/contracts/Contract/Program/RulesView/RulesView';

export interface MarketOptionsProps {
  controllerName: string;
  form: UseFormReturn<FormWithRules>;
  isSelection?: boolean;
  rules?: any;
  parentLocations?: string[];
  placeholder?: string;
  minAreaLevel?: OptionCategory;
  sameLineAreaOptions?: OptionCategory[];
}

export const extractValues = (array?: string[] | null) => array?.map((value: string) => value.split('_')[1]);

type ShowCategory = {
  [key in OptionCategory]: boolean;
};

export const parseOptionCategory = (value: string): OptionCategory => {
  return parseInt(value) as OptionCategory;
};

const mapToOptions = (category: OptionCategory, destinations?: Destination[]) =>
  destinations?.map((d) => ({
    text: d.code + ((d.name && d.name !== d.code && ` - ${d.name}`) ?? ''),
    value: formatMarketDataOption(category, d.code),
  })) ?? [];

const createShowCategory = (): ShowCategory => ({
  [OptionCategory.TrafficConference]: false,
  [OptionCategory.Region]: false,
  [OptionCategory.Zone]: false,
  [OptionCategory.Country]: false,
  [OptionCategory.State]: false,
  [OptionCategory.MetroArea]: false,
  [OptionCategory.Airport]: false,
})

const LocationsSelector: React.FunctionComponent<MarketOptionsProps> = ({
                                                                          form,
                                                                          rules,
                                                                          isSelection,
                                                                          parentLocations,
                                                                          controllerName,
                                                                          sameLineAreaOptions,
                                                                          minAreaLevel,
                                                                          placeholder
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const formValue = form.getValues(controllerName) as string[];
  const [stateValue, setStateValue] = useState<string[]>(formValue ?? []);

  const getShowCategory = (value: string) => {
    const type = parseOptionCategory(value.split('_')[0]);
    const category: ShowCategory = createShowCategory();
    if (sameLineAreaOptions?.includes(type)) {
      sameLineAreaOptions.forEach((area) => (category[area] = true));
    } else {
      category[type] = true;
    }

    return category;
  };

  const initialCategory = (showAll: boolean) => {
    if (!stateValue?.length || showAll) {
      return Object.keys(OptionCategory).reduce((categories, k) => {
        const category = parseOptionCategory(k);
        if (minAreaLevel === undefined || category <= minAreaLevel) {
          categories[category] = true;
        }
        return categories;
      }, createShowCategory());
    }
    return getShowCategory(stateValue[0]);
  };

  useEffect(() => {
    if (formValue && !arraysEqual(formValue, stateValue)) {
      setStateValue(formValue);
      if (!formValue.length) {
        setShowCategory(initialCategory(true));
      }
    }
  }, [formValue]);

  const [showCategory, setShowCategory] = useState<ShowCategory>(initialCategory(false));

  const selectedOptions = parseMarketDataOptions(stateValue);
  const parentOptions = parentLocations && parseMarketDataOptions(parentLocations);
  const searchedMarketData = useSelector(
    marketDataSearchSelector(
      searchQuery,
      selectedOptions,
      parentOptions,
      minAreaLevel
    )
  );

  const trafficConferencesOption = useMemo(
    () =>
      searchedMarketData?.[OptionCategory.TrafficConference]?.map((tc) => ({
        text: tc.code,
        value: formatMarketDataOption(OptionCategory.TrafficConference, tc.code),
      })) ?? [],
    [searchedMarketData]
  );
  const regionsOptions = useMemo(
    () =>
      searchedMarketData?.[OptionCategory.Region]?.map((r) => ({
        text: r.code,
        value: formatMarketDataOption(OptionCategory.Region, r.code),
      })) ?? [],
    [searchedMarketData]
  );
  const zonesOptions = useMemo(
    () =>
      searchedMarketData?.[OptionCategory.Zone]?.map((z) => ({
        text: z.code,
        value: formatMarketDataOption(OptionCategory.Zone, z.code),
      })) ?? [],
    [searchedMarketData]
  );
  const countriesOptions = useMemo(
    () =>
      searchedMarketData?.[OptionCategory.Country]?.map((c) => ({
        text: c.name,
        value: formatMarketDataOption(OptionCategory.Country, c.name),
      })) ?? [],
    [searchedMarketData]
  );
  const metroAreasOptions = useMemo(
    () => mapToOptions(OptionCategory.MetroArea, searchedMarketData?.[OptionCategory.MetroArea]),
    [searchedMarketData]
  );
  const statesOptions = useMemo(
    () =>
      searchedMarketData?.[OptionCategory.State]?.map((s) => ({
        text: s.code,
        value: formatMarketDataOption(OptionCategory.State, s.code),
      })) ?? [],
    [searchedMarketData]
  );
  const airportsOptions = useMemo(
    () => mapToOptions(OptionCategory.Airport, searchedMarketData?.[OptionCategory.Airport]),
    [searchedMarketData]
  );

  const hide = (value: string) => {
    if (!stateValue.length) {
      const category = getShowCategory(value);
      setShowCategory(category);
    }
  };

  const show = (state: string[]) => {
    if (state.length == 0) {
      setShowCategory(initialCategory(false));
    }
  };

  const handleChangeSelection = (value: string, added: boolean | undefined, onChange: any) => {
    let newState = stateValue.splice(0);
    setSearchQuery('');
    if (added) {
      newState.push(value);
      setStateValue(newState);
      hide(value);
    } else {
      newState = newState.filter((x) => x != value);
      setStateValue(newState);
      show(newState);
    }
    onChange(newState);
  };

  const handleSearchChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) =>
    setSearchQuery(data.searchQuery);

  const rowName = useMemo(
    () => controllerName.substring(0, controllerName.lastIndexOf('.')),
    [controllerName]
  );

  return (
    <Controller
      control={form.control}
      name={controllerName}
      rules={rules?.generateValidate ? rules?.generateValidate(form, rowName) : rules}
      render={({ field: { onChange, value } }) => (
        <Dropdown
          text={
            stateValue.length > 0
              ? extractValues(stateValue)?.join(', ')
              : placeholder ?? 'Select Direction(s)'
          }
          pointing
          className="destination-type-selector"
          onChange={(_, data) => onChange(data.value)}
          value={value as string[]}
          multiple
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          selection={isSelection}
          search
        >
          <Dropdown.Menu>
            
              {showCategory[OptionCategory.TrafficConference] && (
                <Category
                  headerContent="Traffic Conferences"
                  onChange={onChange}
                  handleChangeSelection={handleChangeSelection}
                  options={trafficConferencesOption}
                  stateValue={stateValue}
                  optionCategory={OptionCategory.TrafficConference}
                />
              )}
              {showCategory[OptionCategory.Region] && <Category
                headerContent="Regions"
                onChange={onChange}
                handleChangeSelection={handleChangeSelection}
                options={regionsOptions}
                stateValue={stateValue}
                optionCategory={OptionCategory.Region}
              />}
              {showCategory[OptionCategory.Zone] && <Category
                headerContent="Zones"
                onChange={onChange}
                handleChangeSelection={handleChangeSelection}
                options={zonesOptions}
                stateValue={stateValue}
                optionCategory={OptionCategory.Zone}
              />}
              {showCategory[OptionCategory.Country] && <Category
                headerContent="Countries"
                onChange={onChange}
                handleChangeSelection={handleChangeSelection}
                options={countriesOptions}
                stateValue={stateValue}
                optionCategory={OptionCategory.Country}
              />}
              {showCategory[OptionCategory.MetroArea] && (
                <Category
                  headerContent="Metro Areas"
                  onChange={onChange}
                  handleChangeSelection={handleChangeSelection}
                  options={metroAreasOptions}
                  stateValue={stateValue}
                  optionCategory={OptionCategory.MetroArea}
                />
              )}
              {showCategory[OptionCategory.State] && <Category
                headerContent="States"
                onChange={onChange}
                handleChangeSelection={handleChangeSelection}
                options={statesOptions}
                stateValue={stateValue}
                optionCategory={OptionCategory.State}
              />}
              {showCategory[OptionCategory.Airport] && <Category
                headerContent="Airports"
                onChange={onChange}
                handleChangeSelection={handleChangeSelection}
                options={airportsOptions}
                stateValue={stateValue}
                optionCategory={OptionCategory.Airport}
              />}
          </Dropdown.Menu>
        </Dropdown>
      )}
    />
  );
};

export default LocationsSelector;
