import React, { useEffect, useState } from 'react';
import { AccountByGroupsModel } from '../AccountTypes';
import { Checkbox, Table } from 'semantic-ui-react';
import LcnDropdown from './LcnDropdown';
import './AccountTable.scss';

export interface ViewAccountInfoProps {
  selectedAccounts?: AccountByGroupsModel[];
  className?: string;
}

export interface AccountInfoProps {
  selectedAccounts?: AccountByGroupsModel[];
  selectedRows: string[];
  setSelectedRows?: (selected: string[]) => void;
  isReadOnly?: boolean;
  className?: string;
}

export const ViewNotBorderedAccountTable: React.FunctionComponent<ViewAccountInfoProps> = ({
  selectedAccounts,
}) => {
  return (
    <AccountTable
      selectedAccounts={selectedAccounts}
      isReadOnly={true}
      className="no-bordered"
      selectedRows={[]}
    />
  );
};

export const ViewAccountTable: React.FunctionComponent<ViewAccountInfoProps> = ({
  selectedAccounts,
  className,
}) => {
  return (
    <AccountTable
      selectedAccounts={selectedAccounts}
      isReadOnly={true}
      className={className}
      selectedRows={[]}
    />
  );
};

const AccountTable: React.FunctionComponent<AccountInfoProps> = ({
  selectedAccounts,
  selectedRows,
  setSelectedRows,
  isReadOnly,
  className,
}) => {
  const [accounts, setAccounts] = useState<string[]>([]);
  const [headerChecked, setHeaderChecked] = useState<boolean>(false);

  useEffect(() => {
    const selectedNodes: string[] = [];
    selectedAccounts?.forEach((node: any) => {
      const exists = accounts.find((ac) => ac === node.Id);
      if (!exists) {
        const model = selectedAccounts?.find((m) => m.Id === node.Id) || null;
        model?.Lcn?.every((l: any) => (l.checked = true));
        selectedNodes.push(node.Id);
      } else if (exists && selectedRows.find((r: any) => r === node.Id)) {
        selectedNodes.push(node.Id);
      }
    });

    setAccounts(selectedAccounts?.map((ac) => ac.Id) || []);
    if (setSelectedRows) setSelectedRows(selectedNodes);
  }, [selectedAccounts]);

  const onCheckboxChanged = (account: AccountByGroupsModel, checked: boolean) => {
    if (checked && setSelectedRows) {
      setSelectedRows((selectedRows || []).concat(account.Id));
    } else if (setSelectedRows) {
      setSelectedRows(selectedRows.filter((el) => el !== account.Id));
    }
  };

  const onHeaderCheckboxChanged = (checked: boolean) => {
    setHeaderChecked(checked);

    if (checked && setSelectedRows) {
      setSelectedRows(selectedAccounts?.map((ac) => ac.Id) || []);
    } else if (setSelectedRows) {
      setSelectedRows([]);
    }
  };

  return (
    <>
      {' '}
      {!!selectedAccounts?.length && (
        <Table basic className={`account-table ${className}`}>
          <Table.Header>
            <Table.Row>
              {!isReadOnly && (
                <Table.HeaderCell width={1}>
                  <Checkbox
                    className="account-checkbox"
                    checked={headerChecked}
                    onChange={(_e, data) => onHeaderCheckboxChanged(data.checked as boolean)}
                  />
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>Customer Name</Table.HeaderCell>
              <Table.HeaderCell>Related GCN ID</Table.HeaderCell>
              <Table.HeaderCell>Booking country</Table.HeaderCell>
              <Table.HeaderCell>SMID</Table.HeaderCell>
              <Table.HeaderCell>GDS PCC/OID</Table.HeaderCell>
              <Table.HeaderCell>LCN</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedAccounts?.map((c) => {
              return (
                <Table.Row key={c.Id}>
                  {!isReadOnly && (
                    <Table.Cell>
                      <Checkbox
                        className="account-checkbox"
                        checked={!!selectedRows?.find((r) => r === c.Id)}
                        onChange={(_e, data) => onCheckboxChanged(c, data.checked as boolean)}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell>{c.Name}</Table.Cell>
                  <Table.Cell>{c.Gcn}</Table.Cell>
                  <Table.Cell>{c.CountryName || c.BookingPcc}</Table.Cell>
                  <Table.Cell>{c.Smid}</Table.Cell>
                  <Table.Cell>{c.BookingPcc}</Table.Cell>
                  <Table.Cell className="lcn-dropdown">
                    {<LcnDropdown lcns={c.Lcn || []} isReadOnly={!!isReadOnly} />}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

export default AccountTable;
