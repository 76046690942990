import React, { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import { RulesCombination } from '../../../../../api/types';
import { IRulesAssociation } from '../../../../../services/rules/getRuleValues';
import { RuleName } from '../../../../../services/rules/RuleName';
import { allColumns, Column, listFormatter } from './columns';
import './RulesView.css';

interface RulesViewProperties {
  data: IRulesAssociation[];
  primaryFieldName?: string;
  primaryFieldDisplayName?: string;
}

type Row = RulesCombination & {
  primaryField?: object;
  isFromTo: boolean;
  exceptions?: RulesCombination[];
  isExeptionsHidden: boolean;
  isPrimaryHidden: boolean;
  rowSpan: number;
};

const columns = [
  allColumns.ValidatingCarriers,
  allColumns.MarketingCarriers,
  allColumns.OperatingCarriers,
  allColumns.TourCodes,
  allColumns.TicketDesignators,
  allColumns.ExcludedFlightNumbers,
];

const getColumns = (data: IRulesAssociation[]): Column[] => {
  const uniqueRules = data.reduce((acc, a) => {
    if (a.Definitions) {
      a.Definitions.forEach((d) => {
        d.Rules.forEach((r) => {
          if (!acc.includes(r.RuleName)) {
            acc.push(r.RuleName);
          }
        });
      });
    }
    return acc;
  }, Array<string>());

  return columns.filter((c) => c.rules.find((r) => uniqueRules.includes(r)));
};

export const departureRules = [
  RuleName.departureAirportCodeIn,
  RuleName.departureCountryCodeIn,
  RuleName.departureMetroAreaCodeIn,
  RuleName.departureStateCodeIn,
  RuleName.departureZoneIn,
  RuleName.departureRegionIn,
  RuleName.departureTrafficConferenceIn,
] as string[];

export const arrivalRules = [
  RuleName.arrivalAirportCodeIn,
  RuleName.arrivalCountryCodeIn,
  RuleName.arrivalMetroAreaCodeIn,
  RuleName.arrivalStateCodeIn,
  RuleName.arrivalZoneIn,
  RuleName.arrivalRegionIn,
  RuleName.arrivalTrafficConferenceIn,
] as string[];

export const arraysEqual = (a?: Array<string | null> | null, b?: Array<string | null> | null) => {
  if (!a && !b) {
    return true;
  }

  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }

  if (a.length != b.length) {
    return false;
  }

  return a.every((v) => b.includes(v));
};

const getRows = (data: IRulesAssociation[], primaryFieldName?: string) => {
  return data.reduce((rows, d) => {
    if (d.Definitions) {
      let pushedRowsCount = 0;
      d.Definitions.forEach((c, index) => {
        const row = Object.assign<Row, RulesCombination>({} as Row, c);
        row.primaryField = primaryFieldName && (d as any)[primaryFieldName];

        if (index != 0) {
          row.isPrimaryHidden = true;
        }

        if (d.Exceptions) {
          if (index === 0) {
            row.exceptions = d.Exceptions;
          } else {
            row.isExeptionsHidden = true;
          }
        }

        if (index > 0 && rows.length > 0) {
          const from = row.Rules.find((r) => departureRules.includes(r.RuleName));
          const to = row.Rules.find((r) => arrivalRules.includes(r.RuleName));
          if (from && to) {
            const previous = rows.find((previousRow) => {
              const previousFrom = previousRow.Rules.find((r) => departureRules.includes(r.RuleName));
              const previousTo = previousRow.Rules.find((r) => arrivalRules.includes(r.RuleName));
              if (
                to &&
                from &&
                previousFrom &&
                previousTo &&
                previousRow.primaryField == row.primaryField &&
                previousFrom.RuleName === departureRules[arrivalRules.indexOf(to.RuleName)] &&
                previousTo.RuleName === arrivalRules[departureRules.indexOf(from.RuleName)] &&
                arraysEqual(previousFrom.Values, to.Values) &&
                arraysEqual(previousTo.Values, from.Values) &&
                row.Rules.length == previousRow.Rules.length &&
                row.Rules.filter(
                  (r) => !departureRules.includes(r.RuleName) && !arrivalRules.includes(r.RuleName)
                ).every((r) =>
                  arraysEqual(r.Values, previousRow.Rules.find((pr) => pr.RuleName === r.RuleName)?.Values)
                )
              ) {
                return previousRow;
              }
            });
            if (previous) {
              previous.isFromTo = true;
              return;
            }
          }
        }
        rows.push(row);
        pushedRowsCount++;
      });
      rows[rows.length - pushedRowsCount].rowSpan = pushedRowsCount;
    }
    return rows;
  }, Array<Row>());
};

const formatExceptions = (exceptions: RulesCombination[]): ReactNode => {
  return exceptions
    .map((e) =>
      e.Rules.map((r) => {
        const column = columns.find((c) => c.rules.includes(r.RuleName as RuleName)) as any;
        const formatter = column?.formatters?.[r.RuleName] || listFormatter;
        return (
          <>
            <b>{column?.displayName}</b>
            {r.Values && ': '}
            {r.Values && formatter(r.Values)}
          </>
        );
      }).map((el, i) => (
        <>
          {(i && <> and </>) || ''}
          {el}
        </>
      ))
    )
    .map((l, i) => (
      <>
        {(i && (
          <>
            <br /> or{' '}
          </>
        )) ||
          ''}
        {l}
      </>
    ));
};

export const mapColumnToClassName = {
  ['Fare basis']: 'fare-basis',
} as { [key: string]: string };

const RulesView: React.FunctionComponent<RulesViewProperties> = React.memo(
  ({ data, primaryFieldName, primaryFieldDisplayName }) => {
    const columns = getColumns(data);
    const rows = getRows(data, primaryFieldName);
    const hasExceptions = rows.find((r) => r.exceptions);

    return (
      <Table celled className={!primaryFieldName ? 'noprimary' : ''}>
        <Table.Header>
          <Table.Row>
            {primaryFieldName && (
              <Table.HeaderCell>{primaryFieldDisplayName || primaryFieldName}</Table.HeaderCell>
            )}
            {columns.map((c) => (
              <>
                <Table.HeaderCell>{c.displayName}</Table.HeaderCell>
                {c.rules.find((r) => departureRules.includes(r)) && (
                  <Table.HeaderCell width="1">Direction</Table.HeaderCell>
                )}
              </>
            ))}
            {hasExceptions && <Table.HeaderCell>Excluding</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows.map((r) => (
            <Table.Row>
              {primaryFieldName && !r.isPrimaryHidden && (
                <Table.Cell rowSpan={r.rowSpan || 1} singleLine>
                  <>
                    {' '}
                    {r.primaryField}
                  </>
                </Table.Cell>
              )}
              {columns.map((c) => (
                <>
                  <Table.Cell className={mapColumnToClassName[c.displayName] as any as string}>
                    {r.Rules.filter((dr) => c.rules.includes(dr.RuleName as RuleName))
                      .map((dr) => dr.Values && (c.formatters?.[dr.RuleName] || listFormatter)(dr.Values))
                      .join(', ')}
                  </Table.Cell>
                  {c.rules.find((r) => departureRules.includes(r)) && (
                    <Table.Cell className={mapColumnToClassName[c.displayName] as any as string}>
                      {r.isFromTo ? <>To/From</> : <>To</>}
                    </Table.Cell>
                  )}
                </>
              ))}
              {hasExceptions && !r.isExeptionsHidden && (
                <Table.Cell rowSpan={(r.exceptions && r.rowSpan) || 1}>
                  {r.exceptions && formatExceptions(r.exceptions)}
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
);

export default RulesView;
