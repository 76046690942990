import { createSelector } from '@reduxjs/toolkit';
import { State } from '../State';
import { createParameterSelector } from '../useParamsSelector';

export interface PageParams {
  currentPage: number;
}

const pageParamSelector = createParameterSelector<PageParams, number>((params) => params.currentPage);

const modalSelector = createSelector(
  (state: State) => state,
  pageParamSelector,
  (state: State, pageNumber: number) => state.modalFiles.files[pageNumber]
);

export const modalLoadStateSelector = createSelector(modalSelector, (files) => files?.loadState);

export const modalDataSelector = createSelector(modalSelector, (files) => files?.data);

export const modalTotalCountSelector = (state: State) => state.modalFiles.countFiles;
