import { AccountExtended } from '../../../../api/types';

export type LcnModel = {
  id: string;
  value: string;
  checked: boolean;
};

export type AccountByGroupsModel = {
  UnitedIds: string[];
  Id: string;
  Name: string;
  Gcn: number;
  BookingPcc: string;
  Smid: string;
  CountryName: string;
  Lcn: LcnModel[];
};

export interface AccountOptions extends AccountExtended {
  text?: string;
  value?: string;
  key?: string;
}

export enum AccountSeachType {
  Name,
  Gcn,
  BookingPcc,
}

export enum RadioOptions {
  NameRadioValue = 'Name',
  GcnRadioValue = 'Gcn',
  PccRadioValue = 'BookingPcc',
}
