import { BreadCrumbDynamicSelectorItem, BreadCrumbItem, Segment } from './types';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import React, { FC } from 'react';
import { AomStaticBreadCrumb } from './AomStaticBreadCrumb';
import { AomDynamicBreadCrumb } from './AomDynamicBreadCrumb';

const isDynamic = (item: BreadCrumbItem): item is BreadCrumbDynamicSelectorItem =>
  (item as BreadCrumbDynamicSelectorItem).selector !== undefined;

export type AomBreadcrumbSectionProps = { node: Segment };
export const AomBreadcrumbSection: FC<AomBreadcrumbSectionProps> = ({ node }) => {
  const location = useLocation();

  const toSectionProps = (segment: Segment, currentPath: string) => {
    const { match: { pathname: segmentPath }, breadcrumb: { disableNavigation } } = segment;
    if (currentPath === segmentPath || `${currentPath}/` === segmentPath) {
      return { active: true };
    }
    
    return disableNavigation ? {} : { link: true, to: segment.match.pathname, as: Link };
  }

  const { breadcrumb } = node;

  return <Breadcrumb.Section {...(toSectionProps(node, location.pathname))}>
    {isDynamic(breadcrumb)
      ? <AomDynamicBreadCrumb item={breadcrumb} />
      : <AomStaticBreadCrumb item={breadcrumb} />}
  </Breadcrumb.Section>;
}






