import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Dimmer,
  Dropdown,
  Icon,
  Label,
  Loader,
  Pagination,
  Popup,
  SemanticCOLORS,
  Table,
} from 'semantic-ui-react';
import { Contract } from '../../../api/types';
import AomLoader from '../../../components/AomLoader';
import { getContractStatusProps } from '../../../store/contract/contractSelectors';
import { loadContractList, setSelectedPage } from '../../../store/contractList/contractListActions';
import {
  activePageSelector,
  contractListFilterOptionsSelector,
  contractListPageLoadStateSelector,
  contractListPageSelector, contractListSelectedValuesSelector,
  contractListTotalCountSelector,
} from '../../../store/contractList/contractListSelectors';
import {
  ContractListItem,
  ContractListPaginatedParams,
  ContractStatus,
} from '../../../store/contractList/contractListTypes';
import { combinePath } from '../../path.utils';
import SearchBar from '../../../components/SearchBar';
import { deleteContractHandler, getChangeStatusHandler, statusColorMap } from '../Contract/ContractView';
import './ContractList.scss';
import formatDecimal from '../../../services/utils/formatDecimal';
import { repositoryPageSize } from '../../../constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { getRouterFilePageState } from './helper';
import useParamsSelector from '../../../store/useParamsSelector';
import { LoadStateType } from '../../../store/commonTypes';
import AttachFileModal from '../../repository/AttachFileModal/AttachFileModal';
import { FileToAttach, isSelectUploadedFileGuard, isUploadFileGuard } from '../../repository/types';
import { attachContractToFile, uploadContractFile } from '../../../store/repository/repositoryActions';
import { repositoryCountFilesSelector } from '../../../store/repository/repositorySelector';
import SortController from '../../../components/SortController';
import { mapOrderByToRequestType, OrderBy } from '../../../components/SortController/SortController';
import ContractListFilterPanel, { ContractListFilters } from './ContractListFilterPanel';
import getUTCDate from '../../../services/utils/getUTCDate';
import getDateLastSecond from '../../../services/utils/getDateLastSecond';
import _ from 'lodash';
import { useAppDispatch } from '../../../store/useAppDispatch';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const pageSize = 15;
const popupTopPadding = 12;
const popupRecordFullHeight = 24;
const defaultRecordsCount = 9;

type ContractDateWarningData = {
  color: SemanticCOLORS;
  content: string;
};

export interface RouterFilePageState {
  pageNumber?: number | null;
  fileName: string;
}

const getDateWarningInfo = (contract: ContractListItem, now: Moment) => {
  if (contract.Status !== 1 || !contract.EndDate) {
    return undefined;
  }
  const endDate = moment(contract.EndDate);

  if (endDate.diff(now, 'days') <= 0) {
    return {
      color: 'red' as SemanticCOLORS,
      content: 'This agreement has expired.',
    } as ContractDateWarningData;
  }

  if (endDate.diff(now, 'days') < 90) {
    return {
      color: 'orange' as SemanticCOLORS,
      content: 'This agreement expires in less than 90 days.',
    } as ContractDateWarningData;
  }

  return undefined;
};

const calculateLastPage = (pageSize: number, count: number) => {
  const totalPages = Math.ceil(count / pageSize);
  return totalPages > 0 ? totalPages : totalPages + 1;
};

export const ContractList: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith('/contract')) {
      dispatch(setSelectedPage({ activePage: 0 }));
    }
  }, [location]);

  const actualPage = useSelector(activePageSelector);
  const [search, setSearch] = useState<string>('');
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);
  const [errorRedirectMessage, setErrorRedirectMessage] = useState<string | null>(null);
  const [attachModalContract, setAttachModalContract] = useState<ContractListItem | null>(null);
  const [showHoveringLoader, setShowHoveringLoader] = useState(false);
  const [activePage, setActivePage] = useState(actualPage);
  const [highlightedContract, setHighlightedContract] = useState<ContractListItem | null>(null);
  const [editFilters, setEditFilters] = useState(false);

  const repositoryCountFiles = useSelector(repositoryCountFilesSelector);

  const handleUploadContract = async (contract: FileToAttach) => {
    if (!contract) {
      return;
    }
    const lastPage = calculateLastPage(repositoryPageSize, repositoryCountFiles);
    if (isUploadFileGuard(contract)) {
      await dispatch(uploadContractFile(contract, lastPage, repositoryPageSize, false));
      await dispatch(attachContractToFile(contract.name, attachModalContract?.Id as number, lastPage));
      setFileToAttach(null);
    } else if (isSelectUploadedFileGuard(contract)) {
      await dispatch(attachContractToFile(contract.fileName, attachModalContract?.Id as number, lastPage));
    }
    setHighlightedContract(attachModalContract);
  };
  const [sortFieldName, setSortFieldName] = useState<string>('');
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.Inactive);
  const filters = useSelector(contractListSelectedValuesSelector)
  useEffect(() => {
    if (highlightedContract != null) {
      setHighlightedContract(null);
    }

    return () => {
      setHighlightedContract(null);
    };
  }, [activePage, search]);

  const getSearchParams = (pageNumber: number, includeFilterOptions?: boolean) =>
    ({
      PageSize: pageSize,
      PageNumber: pageNumber - 1,
      SearchQuery: search,
      SortFieldName: sortFieldName,
      OrderBy: mapOrderByToRequestType[orderBy],
      IncludeFilterOptions: includeFilterOptions,
      ...filters,
      ...(filters.EndDate?.length ? {
        EndDate: [
          getUTCDate(filters.EndDate[0]),
          getDateLastSecond(getUTCDate(filters.EndDate[1] || filters.EndDate[0]), true),
        ]
      } : {})
    });

  const searchParams: ContractListPaginatedParams = useMemo(
    () => getSearchParams(activePage, true),
    [activePage, search, sortFieldName, orderBy, filters]
  );

  const contractsLoadedState = useParamsSelector(contractListPageLoadStateSelector, searchParams);
  const contracts = useParamsSelector(contractListPageSelector, searchParams);
  const contractsTotalCount = useParamsSelector(contractListTotalCountSelector, searchParams);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (contracts?.length === 0 && activePage !== 1) {
      setActivePage(activePage - 1);
    } else {
      dispatch(loadContractList(searchParams));
      dispatch(setSelectedPage({ activePage: searchParams.PageNumber ?? 0 }));
    }
  }, [searchParams, contracts]);

  const [fileToAttach, setFileToAttach] = useState<FileToAttach>(null);
  const filterOptions = useParamsSelector(contractListFilterOptionsSelector, searchParams);

  const handleSearchChange = (value: string) => {
    setActivePage(1);
    setSearch(value);
  };

  const handlePageChange = async (pageNumber: number) => {
    setShowHoveringLoader(true);
    await dispatch(loadContractList(getSearchParams(pageNumber)));
    setActivePage(pageNumber);
    setShowHoveringLoader(false);
  };

  const anyContractsFound = contractsTotalCount > 0;

  const now = moment();

  if (errorRedirectMessage) {
    return <ErrorMessage message={errorRedirectMessage} />;
  }

  const title = (
    <>
      <h2>Contracts</h2>
      <div className="action-panel">
        <Button
          size="mini"
          color="blue"
          className="action-button"
          basic
          onClick={() => setEditFilters(!editFilters)}
        >
          <Icon name={editFilters ? 'chevron up' : 'chevron down'} />
          Filter
        </Button>
        <Button
          size="mini"
          className="action-button"
          primary
          onClick={() => {
            navigate('/contracts/create');
          }}
        >
          <Icon name="plus circle" />
          Create new contract
        </Button>
      </div>
      <ContractListFilterPanel
        editMode={editFilters}
        filterOptions={filterOptions}
        selected={filters}
      />
      <SearchBar
        handleSearchChange={handleSearchChange}
        placeholder="Search by ID or contract name"
        searchDelay={500}
        minSearchLength={2}
      />
    </>
  );

  const paging = contractsTotalCount > pageSize && (
    <Pagination
      activePage={activePage}
      totalPages={Math.ceil(contractsTotalCount / pageSize)}
      onPageChange={(_, { activePage }) => handlePageChange(activePage as number)}
      firstItem={null}
      lastItem={null}
      prevItem={{ content: <Icon name="angle left" />, icon: true }}
      nextItem={{ content: <Icon name="angle right" />, icon: true }}
      ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true, className: 'ellipsis' }}
    />
  );

  if (
    contractsLoadedState.type === LoadStateType.NotLoaded ||
    contractsLoadedState.type === LoadStateType.Loading
  ) {
    return (
      <>
        {title}
        <AomLoader className="contracts-list-loading" />
        {paging}
      </>
    );
  }

  if (contractsLoadedState.type === LoadStateType.Error) {
    return (
      <>
        {title}
        <ErrorMessage message={contractsLoadedState.errorMessage} />
      </>
    );
  }

  if (!anyContractsFound) {
    return (
      <>
        {title}
        <div className="empty-search">
          <h2>No contracts found</h2>
          {(search != '' ||
            Object.keys(filters).find((f) => filters[f as keyof ContractListFilters]?.length)) && (
            <h4>Please try searching again using different words and removing your filters.</h4>
          )}
        </div>
      </>
    );
  }

  const onViewFileHandle = async (fileName: string) => {
    try {
      setIsRedirectLoading(true);
      const state = await getRouterFilePageState(fileName.split('.')[0]);
      setIsRedirectLoading(false);
      navigate('/repository/list', { state });
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      setErrorRedirectMessage(errorMessage);
    }
  };

  const handleSortChange = (orderBy: OrderBy, fieldName: string) => {
    setOrderBy(orderBy);
    setSortFieldName(fieldName);
    setActivePage(1);
  };

  const renderSortController = (fieldName: keyof ContractListItem) => (
    <SortController
      fieldName={fieldName}
      activeFieldName={sortFieldName}
      activeOrder={orderBy}
      onClick={handleSortChange}
    />
  );

  const renderSortedHeaderCell = (headerText: string, sortFieldName: keyof ContractListItem) => (
    <div className="sort-header">
      <div className="header-text">{headerText}</div>
      {renderSortController(sortFieldName)}
    </div>
  );

  const getCustomersList = (contract: Contract) => {
    return contract.Accounts?.map((c) => `${c.Name}/${c.CountryName}`);
  };

  const getCustomersPopupHeight = (recordsCount: number) => {
    const visibleRecordsCount = recordsCount <= defaultRecordsCount ? recordsCount : defaultRecordsCount;
    return popupTopPadding + visibleRecordsCount * popupRecordFullHeight;
  };

  return (
    <>
      {title}
      {attachModalContract && (
        <AttachFileModal
          onClose={() => setAttachModalContract(null)}
          contract={{ value: attachModalContract }}
          modal={{ isOpen: isOpen, onChange: setIsOpen }}
          fileToAttach={{
            onAttach: handleUploadContract,
            value: fileToAttach,
            onChange: setFileToAttach,
          }}
        />
      )}
      <Table selectable>
        <Dimmer active={showHoveringLoader || isRedirectLoading} inverted>
          <Loader size="medium">Loading</Loader>
        </Dimmer>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{renderSortedHeaderCell('ID', 'BusinessId')}</Table.HeaderCell>
            <Table.HeaderCell width={6}>{renderSortedHeaderCell('Contract name', 'Name')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{renderSortedHeaderCell('Version', 'Version')}</Table.HeaderCell>
            <Table.HeaderCell width={2}>Customer</Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {renderSortedHeaderCell('Airline', 'ValidatingCarriers')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>{renderSortedHeaderCell('End date', 'EndDate')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{renderSortedHeaderCell('Files', 'FileName')}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{renderSortedHeaderCell('Status', 'Status')}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {contracts?.map((c) => {
            const contractStatusProps = getContractStatusProps(c as Contract);
            const warningInfo = getDateWarningInfo(c, now);
            const customersList = getCustomersList(c as Contract);

            return (
              <Table.Row key={c.Id} active={highlightedContract?.Id == c.Id}>
                <Table.Cell className="business-id">{c.BusinessId}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={combinePath('/contracts/:contractId', {
                      contractId: c?.Id,
                    })}
                  >
                    {c.Name}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDecimal(c.Version)}</Table.Cell>
                <Table.Cell>
                  {_.first(customersList) ?? ''}
                  {customersList && customersList.length > 1 && (
                    <Popup
                      className="customers-popup"
                      trigger={
                        <span className="customers-popup-counter">{` +${customersList.length - 1}`}</span>
                      }
                      style={{ height: `${getCustomersPopupHeight(customersList.length)}px` }}
                      on="hover"
                      position="right center"
                      basic
                      hoverable
                    >
                      {customersList.map((customer) => (
                        <p key={customer} className="customers-popup-record">{customer}</p>
                      ))}
                    </Popup>
                  )}
                </Table.Cell>
                <Table.Cell className="airlines">
                  {c.ValidatingCarriers?.map((c) => (
                    <Label key={c} circular color="blue">
                      {c}
                    </Label>
                  ))}
                </Table.Cell>
                <Table.Cell>{(c.EndDate && moment(c.EndDate).utc().format('MM/DD/YYYY')) || '-'}</Table.Cell>
                <Table.Cell>
                  {c.FileName && (
                    <Button
                      className="view-file"
                      color="blue"
                      onClick={async () => await onViewFileHandle(c.FileName as string)}
                    >
                      View File
                    </Button>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Label>
                    <Icon name="circle" size="tiny" color={statusColorMap[c.Status]} />{' '}
                    {ContractStatus[c.Status]}
                  </Label>
                  {warningInfo && (
                    <Popup
                      trigger={<Icon name="bell" color={warningInfo.color} />}
                      position="bottom center"
                      content={warningInfo.content}
                      offset={[0, 3]}
                    />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Dropdown direction="left" icon="ellipsis vertical">
                    <Dropdown.Menu>
                      {contractStatusProps.edit && (
                        <Dropdown.Item
                          text="Edit"
                          onClick={() =>
                            navigate(
                              combinePath(
                                '/contracts/:contractId/edit',
                                { contractId: c.Id }
                              )
                            )
                          }
                        />
                      )}
                      {contractStatusProps.update && (
                        <Dropdown.Item
                          text="Update"
                          onClick={() =>
                            navigate(
                              combinePath(
                                '/contracts/:contractId/update',
                                { contractId: c.Id }
                              )
                            )
                          }
                        />
                      )}
                      <Dropdown.Item
                        text="Changelogs"
                        onClick={() =>
                          navigate(
                            combinePath(
                              '/contracts/:contractId/changelogs',
                              { contractId: c.Id }
                            )
                          )
                        }
                      />
                      {contractStatusProps.attach && (
                        <Dropdown.Item
                          text="Attach file"
                          onClick={() => {
                            setAttachModalContract(c);
                            setIsOpen(true);
                          }}
                        />
                      )}
                      {contractStatusProps.versionComparison && (
                        <Dropdown.Item
                          text="Version Comparison"
                          onClick={() =>
                            navigate(
                              combinePath(
                                '/contracts/:contractId/versionComparison',
                                { contractId: c.Id }
                              )
                            )
                          }
                        />
                      )}
                      {contractStatusProps.activate?.isAvailable && (
                        <Dropdown.Item
                          text="Activate"
                          disabled={!contractStatusProps.activate?.isEnabled}
                          title={contractStatusProps.activate?.disabledReason}
                          onClick={getChangeStatusHandler(
                            dispatch,
                            c.Id,
                            'Are you sure you want to activate this contract?',
                            'Activate',
                            ContractStatus.Active,
                            c?.Name
                          )}
                        />
                      )}
                      <Dropdown.Item
                        text="Clone"
                        onClick={() =>
                          navigate(
                            combinePath(
                              '/contracts/:contractId/clone',
                              { contractId: c.Id }
                            )
                          )
                        }
                      />
                      {contractStatusProps.deactivate && (
                        <Dropdown.Item
                          text="Deactivate"
                          onClick={getChangeStatusHandler(
                            dispatch,
                            c.Id,
                            'Are you sure you want to deactivate this contract?',
                            'Deactivate',
                            ContractStatus.Inactive,
                            c?.Name
                          )}
                        />
                      )}
                      {contractStatusProps.delete && (
                        <Dropdown.Item
                          text="Delete"
                          onClick={deleteContractHandler(
                            dispatch,
                            c,
                            navigate,
                            'Are you sure you want to delete this contract? This action can not be undone.',
                            'Delete',
                            c?.Name
                          )}
                        />
                      )}
                      {contractStatusProps.extend && (
                        <Dropdown.Item
                          text="Extend"
                          onClick={() =>
                            navigate(
                              combinePath(
                                '/contracts/:contractId/extend',
                                { contractId: c.Id }
                              )
                            )
                          }
                        />
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {paging}
    </>
  );
};
