import React, { useState } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import './SearchBar.css';

export interface SearchBarProps {
  placeholder: string;
  handleSearchChange: (value: string) => void;
  searchDelay?: number;
  minSearchLength?: number;
  defaultValue?: string | null
}

const specialCharacters = '\'"^%*/!`#$&()+=?<>|~{}[]"';

let searchTimeout: NodeJS.Timeout;

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  placeholder,
  handleSearchChange,
  searchDelay,
  minSearchLength,
  defaultValue
}) => {
  const [value, setValue] = useState('');

  const handleInput = (newValue: string) => {
    if (specialCharacters.includes(newValue[newValue.length - 1])) {
      return;
    }

    setValue(newValue);

    if (minSearchLength && newValue && newValue.length < minSearchLength) {
      return;
    }

    if (searchDelay) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        handleSearchChange(newValue);
      }, searchDelay);
      return;
    }

    handleSearchChange(newValue);
  };

  const handleDelete = () => {
    setValue('');
    handleSearchChange('');
  };

  return (
    <>
      <div className="search-bar">
        <Input
          value={value || defaultValue}
          onChange={(e, data) => handleInput(data.value)}
          placeholder={placeholder}
          iconPosition="left"
        >
          <Icon name="search" />
          <input />
          {(value || defaultValue)?.length ? <Icon name="delete" link onClick={handleDelete} /> : undefined}
        </Input>
      </div>
    </>
  );
};

export default SearchBar;
