import React from 'react';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { OptionCategory } from '../../../store/marketData/marketDataTypes';

interface CategoryProps {
  headerContent: string;
  onChange: any;
  handleChangeSelection: any;
  options: Array<{ text: string; value: string }>;
  stateValue: string[];
  optionCategory: OptionCategory;
}

const Category: React.FunctionComponent<CategoryProps> = ({
                                                            handleChangeSelection,
                                                            headerContent,
                                                            onChange,
                                                            optionCategory,
                                                            options,
                                                            stateValue
                                                          }) => {
  
  if (options == null  || options.length === 0) {
    return <></>;
  }
  
  return (
    <>
      <Dropdown.Header content={headerContent} />
      {options?.map((tc) => (
        <Dropdown.Item key={tc.value} value={tc.value} text={tc.text}>
          <Checkbox
            label={tc.text}
            checked={
              stateValue.includes(tc.value) ||
              stateValue.includes(`${optionCategory}_${tc.text}`)
            }
            onChange={(_, data) => handleChangeSelection(tc.value, data.checked, onChange)}
          />
        </Dropdown.Item>
      ))}
    </>
  );
};

export default Category;
