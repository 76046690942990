import { createSelector } from '@reduxjs/toolkit';
import { IdParams } from '../commonTypes';
import { State } from '../State';
import { createParameterSelector } from '../useParamsSelector';

export const repositoryLoadStateSelector = (pageNumber: number) => (state: State) =>
  state.repository.files[pageNumber]?.loadState;

export const lastFileLoadStateSelector = (state: State) => state.repository.lastActionFile.loadState;

export const attachContractLoadStateSelector = (state: State) => state.repository.lastAttachedFileState;

export const repositorySelector = (pageNumber: number) => (state: State) =>
  state.repository.files[pageNumber]?.data;

export const repositoryCountFilesSelector = (state: State) => state.repository.countFiles.data || 0;

export const repositoryCountFilesStateSelector = (state: State) => state.repository.countFiles.loadState;

const nameParamSelector = createParameterSelector<IdParams<string | null>, string | null>(
  (params) => params.id
);
export const repositoryCurrentFileSelector = createSelector(nameParamSelector, (name) => ({ Name: name }));
