import React, { FC } from 'react';
import { BreadCrumbDynamicSelectorItem } from './types';
import { useParams } from 'react-router-dom';
import useParamsSelector from '../../store/useParamsSelector';
import { ContractData } from '../../store/contract/contractTypes';
import { ProgramData } from '../../store/program/programTypes';

type SupportedSelectorContent = ContractData | ProgramData | { Name: string | null } | undefined;
export const AomDynamicBreadCrumb: FC<{ item: BreadCrumbDynamicSelectorItem }> = ({ item: { selector, name, paramsKey} }) => {
  const params = useParams();
  const id = params[paramsKey];
  if (!id) {
    throw new Error(`${paramsKey} is not found`)
  }
  
  const content = useParamsSelector(selector, { id }) as SupportedSelectorContent;

  if (!content) {
    return null;
  }

  const isProgramData = (data: SupportedSelectorContent): data is ProgramData => {
    return (data as ProgramData).ProgramName !== undefined;
  }

  const contentName = isProgramData(content) ? content.ProgramName : content.Name;
  return <>{`${name ?? ''}${contentName}`}</>;
}