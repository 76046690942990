import { createSelector } from '@reduxjs/toolkit';
import { notLoadedState } from '../commonTypes';
import { State, stateSelector } from '../State';
import { createParameterSelector } from '../useParamsSelector';
import { ContractChangelogsFilterOptionsState, ContractsChangelogSearchOptions } from './contractsChangelogsTypes';

type Nullish<T> = T | null | undefined;

const pageNumberParamSelector = createParameterSelector<ContractsChangelogSearchOptions, number>(
  (params) => params.PageNumber ?? 0
);
const pageSizeParamSelector = createParameterSelector<ContractsChangelogSearchOptions, number>(
  (params) => params.PageSize ?? 0
);
const sortFieldNameParamSelector = createParameterSelector<ContractsChangelogSearchOptions, string | null>(
  (params) => params.SortFieldName
);
const orderByParamSelector = createParameterSelector<ContractsChangelogSearchOptions, boolean | null>(
  (params) => params.OrderBy
);
const searchQueryParamSelector = createParameterSelector<ContractsChangelogSearchOptions, Nullish<string>>(
  (params) => params.SearchQuery
);

const contractTypesParamSelector = createParameterSelector<ContractsChangelogSearchOptions, Nullish<string[]>>(
  (params) => params.ContractTypes
);

const changeDateFromParamSelector = createParameterSelector<ContractsChangelogSearchOptions, Nullish<string>>(
  (params) => params.ChangeDateFrom
);

const changeDateToParamSelector = createParameterSelector<ContractsChangelogSearchOptions, Nullish<string>>(
  (params) => params.ChangeDateTo
);

const userNamesParamSelector = createParameterSelector<ContractsChangelogSearchOptions, Nullish<string[]>>(
  (params) => params.UserNames
);

const validatingCarriersParamSelector = createParameterSelector<ContractsChangelogSearchOptions, Nullish<string[]>>(
  (params) => params.ValidatingCarriers
);

export const contractsChangelogsKeySelector = createSelector(
  pageSizeParamSelector,
  sortFieldNameParamSelector,
  orderByParamSelector,
  searchQueryParamSelector,
  contractTypesParamSelector,
  changeDateFromParamSelector,
  changeDateToParamSelector,
  userNamesParamSelector,
  validatingCarriersParamSelector,
  (
    pageSize,
    sortFieldName,
    orderBy,
    searchQuery,
    contractTypes,
    changeDateFrom,
    changeDateTo,
    userNames,
    validatingCarriers
  ) => `${ pageSize }_${ sortFieldName }_${ typeof orderBy === 'boolean' ? orderBy : '' }_${ searchQuery ?? '' }` +
    `_${ contractTypes?.join(',') ?? '' }_${ userNames?.join(',') ?? '' }_${ validatingCarriers?.join(',') ?? '' }`
    + `_${ changeDateFrom ?? '' }_${ changeDateTo ?? '' }`
);

const contractsChangelogsPageStateSelector = createSelector(
  (state: State) => state,
  contractsChangelogsKeySelector,
  pageNumberParamSelector,
  (state, key, pageNumber) =>
    state.contractsChangelogs.key === key ? state.contractsChangelogs.pages?.[pageNumber] : undefined
);

export const contractsChangelogsPageLoadStateSelector = createSelector(
  contractsChangelogsPageStateSelector,
  (page) => page?.loadState ?? notLoadedState
);

export const contractsChangelogsPageSelector = createSelector(contractsChangelogsPageStateSelector, (page) => page?.data);

export const contractsChangelogsTotalCountSelector = createSelector(
  (state: State) => state,
  contractsChangelogsKeySelector,
  (state, key) => (state.contractsChangelogs.key === key ? state.contractsChangelogs.totalCount : 0)
);

export const contractChangelogsFilterOptionsSelector = createSelector(
  stateSelector,
  contractsChangelogsKeySelector,
  (state, key): ContractChangelogsFilterOptionsState => (
    state.contractsChangelogs.key === key
      ? state.contractsChangelogs.filterOptions
      : { ValidatingCarriers: null, Users: null, ContractTypes: null, ChangeDateFrom: null, ChangeDateTo: null })
);

