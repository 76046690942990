import React, { useEffect, useState, useMemo } from 'react';
import { Button, Icon, Loader, Modal, Pagination, Table } from 'semantic-ui-react';
import { LoadStateType } from '../../../store/commonTypes';
import moment from 'moment';
import AuditUserInfo from '../../../components/AuditUserInfo';
import useParamsSelector from '../../../store/useParamsSelector';
import {
  modalDataSelector,
  modalLoadStateSelector,
  modalTotalCountSelector,
} from '../../../store/modalFiles/modalFilesSelector';
import { useSelector } from 'react-redux';
import {
  attachContractLoadStateSelector,
  lastFileLoadStateSelector,
} from '../../../store/repository/repositorySelector';
import { deleteModalFileByPage, loadFilesModal } from '../../../store/modalFiles/modalFilesActions';
import { FileToAttach, UploadedFile } from '../types';
import { loadRepositoryCountFiles } from '../../../store/repository/repositoryActions';
import SearchBar from '../../../components/SearchBar';
import { mapOrderByToRequestType, OrderBy } from '../../../components/SortController/SortController';
import SortController from '../../../components/SortController';
import { ModalFilesListItem, ModalFilesPaginatedParams } from '../../../store/modalFiles/modalFilesTypes';
import { repositoryGridModalPageSize } from '../../../constants';
import { getFullFileName } from '../helper';
import { useAppDispatch } from '../../../store/useAppDispatch';

interface RepositoryGridModalProps {
  modal: {
    isOpen: boolean;
    onChange: (isOpen: boolean) => void;
    headerText: string;
  };
  submitButton: {
    onClick: (file: FileToAttach) => Promise<void>;
    disabled: boolean;
  };
  selectedFile: {
    value: UploadedFile;
    onChange: (repositoryFile: UploadedFile) => void;
  };
}

const RepositoryGridModal: React.FunctionComponent<RepositoryGridModalProps> = (props) => {
  const { modal, submitButton, selectedFile } = props;
  const dispatch = useAppDispatch();
  const [filesModalActivePage, setFilesModalActivePage] = useState(1);
  const modalFilesTotalCount = useSelector(modalTotalCountSelector);
  const lastFileUploadState = useSelector(lastFileLoadStateSelector);
  const modalFiles = useParamsSelector(modalDataSelector, { currentPage: filesModalActivePage });
  const modalFileLoadState = useParamsSelector(modalLoadStateSelector, { currentPage: filesModalActivePage });
  const attachedContractLoadState = useSelector(attachContractLoadStateSelector);
  const [search, setSearch] = useState<string>('');
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.Inactive);
  const [sortFieldName, setSortByField] = useState<string>('');

  useEffect(() => {
    dispatch(loadRepositoryCountFiles());
  }, [dispatch]);
  const modalTotalPages = Math.ceil(modalFilesTotalCount / repositoryGridModalPageSize);
  const anyFilesFound = modalFilesTotalCount > 0;

  const loader = <Loader active className="modal-loader" />;

  const handleSearchChange = (value: string) => {
    setFilesModalActivePage(1);
    setSearch(value);
  };

  const getSearchParams = (pageNumber: number) =>
    Object.assign({
      PageSize: repositoryGridModalPageSize,
      PageNumber: pageNumber,
      SearchQuery: search,
      SortFieldName: sortFieldName,
      OrderBy: mapOrderByToRequestType[orderBy],
      IsAttached: false,
    });

  const searchParams: ModalFilesPaginatedParams = useMemo(
    () => getSearchParams(filesModalActivePage),
    [filesModalActivePage, search, sortFieldName, orderBy]
  );

  useEffect(() => {
    dispatch(loadFilesModal(searchParams));
  }, [dispatch, searchParams]);

  const handleSortChange = (orderBy: OrderBy, fieldName: string) => {
    setOrderBy(orderBy);
    setSortByField(fieldName);
    setFilesModalActivePage(1);
  };

  const renderSortController = (fieldName: keyof ModalFilesListItem) => (
    <SortController
      fieldName={fieldName}
      activeFieldName={sortFieldName}
      activeOrder={orderBy}
      onClick={handleSortChange}
    />
  );

  const renderSortedHeaderCell = (headerText: string, sortFieldName: keyof ModalFilesListItem) => (
    <div className="sort-header">
      <div className="header-text">{headerText}</div>
      {renderSortController(sortFieldName)}
    </div>
  );

  return (
    <>
      <Modal open={modal.isOpen}>
        <Modal.Header>
          <h3>{modal.headerText}</h3>
        </Modal.Header>
        <Modal.Content>
          <div>Select the file to attach the contract</div>
          <SearchBar
            handleSearchChange={handleSearchChange}
            placeholder="Search by file name"
            searchDelay={500}
            minSearchLength={2}
          />
          {(!modalFileLoadState?.type || modalFileLoadState.type === LoadStateType.Loading) && loader}
          {anyFilesFound && modalFileLoadState?.type === LoadStateType.Loaded && (
            <>
              <Table selectable={true}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={5}>
                      {renderSortedHeaderCell('File name', 'Name')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1}>
                      {renderSortedHeaderCell('Type', 'Extension')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      {renderSortedHeaderCell('Date uploaded', 'Date')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      {renderSortedHeaderCell('Uploaded by', 'FullName')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {modalFiles?.map((file) => {
                    return (
                      <Table.Row
                        key={getFullFileName(file)}
                        onClick={() => {
                          selectedFile.onChange({ fileName: getFullFileName(file) });
                        }}
                        active={selectedFile.value?.fileName == getFullFileName(file)}
                      >
                        <Table.Cell>{file.Name}</Table.Cell>
                        <Table.Cell>{file.Extension}</Table.Cell>
                        <Table.Cell>
                          {(file?.UploadedBy?.Date &&
                            moment(file.UploadedBy.Date).utc().format('DD MMMM YYYY')) ||
                            ''}
                        </Table.Cell>
                        <Table.Cell>
                          <AuditUserInfo data={file.UploadedBy} hideDate={true} />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </>
          )}
          {anyFilesFound && modalFilesTotalCount > repositoryGridModalPageSize ? (
            <Pagination
              activePage={filesModalActivePage}
              totalPages={modalTotalPages}
              onPageChange={(e, { activePage }) => setFilesModalActivePage(activePage as number)}
              firstItem={null}
              lastItem={null}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
                className: 'ellipsis',
              }}
            />
          ) : null}
        </Modal.Content>
        <Modal.Actions style={{ justifyContent: 'flex-end' }}>
          <Button
            basic
            color="blue"
            style={{ marginRight: 0 }}
            onClick={() => {
              modal.onChange(false);
              selectedFile.onChange(null);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            color="blue"
            onClick={async () => {
              dispatch(deleteModalFileByPage(filesModalActivePage));
              await submitButton.onClick(selectedFile.value);
            }}
            loading={
              lastFileUploadState.type === LoadStateType.Loading ||
              attachedContractLoadState.type === LoadStateType.Loading
            }
            disabled={submitButton.disabled}
          >
            Attach
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default RepositoryGridModal;
