import React, { ReactElement } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Dropdown, Icon, SemanticWIDTHS, Table } from 'semantic-ui-react';
import LocationsSelector from '../LocationsSelector';
import { FormWithRules } from '../../services/rules';
import CarriesDropdown from '../CarriesDropdown';
import { rulesFieldsArrayFormatter } from '../RulesViewTable';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { arraysEqual } from '../../app/contracts/Contract/Program/RulesView/RulesView';
import { MarketData, MarketDataState, OptionCategory, GdsOptions } from '../../store/marketData/marketDataTypes';
import { parseMarketDataOptions, formatMarketDataOption } from '../../store/marketData/marketDataMapping';
import { CarrierListItem } from '../../store/carriers/carriersTypes';
import { filteredMarketDataSelector } from '../../store/marketData/marketDataSelectors';
import { State } from '../../store/State';
import SingleDateSelector from '../DatesSelector/SingleDateSelector';

type Tooltip = {
  icon: SemanticICONS;
  position: any;
  content?: string;
  table?: any;
  offset?: any;
};

export enum MinWidth {
  Average = 150,
  Large = 200,
}

export type Column = {
  name: string;
  additionalFields?: string[];
  displayName: string;
  placeholder?: string;
  rules?: any;
  control?: (
    form: UseFormReturn<FormWithRules>,
    rowName: string,
    column: Column,
    data?: any,
    columnData?: ColumnData
  ) => ReactElement;
  width?: SemanticWIDTHS;
  tooltip?: Tooltip;
  minWidth?: MinWidth;
  className?: string;
};

export interface TableActions {
  toggleExclude: (rowIndex: number) => void;
}

export interface ColumnData {
  marketData: MarketData;
  carrierGroups: CarrierListItem[];
}

export const defaultControl = (form: UseFormReturn<FormWithRules>, rowName: string, column: Column) => (
  <input
    type="text"
    placeholder={column.placeholder || 'Enter codes comma-sparated'}
    {...form.register(`${rowName}${column.name}`, column.rules)}
  />
);

const toFromOptions = [
  { text: 'To/From', value: 'false' },
  { text: 'To', value: 'true' },
];

export const SelectAll = 'Select All';

const filterForm = (
  form: UseFormReturn<FormWithRules>,
  rowName: string,
  columnName: string,
  options?: string[]
) => {
  if (options?.length) {
    const currentFormValues = form.getValues(`${rowName}${columnName}`) as string[];
    if (currentFormValues?.length) {
      const filteredFormValues = currentFormValues.filter((value) => options?.includes(value)) as any;
      if (!arraysEqual(filteredFormValues, currentFormValues)) {
        form.setValue(`${rowName}${columnName}`, filteredFormValues);
      }
    }
  }
};

const getParentLocations = (toOnly?: string, initialParent?: string[], possibleParent?: string[]) => {
  let parentLocations = initialParent || [];
  if (toOnly !== 'true') {
    parentLocations = parentLocations.concat(possibleParent || []);
  }
  return parentLocations;
};

const filterFormMarketData = (
  form: UseFormReturn<FormWithRules>,
  rowName: string,
  columnName: string,
  parentOptions?: string[],
  marketData?: MarketData
) => {
  if (!parentOptions?.length || !marketData) {
    return;
  }

  const currentFormValues = form.getValues(`${rowName}${columnName}`) as string[];
  if (!currentFormValues?.length) {
    return;
  }

  const parsedOptions = parseMarketDataOptions(parentOptions);
  const parsedValues = parseMarketDataOptions(currentFormValues);
  const valueType = parsedValues[0].type;

  const filteredMarketData = filteredMarketDataSelector(
    parsedOptions
  )({ marketData: { data: marketData } as MarketDataState } as unknown as State);

  const filteredFormValues = parsedValues.filter((v) =>
    filteredMarketData?.destinations?.[v.type]?.find(
      (d) => d.code === v.value || (v.type === OptionCategory.Country && d.name === v.value)
    )
  );

  if (parsedValues.find((v) => !filteredFormValues.find((f) => f.value === v.value))) {
    form.setValue(
      `${rowName}${columnName}`,
      filteredFormValues.map((f) => formatMarketDataOption(valueType, f.value)) as any
    );
  }
};

export const sameLineFromToAreaOptions = [OptionCategory.MetroArea, OptionCategory.Airport];

export const getCarriesList = (
  data: { ValidatingCarriers: string[]; MarketingCarriers: string[]; OperatingCarriers: string[] }[],
  field: 'ValidatingCarriers' | 'MarketingCarriers' | 'OperatingCarriers',
  carrierGroups?: CarrierListItem[]
) =>
  data?.reduce((designators: string[], r) => {
    r?.[field]?.forEach((d) => {
      if (!designators.includes(d)) {
        designators.push(d);
        const carrier = carrierGroups?.find((g) => g.code === d);
        if (carrier?.carriers) {
          designators.push(...carrier.carriers.map((r) => r.code));
        }
      }
    });
    return designators;
  }, []);

export const allColumns = {
  Markets: {
    name: 'Markets',
    displayName: 'Markets',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column, data?: any) => {
      const marketsList = data?.Markets as { Name: string }[];
      const markets = marketsList?.reduce((markets: string[], m) => {
        if (!markets.includes(m.Name)) {
          markets.push(m.Name);
        }
        return markets;
      }, []);

      filterForm(form, rowName, column.name, markets);

      const marketOptions = markets?.map((m) => ({ text: m, value: m }));

      return (
        <Controller
          control={form.control}
          name={`${rowName}${column.name}`}
          rules={{ required: 'This field should not be empty' }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Dropdown
              placeholder="Select Markets"
              options={marketOptions}
              noResultsMessage="No markets defined"
              onChange={(e, data) => onChange(data.value)}
              onBlur={onBlur}
              value={value as any as string[]}
              multiple
            />
          )}
        />
      );
    },
  } as Column,
  ValidatingCarriers: {
    name: 'ValidatingCarriers',
    displayName: 'Validating carriers',
    control: (
      form: UseFormReturn<FormWithRules>,
      rowName: string,
      column: Column,
      data?: any,
      columnData?: ColumnData
    ) => {
      const validatingCarriers = getCarriesList(data?.Rules, 'ValidatingCarriers', columnData?.carrierGroups);

      filterForm(form, rowName, column.name, validatingCarriers);

      return (
        <Controller
          control={form.control}
          name={`${rowName}${column.name}`}
          render={({ field: { onChange, onBlur, value } }) => (
            <CarriesDropdown
              placeholder="Select Validating carriers"
              onChange={(value) => onChange(value)}
              onBlur={onBlur}
              optionValue={validatingCarriers}
              value={value as any as string[]}
            />
          )}
        />
      );
    },
  } as Column,
  MarketingCarriers: {
    name: 'MarketingCarriers',
    displayName: 'Marketing carriers',
    control: (
      form: UseFormReturn<FormWithRules>,
      rowName: string,
      column: Column,
      data?: any,
      columnData?: ColumnData
    ) => {
      const marketingCarriers = getCarriesList(data?.Rules, 'MarketingCarriers', columnData?.carrierGroups);

      filterForm(form, rowName, column.name, marketingCarriers);

      return (
        <Controller
          control={form.control}
          name={`${rowName}${column.name}`}
          render={({ field: { onChange, onBlur, value } }) => (
            <CarriesDropdown
              placeholder="Select Marketing carriers"
              onChange={(value) => onChange(value)}
              onBlur={onBlur}
              optionValue={marketingCarriers}
              value={value as any as string[]}
            />
          )}
        />
      );
    },
  } as Column,
  OperatingCarriers: {
    name: 'OperatingCarriers',
    displayName: 'Operating carriers',
    control: (
      form: UseFormReturn<FormWithRules>,
      rowName: string,
      column: Column,
      data?: any,
      columnData?: ColumnData
    ) => {
      const operatingCarriers = getCarriesList(data?.Rules, 'OperatingCarriers', columnData?.carrierGroups);

      filterForm(form, rowName, column.name, operatingCarriers);

      return (
        <Controller
          control={form.control}
          name={`${rowName}${column.name}`}
          render={({ field: { onChange, onBlur, value } }) => (
            <CarriesDropdown
              placeholder="Select Operating carriers"
              onChange={(value) => onChange(value)}
              onBlur={onBlur}
              optionValue={operatingCarriers}
              value={value as any as string[]}
            />
          )}
        />
      );
    },
  } as Column,
  From: {
    name: 'From',
    displayName: 'From',
    control: (
      form: UseFormReturn<FormWithRules>,
      rowName: string,
      column: Column,
      data?: any,
      columnData?: ColumnData
    ) => {
      const isExcluding = rowName.indexOf('Excluding') >= 0;
      const parentLocations = isExcluding ? getParentLocations(data.ToOnly, data.From, data.To) : [];

      if (isExcluding) {
        filterFormMarketData(form, rowName, column.name, parentLocations, columnData?.marketData);
      }

      return (
        <LocationsSelector
          form={form}
          controllerName={`${rowName}${column.name}`}
          rules={column.rules}
          isSelection={isExcluding}
          parentLocations={(isExcluding && parentLocations) || undefined}
          sameLineAreaOptions={sameLineFromToAreaOptions}
        />
      );
    },
  } as Column,
  To: {
    name: 'To',
    displayName: 'To',
    control: (
      form: UseFormReturn<FormWithRules>,
      rowName: string,
      column: Column,
      data?: any,
      columnData?: ColumnData
    ) => {
      const isExcluding = rowName.indexOf('Excluding') >= 0;
      const parentLocations = isExcluding ? getParentLocations(data.ToOnly, data.To, data.From) : [];

      if (isExcluding) {
        filterFormMarketData(form, rowName, column.name, parentLocations, columnData?.marketData);
      }

      return (
        <LocationsSelector
          form={form}
          controllerName={`${rowName}${column.name}`}
          isSelection={isExcluding}
          rules={column.rules}
          parentLocations={(isExcluding && parentLocations) || undefined}
          sameLineAreaOptions={sameLineFromToAreaOptions}
        />
      );
    },
  } as Column,
  ToOnly: {
    name: 'ToOnly',
    displayName: 'Direction',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column) => (
      <Controller
        control={form.control}
        name={`${rowName}${column.name}`}
        render={({ field: { onChange, onBlur, value } }) => (
          <Dropdown
            defaultValue="false"
            options={toFromOptions}
            onChange={(e, data) => onChange(data.value)}
            onBlur={onBlur}
            value={(value as any as string) || undefined}
          />
        )}
      />
    ),
  } as Column,
  ClassesOfService: {
    name: 'ClassesOfService',
    displayName: 'Booking Class',
    rules: {
      pattern: {
        value: /^[A-Z ,]+$/,
        message: 'Invalid format',
      },
    },
    placeholder: 'Enter Booking Class',
    tooltip: {
      icon: 'question circle outline' as SemanticICONS,
      position: 'left center',
      content: 'List of class of services, separated by a comma (example: J, C, D)',
    } as Tooltip,
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column, data?: any) => {
      const rules = column.rules?.generateValidate
        ? column.rules?.generateValidate(form, rowName)
        : column.rules;
      return (
        <input
          type="text"
          placeholder={column.placeholder}
          {...form.register(`${rowName}${column.name}`, rules)}
        />
      );
    },
  } as Column,
  FareBasis: {
    name: 'FareBasis',
    displayName: 'Fare Basis Code',
    rules: {
      pattern: {
        value: /^[A-Z0-9\-\_ ,]+$/,
        message: 'The Fare Basis must contain only uppercase letters, numbers, dash and underscores',
      },
    },
    tooltip: {
      icon: 'question circle outline' as SemanticICONS,
      position: 'top right',
      offset: ({ placement }: any) => [(placement === 'top-end' || placement === 'bottom-end') ? 10 : -10, 0],
      table: (
        <Table className="ui very basic table tooltip-table">
          <Table.Row>
            <Table.Cell className="tooltip-header" width={3}>
              Code format
            </Table.Cell>
            <Table.Cell className="tooltip-header" width={5}>
              Example input
            </Table.Cell>
            <Table.Cell className="tooltip-header" width={8}>
              Description
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Equal to</Table.Cell>
            <Table.Cell>VAAOAKED, CAAOAKED</Table.Cell>
            <Table.Cell>List the full code names, separeted by a comma</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Starts with</Table.Cell>
            <Table.Cell>J-, C-, B-, Y-</Table.Cell>
            <Table.Cell>List the letters with -, separated by a comma</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Includes</Table.Cell>
            <Table.Cell>_AA_ _ _ _ _, _AD_ _ _ _ _</Table.Cell>
            <Table.Cell>
              List the codes using _ to indicate the number of symbols
              in the code and the space between them, separated by a comma
            </Table.Cell>
          </Table.Row>
        </Table>
      ),
    } as Tooltip,
    placeholder: 'Enter Fare Basis Code',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column, data?: any) => {
      const rules = column.rules?.generateValidate
        ? column.rules?.generateValidate(form, rowName)
        : column.rules;
      return (
        <input
          className="fare-basis"
          type="text"
          placeholder={column.placeholder}
          {...form.register(`${rowName}${column.name}`, rules)}
        />
      );
    },
  } as Column,
  POO: {
    name: 'POO',
    displayName: 'POO',
    control: (
      form: UseFormReturn<FormWithRules>,
      rowName: string,
      column: Column,
      data?: any,
      columnData?: ColumnData
    ) => {
      const isExcluding = rowName.indexOf('Excluding') >= 0;
      const poo = (isExcluding ? data.POO : data.PooCodes) as string[];

      filterFormMarketData(form, rowName, column.name, poo, columnData?.marketData);

      return (
        <LocationsSelector
          form={form}
          controllerName={`${rowName}${column.name}`}
          isSelection={isExcluding}
          rules={column.rules}
          parentLocations={poo}
          placeholder="Select country or area"
          minAreaLevel={OptionCategory.Country}
        />
      );
    },
  } as Column,
  POS: {
    name: 'POS',
    displayName: 'POS',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column, data?: any) => {
      if (!data?.PosCountryCodes || data.PosCountryCodes?.length === 0) {
        return defaultControl(form, rowName, column);
      }

      const pos = data.PosCountryCodes as string[];
      const options = pos.map((c) => ({ text: c, value: c }));

      filterForm(form, rowName, column.name, pos);

      return (
        <Controller
          control={form.control}
          name={`${rowName}${column.name}`}
          render={({ field: { onChange, onBlur, value } }) => (
            <Dropdown
              defaultValue="false"
              multiple={true}
              placeholder="Select POS"
              options={options}
              onChange={(e, data) => onChange(data.value)}
              onBlur={onBlur}
              value={(value as any as string) || undefined}
            />
          )}
        />
      );
    },
  } as Column,
  TicketDesignators: {
    name: 'TicketDesignators',
    displayName: 'Ticket Designator',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column, data?: any) => {
      const rules = data?.Rules as { TicketDesignators?: string[] }[];
      const ticketDesignators = rules?.reduce((designators: string[], r) => {
        r?.TicketDesignators?.forEach((d) => {
          if (!designators.includes(d)) {
            designators.push(d);
          }
        });
        return designators;
      }, []);

      if (ticketDesignators?.length === 0) {
        return defaultControl(form, rowName, column);
      }

      const ticketDesignatorOptions = ticketDesignators?.map((d) => ({ text: d, value: d }));

      filterForm(form, rowName, column.name, ticketDesignators);

      return (
        <Controller
          control={form.control}
          name={`${rowName}${column.name}`}
          render={({ field: { onChange, onBlur, value } }) => (
            <Dropdown
              defaultValue="false"
              multiple={true}
              placeholder="Select Ticket Designators"
              options={ticketDesignatorOptions}
              onChange={(e, data) => onChange(data.value)}
              onBlur={onBlur}
              value={(value as any as string) || undefined}
            />
          )}
        />
      );
    },
  } as Column,
  TourCodes: {
    name: 'TourCodes',
    displayName: 'Tour codes',
  } as Column,
  TicketedDates: {
    name: 'TicketedDates',
    displayName: 'Last Ticket Date',
    minWidth: MinWidth.Average,
    className: 'date-input',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column, data?: any) => (
      <Controller
        control={form.control}
        name={`${rowName}${column.name}`}
        render={({ field: { onChange, onBlur, value } }) => (
          <SingleDateSelector
            onChange={onChange}
            onBlur={onBlur}
            value={value as unknown as Date}
            maxDate={data?.LastTicketDate}
          />
        )}
      />
    ),
  } as Column,
  TravelCommencementDates: {
    name: 'TravelCommencementDates',
    displayName: 'Travel Commencement Date',
    minWidth: MinWidth.Average,
    className: 'date-input',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column, data?: any) => (
      <Controller
        control={form.control}
        name={`${rowName}${column.name}`}
        render={({ field: { onChange, onBlur, value } }) => (
          <SingleDateSelector
            onChange={onChange}
            onBlur={onBlur}
            value={value as unknown as Date}
            maxDate={data?.TravelCommencingDate}
          />
        )}
      />
    ),
  } as Column,
  TravelCompleteDates: {
    name: 'TravelCompleteDates',
    displayName: 'Travel complete dates',
  } as Column,
  BlackoutDates: {
    name: 'BlackoutDates',
    displayName: 'Black-out dates',
  } as Column,
  Excluding: {
    name: 'Excluding',
    additionalFields: ['ExcludingShown'],
    displayName: 'Excluding',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column) => (
      <Controller
        control={form.control}
        name={`${rowName}${column.additionalFields?.[0]}`}
        render={({ field: { onChange, value } }) => {
          const excludingValue = form.getValues(`${rowName}${column.name}`);
          const formattedValue = excludingValue && rulesFieldsArrayFormatter(excludingValue);
          return (
            <span className="pointer" onClick={() => onChange(!value)}>
              {(formattedValue?.props?.children?.length && formattedValue) || 'Add Exclusion(s)'}{' '}
              <Icon link name={value ? 'angle up' : 'angle down'} />
            </span>
          );
        }}
      />
    ),
  } as Column,
  GDS: {
    name: 'GDS',
    displayName: 'GDS',
    control: (form: UseFormReturn<FormWithRules>, rowName: string, column: Column) => {
      const gds = Object.keys(GdsOptions).filter((x) => isNaN(parseInt(x)));
      const options = gds.map((g) => ({ text: g, value: g }));

      filterForm(form, rowName, column.name, gds);

      return (
        <Controller
          control={form.control}
          name={`${rowName}${column.name}`}
          render={({ field: { onChange, onBlur, value } }) => (
            <Dropdown
              defaultValue="false"
              multiple={true}
              placeholder="Select GDS"
              options={options}
              onChange={(e, data) => onChange(data.value)}
              onBlur={onBlur}
              value={(value as any as string) || undefined}
            />
          )}
        />
      );
    },
  } as Column,
};
