import { RulesCombination } from '../../api/types';
import { RuleName } from './RuleName';

export interface IRulesAssociation {
  Definitions?: RulesCombination[];
  Exceptions?: RulesCombination[];
}

export const getRuleValues = (rules: IRulesAssociation, ruleName: RuleName): (string | null)[][] => {
  return rules.Definitions?.map((d) => d.Rules?.find((r) => r?.RuleName == ruleName)?.Values).filter(
    (v) => v
  ) as Array<string | null>[];
};

export const getUniqueRuleValues = (rules: IRulesAssociation, ruleName: RuleName): string[] => {
  const ruleValues = getRuleValues(rules, ruleName);
  const flatValues = new Array<string | null>().concat.apply([], ruleValues);
  return flatValues.filter((v, i, self) => v && self.indexOf(v) == i) as string[];
};
