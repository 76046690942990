import { createReducer } from '@reduxjs/toolkit';
import { LoadStateType } from '../commonTypes';
import { ModalFilesState } from './modalFilesTypes';
import * as actions from './modalFilesActions';
import { findFileOnPage } from '../helpers';

export const initialState: ModalFilesState = {
  files: {},
  lastActionFile: {
    loadState: {
      type: LoadStateType.NotLoaded,
    },
  },
  lastAttachedFileState: {
    type: LoadStateType.NotLoaded,
    errorMessage: null,
  },
  countFiles: 0,
};
const modalFilesReducer = createReducer<ModalFilesState>(
  initialState,
  (builder) => {
    builder.addCase(actions.loading, (state, action) => {
      state.files[action.payload] = {
        loadState: {
          type: LoadStateType.Loading,
        },
      };
    });
    builder.addCase(actions.load, (state, action) => {
      state.files[action.payload.pageNumber].loadState.type = LoadStateType.Loaded;
      state.files[action.payload.pageNumber].data = action.payload.repositoryFiles;
    });
    builder.addCase(actions.loadNumberFiles, (state, action) => {
      state.countFiles = action.payload;
    });
    builder.addCase(actions.deleteModalFileByPage, (state, action) => {
      for (const pageNumber of Object.keys(state.files)) {
        if (Number(pageNumber) >= action.payload) {
          state.files[pageNumber].loadState.type = LoadStateType.NotLoaded;
          state.files[pageNumber].data = [];
        }
      }
      state.countFiles -= 1;
    });
    builder.addCase(actions.addModalFile, (state, action) => {
      if (state.countFiles == 0) {
        state.files[1] = {
          loadState: {
            type: LoadStateType.Loaded,
          },
          data: [action.payload.repositoryFile],
        };
      } else {
        let lastPage = 1;
        for (const pageNumber of Object.keys(state.files)) {
          if (Number(pageNumber) > lastPage) {
            lastPage = Number(pageNumber);
          }
        }
        if ((state?.files[lastPage]?.data?.length as number) < action.payload.pageSize) {
          state.files[lastPage].data?.push(action.payload.repositoryFile);
        } else {
          state.files[lastPage + 1] = {
            loadState: {
              type: LoadStateType.Loaded,
            },
            data: [action.payload.repositoryFile],
          };
        }
      }

      state.countFiles += 1;
    });
    builder.addCase(actions.deleteModalFile, (state, action) => {
      const fileOnPage = findFileOnPage(state, action.payload);
      if (fileOnPage !== undefined && fileOnPage.page > 0) {
        for (const pageNumber of Object.keys(state.files)) {
          if (Number(pageNumber) >= fileOnPage.page) {
            state.files[pageNumber].loadState.type = LoadStateType.NotLoaded;
            state.files[pageNumber].data = [];
          }
        }
        state.countFiles -= 1;
      }
    });
    builder.addCase(actions.loadingError, (state, action) => {
      state.files[action.payload.pageNumber] = {
        loadState: {
          type: LoadStateType.Error,
          errorMessage: action.payload.errorMessage,
        },
      };
    });
  }
);

export default modalFilesReducer;
