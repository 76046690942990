import React, { useMemo } from 'react';
import { Table } from 'semantic-ui-react';
import { Column, allColumns } from './columns';
import './RulesViewTable.css';

export interface RulesViewTableProps {
  columns: Column[];
  data?: any;
  dataFieldName: string;
}

export const mapColumnToClassName = {
  [allColumns.FareBasis.name]: 'fare-basis',
};

const RulesViewTable: React.FunctionComponent<RulesViewTableProps> = ({ columns, data, dataFieldName }) => {
  const dataArray = useMemo(() => data?.[dataFieldName] as any[], [data?.[dataFieldName]]);
  const visibleColumns = useMemo(
    () =>
      columns.filter(
        (c) =>
          c.alwaysVisible ??
          dataArray?.find((d) => typeof d[c.name] !== 'undefined' && d[c.name].toString().trim() !== '')
      ),
    [columns, dataArray]
  );

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          {visibleColumns.map((c) => (
            <Table.HeaderCell key={c.name}>{c.displayName}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dataArray?.map((d: any) => (
          <Table.Row>
            {visibleColumns.map((c) => (
              <Table.Cell className={mapColumnToClassName[c.name]}>
                {c.formatter
                  ? c.formatter(d[c.name])
                  : Array.isArray(d[c.name])
                  ? d[c.name].join(', ')
                  : d[c.name]}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default RulesViewTable;
