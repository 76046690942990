import { createReducer } from '@reduxjs/toolkit';
import { ChangelogChangeItem, Contract } from '../../api/types';
import { LoadStateType, PaginatedDataState } from '../commonTypes';
import * as actions from './contractActions';
import { contractChangelogsKeySelector } from './contractSelectors';
import { ContractChangelogPaginatedParams, ContractData, ContractState } from './contractTypes';

export const newContractId = 'newContract';
export const initialState: ContractState = {};
const checkChangelogsStateKey = (
  state: PaginatedDataState<ChangelogChangeItem>,
  params: ContractChangelogPaginatedParams
) => {
  const key = contractChangelogsKeySelector({ }, params);
  if (state.key !== key) {
    state.key = key;
    state.pages = [];
    state.totalCount = 0;
  }
};

export const contractReducer = createReducer<ContractState>(initialState, (builder) => {
  builder.addCase(actions.loading, (state, action) => {
    state[action.payload] = {
      loadState: {
        type: LoadStateType.Loading,
      },
    };
  });
  builder.addCase(actions.load, (state, action) => {
    state[action.payload.Id].loadState = {
      type: LoadStateType.Loaded,
    };
    state[action.payload.Id].data = action.payload;
  });
  builder.addCase(actions.loadingError, (state, action) => {
    state[action.payload.Id] = {
      loadState: {
        type: LoadStateType.Error,
        errorMessage: action.payload.errorMessage,
      },
    };
  });
  builder.addCase(actions.creating, (state) => {
    state[newContractId] = {
      loadState: {
        type: LoadStateType.Loading,
      },
    };
  });
  builder.addCase(actions.create, (state, action) => {
    state[newContractId] = {
      loadState: {
        type: LoadStateType.NotLoaded,
      },
    };
    state[action.payload.Id] = {
      loadState: {
        type: LoadStateType.Loaded,
      },
      data: action.payload,
    };
  });
  builder.addCase(actions.removeProgram, (state, action) => {
    if (state[action.payload.contractId].data) {
      const contract = state[action.payload.contractId].data as Contract;
      if (contract.Programs) {
        contract.Programs = contract.Programs.filter((p) => p.Id !== action.payload.programId);
      }
    }
  });
  builder.addCase(actions.creatingError, (state, action) => {
    state[newContractId] = {
      loadState: {
        type: LoadStateType.Error,
        errorMessage: action.payload,
      },
    };
  });
  builder.addCase(actions.editing, (state, action) => {
    Object.keys(state).forEach((id) => {
      state[id] = {
        loadState: {
          type: LoadStateType.NotLoaded,
        },
      };
    });
    state[action.payload.Id] = {
      loadState: {
        type: LoadStateType.Editing,
      },
    };
  });
  builder.addCase(actions.edit, (state, action) => {
    state[action.payload.Id] = {
      loadState: {
        type: LoadStateType.Loaded,
      },
      data: action.payload,
    };
  });
  builder.addCase(actions.editingError, (state, action) => {
    state[action.payload.contract.Id] = {
      loadState: {
        type: LoadStateType.Error,
        errorMessage: action.payload.errorMessage,
      },
    };
  });
  builder.addCase(actions.addProgram, (state, action) => {
    if (state[action.payload.ContractId].data) {
      const contact = state[action.payload.ContractId].data as Contract;
      if (contact.Programs) {
        contact.Programs?.push(action.payload);
      } else {
        contact.Programs = [action.payload];
      }
    }
  });
  builder.addCase(actions.updateProgram, (state, action) => {
    if (state[action.payload.ContractId].data) {
      const contract = state[action.payload.ContractId].data as Contract;
      if (contract.Programs) {
        const index = contract.Programs.findIndex((program) => program.Id == action.payload.Id);
        contract.Programs[index] = action.payload;
      }
    }
  });
  builder.addCase(actions.loadingChangelogs, (state, action) => {
    if (!state[action.payload.id]) {
      state[action.payload.id] = {
        loadState: {
          type: LoadStateType.NotLoaded,
        },
      };
    }

    const contract = state[action.payload.id];
    if (!contract.data) {
      contract.data = { Id: action.payload.id } as ContractData;
    }

    if (!contract.data.changelogs) {
      contract.data.changelogs = {
        key: '',
        pages: [],
        totalCount: 0,
      };
    }

    checkChangelogsStateKey(contract.data.changelogs, action.payload);

    contract.data.changelogs.pages[action.payload.PageNumber || 0] = {
      loadState: {
        type: LoadStateType.Loading,
      },
    };
  });
  builder.addCase(actions.loadChangelogs, (state, action) => {
    const contractData = state[action.payload.params.id].data as ContractData;
    checkChangelogsStateKey(
      contractData.changelogs as PaginatedDataState<ChangelogChangeItem>,
      action.payload.params
    );
    const changelogs = contractData.changelogs as PaginatedDataState<ChangelogChangeItem>;
    changelogs.pages[action.payload.params.PageNumber || 0] = {
      loadState: {
        type: LoadStateType.Loaded,
      },
      data: action.payload.data.Data,
    };
    changelogs.totalCount = action.payload.data.TotalCount;
  });
  builder.addCase(actions.loadingChangelogsError, (state, action) => {
    const contractData = state[action.payload.params.id].data as ContractData;
    checkChangelogsStateKey(
      contractData.changelogs as PaginatedDataState<ChangelogChangeItem>,
      action.payload.params
    );
    const changelogs = contractData.changelogs as PaginatedDataState<ChangelogChangeItem>;
    changelogs.pages[action.payload.params.PageNumber || 0].loadState = {
      type: LoadStateType.Error,
      errorMessage: action.payload.errorMessage,
    };
  });
  builder.addCase(actions.attachFile, (state, action) => {
    const contractData = state[action.payload.contractId]?.data as ContractData;
    if (contractData) {
      contractData.FileName = action.payload.fileName;
    }
  });
  builder.addCase(actions.detachFileFromContract, (state, action) => {
    const contract = state[action.payload]?.data as ContractData;
    if (contract) {
      contract.FileName = null;
    }
  });

  builder.addCase(actions.refreshContractsWithProgramIds, (state, action) => {
    const contractsIdsToRefresh = Object.keys(state).filter(c => state[c]?.data?.Programs?.find(p => action.payload.includes(p.Id)));
    contractsIdsToRefresh.forEach(c => {
      delete state[c];
    })
  });
});
