import { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import './SortController.css';

interface SortControllerProps {
  fieldName: string;
  activeFieldName: string;
  activeOrder: OrderBy;
  onClick: (order: OrderBy, fieldName: string) => void;
  hideInactive?: boolean;
}

export enum OrderBy {
  Ascending,
  Descending,
  Inactive,
}

export const mapOrderByToRequestType = {
  [OrderBy.Ascending]: true,
  [OrderBy.Descending]: false,
  [OrderBy.Inactive]: null,
};

export const mapRequestTypeToOrderBy = (requestType?: boolean) => requestType === true ? OrderBy.Ascending : requestType === false ? OrderBy.Descending : OrderBy.Inactive;

const SortController: React.FunctionComponent<SortControllerProps> = ({
  fieldName,
  activeFieldName,
  activeOrder,
  onClick,
  hideInactive,
}) => {
  const [arrowsState, setArrowsState] = useState<OrderBy>(OrderBy.Inactive);

  useEffect(() => {
    if (activeFieldName !== fieldName) {
      setArrowsState(OrderBy.Inactive);
    } else {
      setArrowsState(activeOrder);
    }
  }, [activeFieldName, activeOrder]);

  const getOnArrowClick = (fieldName: string) => {
    switch (arrowsState) {
      case OrderBy.Inactive:
        return () => {
          setArrowsState(OrderBy.Ascending);
          onClick(OrderBy.Ascending, fieldName);
        };
      case OrderBy.Descending:
        return () => {
          setArrowsState(hideInactive ? OrderBy.Ascending : OrderBy.Inactive);
          onClick(hideInactive ? OrderBy.Ascending : OrderBy.Inactive, hideInactive ? fieldName : '');
        };
      case OrderBy.Ascending:
        return () => {
          setArrowsState(OrderBy.Descending);
          onClick(OrderBy.Descending, fieldName);
        };
    }
  };

  const renderArrows = () => {
    switch (arrowsState) {
      case OrderBy.Inactive:
        return (
          <>
            <Icon name="angle up" color="grey" link onClick={getOnArrowClick(fieldName)} />
            <Icon name="angle down" color="grey" link onClick={getOnArrowClick(fieldName)} />
          </>
        );
      case OrderBy.Descending:
        return <Icon name="angle down" color="blue" link onClick={getOnArrowClick(fieldName)} />;
      case OrderBy.Ascending:
        return <Icon name="angle up" color="blue" link onClick={getOnArrowClick(fieldName)} />;
    }
  };

  const arrows = renderArrows();

  return <div className="arrows-container">{arrows}</div>;
};

export default SortController;
