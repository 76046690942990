import { createReducer } from '@reduxjs/toolkit';
import { buttonLoading, hide, show } from './confirmationActions';
import { ConfirmationState } from './confirmationTypes';

export const initialState: ConfirmationState = {};

const confirmationReducer = createReducer<ConfirmationState>(initialState, (builder) => {
  builder.addCase(show, (_, action) => {
    action.payload.displayed = true;
    return action.payload;
  });
  builder.addCase(hide, (_) => ({ displayed: false }));
  builder.addCase(buttonLoading, (state, action) => {
    const button = state.buttons?.find((b) => b.displayName == action.payload.displayName);
    if (button) {
      button.isLoading = action.payload.isLoading;
    }
  });
});

export default confirmationReducer;
