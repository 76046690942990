import { API } from 'aws-amplify';
import apiPaths, { toolsApiPaths } from '../services/apiPaths';
import { apiNames } from '../services/apiNames';

export type ApiPath = (keyof typeof apiPaths) | (keyof typeof toolsApiPaths);
export type ApiName = keyof typeof apiNames;

class AmplifyApi {
  async get<TResponse>(name: ApiName, path: ApiPath, init: { queryStringParameters?: unknown }) : Promise<TResponse> {
    return await API.get(name, path, init);
  }

  async put<TResponse>(name: ApiName, path: ApiPath, init: { body?: unknown, queryStringParameters?:unknown }) : Promise<TResponse> {
    return await API.put(name, path, init);
  }

  async post<TResponse>(name: ApiName, path: ApiPath, init: { body: unknown }) : Promise<TResponse> {
    return await API.post(name, path, init);
  }
  
  async delete<TResponse>(name: ApiName, path: ApiPath, init: { body?: unknown }) : Promise<TResponse> {
    return await API.del(name, path, init);
  }
}

export const amplifyApi = new AmplifyApi();