import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadStateType } from '../../../store/commonTypes';
import {
  lastFileLoadStateSelector,
  repositoryCountFilesStateSelector,
} from '../../../store/repository/repositorySelector';
import './AttachFileModal.scss';
import UploadFileModal from '../UploadFileModal/UploadFileModal';
import RepositoryGridModal from '../RepositoryGridModal/RepositoryGridModal';
import {
  FileToAttach,
  UploadedFile,
  UploadFile,
  isSelectUploadedFileGuard,
  isUploadFileGuard,
  AttachToContract,
} from '../types';
import { getFileUploadErrorMessage } from '../helper';
import ErrorMessage from '../../../components/ErrorMessage';

interface AttachFileModalProps {
  onClose: () => void;
  modal: {
    isOpen: boolean;
    onChange: (isOpen: boolean) => void;
  };
  contract: {
    value: AttachToContract | null;
  };
  fileToAttach: {
    value: FileToAttach;
    onChange: (file: FileToAttach) => void;
    onAttach: (file: FileToAttach) => Promise<void>;
  };
}

const AttachFileModal: React.FunctionComponent<AttachFileModalProps> = (props) => {
  const {
    onClose,
    modal,
    contract,
    fileToAttach: { value: fileToAttach, onChange: setFileToAttach, onAttach },
  } = props;
  const lastFileUploadState = useSelector(lastFileLoadStateSelector);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [uploadFileModalIsOpen, setUploadFileModalIsOpen] = useState(true);
  const [repositoryGridModalIsOpen, setRepositoryGridModalIsOpen] = useState(false);
  const repositoryCountFilesLoadState = useSelector(repositoryCountFilesStateSelector);

  if (repositoryCountFilesLoadState.type === LoadStateType.Error) {
    return <ErrorMessage message={repositoryCountFilesLoadState.errorMessage} />;
  }

  const handleUploadContract = async (fileToAttach: FileToAttach) => {
    if (!fileToAttach) {
      return;
    } else if (isUploadFileGuard(fileToAttach)) {
      setDisabledButton(true);
      const errorMessage = await getFileUploadErrorMessage(fileToAttach);
      setErrorMessage(errorMessage);
      if (errorMessage) {
        setDisabledButton(false);
        return;
      }
      await onAttach(fileToAttach);
      if (lastFileUploadState.type === LoadStateType.Error) {
        setErrorMessage(lastFileUploadState.errorMessage as string);
      } else {
        onClose();
      }
      setDisabledButton(false);
    } else if (isSelectUploadedFileGuard(fileToAttach)) {
      await onAttach(fileToAttach);
      onClose();
    }
  };

  const modalHeaderText = contract.value?.Name || '';

  return (
    <>
      <UploadFileModal
        modal={{
          isOpen: modal.isOpen && uploadFileModalIsOpen,
          onChange: setUploadFileModalIsOpen,
          headerText: modalHeaderText,
          onClose: onClose,
        }}
        file={{
          value: fileToAttach as UploadFile,
          onChange: setFileToAttach,
        }}
        selectFromRepositoryButton={{
          isVisible: true,
          onClick: () => {
            setUploadFileModalIsOpen(false);
            setRepositoryGridModalIsOpen(true);
          },
        }}
        error={{ value: errorMessage, onChange: setErrorMessage }}
        submitButton={{
          onClick: handleUploadContract,
          disabled: {
            value: disabledButton,
            onChange: setDisabledButton,
          },
        }}
        cancelButton={{
          onClick: () => {
            modal.onChange(false);
          },
        }}
      />
      <RepositoryGridModal
        modal={{
          isOpen: modal.isOpen && repositoryGridModalIsOpen,
          onChange: () => {
            setUploadFileModalIsOpen(true);
            setRepositoryGridModalIsOpen(false);
          },
          headerText: modalHeaderText,
        }}
        selectedFile={{ value: fileToAttach as UploadedFile, onChange: setFileToAttach }}
        submitButton={{ onClick: handleUploadContract, disabled: disabledButton }}
      />
    </>
  );
};

export default AttachFileModal;
