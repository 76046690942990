export type Configuration = {
  authRegion: string;
  authUserPoolId: string;
  authUserPoolWebClientId: string;
  identityPoolId: string;
  redirectUrl: string;
  cognitoEndpoint: string;
  appsync_Region: string;
  appsync_AuthenticationType: string;
  s3_bucket: string;
  s3_region: string;
  environment: Environment | null;
  destinationEnv: Environment | null;
  uiApiEndpoint: string;
  toolsApiEndpoint: string;
  localStorageCacheLifetimeMs: number 
};

export enum Environment {
  DEV = 'dev',
  TST = 'tst',
  UAT = 'uat',
  PRD = 'prod',
}

export const fetchConfiguration = async () => {
  const response = await fetch(`${window.location.origin}/config.json`);
  if (!response.ok) {
    throw new Error(`Fail to fetch file. Status: ${response.status}`);
  }
  const value = await response.json();

  return value as Configuration;
}