import { createSlice } from '@reduxjs/toolkit';
import { RelatedProgramsSearch, RelatedProgramsState, initialState } from './relatedProgramsTypes';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { relatedProgramsSelectors } from './relatedProgramsSelectors';
import { LoadStateType, ParamsLoadErrorPayload, ParamsResponsePayload } from '../commonTypes';
import { ProgramListItem } from '../programList/programListTypes';
import { deleteProgramById, edit, updateStatuses } from '../program/programActions';
import { AppDispatch } from '../useAppDispatch';
import { State } from '../State';
import { amplifyApi } from '../../api/AmplifyApi';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';

const relatedProgramSlice = createSlice({
  initialState: initialState,
  name: "relatedPrograms",
  reducers: {
    load: (state, {
      payload: {
        data,
        params
      }
    }: PayloadAction<ParamsResponsePayload<RelatedProgramsSearch, ProgramListItem>>) => {
      let key = relatedProgramsSelectors.keySelector({}, params);
      data.forEach(p => {
        const oldProgram = state.programs[p.Id];
        Object.keys(state.filters).forEach(f => {
          const oldProgramIndex = state.filters[f].data?.indexOf(oldProgram) as number;
          if (oldProgramIndex >= 0) {
            state.filters[f].data?.splice(oldProgramIndex, 1, p);
          }
        });
        state.programs[p.Id] = p;
      });
      state.filters[key] = { loadState: { type: LoadStateType.Loaded }, data: data };
    },
    loading: (state, { payload }: PayloadAction<RelatedProgramsSearch>) => {
      let key = relatedProgramsSelectors.keySelector({}, payload);
      state.filters[key] = { loadState: { type: LoadStateType.Loading } };
    },
    loadingError: (state, {
      payload: {
        params,
        errorMessage
      }
    }: PayloadAction<ParamsLoadErrorPayload<RelatedProgramsSearch>>) => {
      let key = relatedProgramsSelectors.keySelector({}, params);
      state.filters[key] = { loadState: { type: LoadStateType.Error, errorMessage: errorMessage } };
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(deleteProgramById, (state, { payload: id }) =>
        clearProgramById(state, id))
      .addCase(edit, (state, { payload: { Id: id } }) =>
        clearProgramById(state, id))
      .addCase(updateStatuses, (state, { payload: programStatusChangedUpdates }) => {
        programStatusChangedUpdates.forEach(program => clearProgramById(state, program.Id));
        const programListItems = Object.keys(state.filters)
          .flatMap(key => state.filters[key].data ?? []);
        programStatusChangedUpdates.forEach(item => {
          const programListItem = programListItems.find(x => x.Id == item.Id);
          if (programListItem) {
            programListItem.Status = item.Status;
          }
        });
      })
});

const clearProgramById = (state: RelatedProgramsState, id: number) => {
  if (!state.programs[id]) {
    return;
  }

  Object.keys(state.filters).forEach(f => {
    if (state.filters[f].data?.findIndex(p => p.Id === id)) {
      delete state.filters[f];
    }
  });
  delete state.programs[id];
}

export const fetchRelatedPrograms = (search: RelatedProgramsSearch) => {
  const hasOnlyIds = (search: RelatedProgramsSearch) => {
    return search.Ids?.length
      && !search.ValidatingCarrierCodes?.length
      && !search.ContractTypes?.length
      && !search.Statuses?.length
      && !search.RelatedProgramTypes?.length;
  }

  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const loadState = relatedProgramsSelectors.loadStateSelector(state, search);
    if (loadState.type === LoadStateType.Loaded || loadState.type === LoadStateType.Loading) {
      return;
    }

    if (hasOnlyIds(search)) { // search only by programIds
      const cachedPrograms = relatedProgramsSelectors.cachedProgramsSelector(state, search);
      if (cachedPrograms.length == search.Ids?.length) {
        dispatch(load({ params: search, data: cachedPrograms }));
        return;
      }
    }

    dispatch(loading(search));

    const toQueryStringParams = (search: RelatedProgramsSearch): Record<string, unknown> => {
      return Object.keys(search)
        .map(x => x as keyof RelatedProgramsSearch)
        .reduce<Record<string, unknown>>((params, propName) => {
          const value = search[propName];
          if (value === null || value === undefined) {
            return params;
          }
          params[propName] = value;

          return params;
        }, {});
    }

    try {
      const params = toQueryStringParams(search);
      const programList = await amplifyApi.get<ProgramListItem[]>(apiNames.AomUI, apiPaths.programs, { queryStringParameters: params });
      dispatch(load({ params: search, data: programList }));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(loadingError({ params: search, errorMessage: errorMessage }));
    }
  };
};


export const relatedProgramsReducer = relatedProgramSlice.reducer;
export const { load, loadingError, loading } = relatedProgramSlice.actions;