import { ChangelogChangeItem, Program, } from '../../api/types';
import { LoadStateType, PaginatedResult } from '../commonTypes';
import { State } from '../State';
import {
  programChangelogsPageLoadStateSelector,
  programLoadStateSelector,
  programsSelector,
} from './programSelectors';
import { addProgram, refreshContractsWithProgramIds, removeProgram, updateProgram } from '../contract/contractActions';
import { ProgramChangelogPaginatedParams, ProgramViewModel } from './programTypes';
import { mapFromViewModel } from './programMapping';
import { MarketData } from '../marketData/marketDataTypes';
import * as actions from './programActions';
import { carriersWithGroupsSelector } from '../carriers/carriersSelectors';
import { programListActions } from '../programList/programListSlice';
import { setNotLoadedState } from '../contractList/contractListActions';
import { AppDispatch } from '../useAppDispatch';
import apiNames from '../../services/apiNames';
import apiPaths, { pathById } from '../../services/apiPaths';
import { toProgramListItem } from '../helpers';
import { amplifyApi } from '../../api/AmplifyApi';

export const loadProgram = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const loadState = programLoadStateSelector(state, { id });
    if (loadState.type === LoadStateType.Loaded || loadState.type === LoadStateType.Loading) {
      return;
    }

    dispatch(actions.loading(id));
    try {
      const program = await amplifyApi.get<Program>(apiNames.AomUI, pathById(apiPaths.programs, id), {});
      dispatch(actions.load(program));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(actions.loadingError({ Id: id, errorMessage: errorMessage }));
    }
  };
};

export const createProgram = (programViewModel: ProgramViewModel, marketData?: MarketData) => {

  return async (dispatch: AppDispatch, getState: () => State) => {
    try {
      const carrierGroups = carriersWithGroupsSelector(getState());
      const program = mapFromViewModel(programViewModel, marketData, carrierGroups);
      dispatch(actions.creating());
      const newProgram = await amplifyApi.post<Program>(apiNames.AomUI, apiPaths.programs, { body: program });
      dispatch(clearCombinationPrograms(newProgram));
      clearRelatedPrograms(dispatch, newProgram);
      dispatch(actions.create(newProgram));
      dispatch(addProgram(newProgram));
      dispatch(programListActions.addProgram(toProgramListItem(newProgram)));
      return newProgram;
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(actions.creatingError(errorMessage));
      throw errorMessage;
    }
  };
};

export const deleteProgram = (program: Program) => {
  return async (dispatch: AppDispatch) => {
    const programId = program.Id;
    const contractId = program.ContractId;
    try {
      await amplifyApi.delete(apiNames.AomUI, pathById(apiPaths.programs, programId), { body: program });
      dispatch(clearCombinationPrograms(program));
      
      dispatch(actions.deleteProgramById(programId));
      dispatch(removeProgram({ programId, contractId }));
      dispatch(actions.clearProgramDataWithRelatedProgramId(programId));
      clearRelatedPrograms(dispatch, program);
      dispatch(programListActions.removeProgram(programId));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(actions.deletingError({ programId, errorMessage }));
      throw errorMessage;
    }
  };
};

export const editProgram = (
  programViewModel: ProgramViewModel,
  programStoreModel: Program,
  marketData?: MarketData
) => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    try {
      const carrierGroups = carriersWithGroupsSelector(getState());
      const program = mapFromViewModel(programViewModel, marketData, carrierGroups);
      const mergedProgram = { ...programStoreModel, ...program };
      dispatch(actions.editing(mergedProgram));
      const editedProgram = await amplifyApi.put<Program>(apiNames.AomUI, pathById(apiPaths.programs, program.Id), { body: mergedProgram });

      dispatch(clearCombinationPrograms(editedProgram));
      dispatch(clearCombinationPrograms(programStoreModel));
      clearRelatedPrograms(dispatch, editedProgram, programStoreModel);
      dispatch(actions.edit(editedProgram));
      dispatch(programListActions.edit(toProgramListItem(editedProgram)));
      dispatch(updateProgram(editedProgram));
      dispatch(setNotLoadedState());
      return editedProgram;
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(actions.editingError({ program: programViewModel, errorMessage }));
      throw errorMessage;
    }
  };
};

export const loadProgramChangelogs = (searchParams: ProgramChangelogPaginatedParams) => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    try {
      const loadState = programChangelogsPageLoadStateSelector(getState(), searchParams);
      if (loadState.type === LoadStateType.Loaded || loadState.type === LoadStateType.Loading) {
        return;
      }

      dispatch(actions.loadingChangelogs(searchParams));
      const search = { ...searchParams, EntityId: searchParams.id.toString(), id: undefined };
      
      const changelogs = await amplifyApi.get<PaginatedResult<ChangelogChangeItem>>(
        apiNames.AomUI,
        pathById(apiPaths.programs, searchParams.id, 'changelogs'),
        { queryStringParameters: search }
      );
      dispatch(
        actions.loadChangelogs({
          params: searchParams,
          data: changelogs,
        })
      );
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(actions.loadingChangelogsError({ params: searchParams, errorMessage }));
    }
  };
};

const clearCombinationPrograms = (program: Program) => {
  return (dispatch: AppDispatch, getState: () => State) => {
    const combinationLegsIds = program.CombinationRules?.LegProgramIds;

    if (!combinationLegsIds) {
      const allPrograms = programsSelector(getState());
      Object.values(allPrograms).forEach((p) => {
        if (p.data?.CombinationRules?.LegProgramIds?.includes(program.Id)) {
          dispatch(actions.clearProgramData(p.data.Id));
        }
      });
    }

    combinationLegsIds?.forEach((id) => dispatch(actions.clearProgramData(id)));
  };
};

const clearRelatedPrograms = (dispatch: AppDispatch, program: Program, oldProgram?: Program) => {
  const oldProgramIds = oldProgram?.RelatedPrograms?.ProgramIds ?? [];
  const newProgramIds = program.RelatedPrograms?.ProgramIds ?? [];
  const clearIds = newProgramIds.filter(id => !oldProgramIds.includes(id));
  clearIds.push(...oldProgramIds.filter(id => !newProgramIds.includes(id)));
  if (!clearIds.length) {
    return;
  }

  clearIds.forEach(id => dispatch(actions.clearProgramData(id)));
  dispatch(refreshContractsWithProgramIds(clearIds));
}
