import React, { useState } from 'react';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { LcnModel } from '../AccountTypes';

export interface AccountInfoProps {
  lcns: LcnModel[];
  isReadOnly: boolean;
}

const LcnDropdown: React.FunctionComponent<AccountInfoProps> = ({ lcns, isReadOnly }) => {
  const [selection, setSelection] = useState<string[]>(
    lcns.filter((v: any) => v.checked).map((v: any) => v.value) || []
  );

  if (lcns.length === 1) {
    return <>{lcns[0].value}</>;
  }

  if (isReadOnly) {
    return <>{lcns.map((v) => v.value).join(', ')}</>;
  }

  const toggleSelection = (label: string, checked: boolean) => {
    const checkedLcn = lcns.find((s) => s.value === label);
    if (checkedLcn) checkedLcn.checked = checked;

    if (checked) {
      setSelection([...selection, label]);
    } else {
      setSelection(selection.filter((el) => el !== label));
    }
  };

  return (
    <Dropdown
      item
      multiple
      className="lcn-dropdown"
      text={selection.length ? selection.join(', ') : 'Select Lcn(s)'}
      value={selection}
    >
      <Dropdown.Menu>
        {lcns.map((lcn) => (
          <Dropdown.Item key={lcn.id}>
            <Checkbox
              className="account-checkbox"
              label={lcn.value}
              checked={selection?.indexOf(lcn.value) > -1}
              onChange={(_e, data) => toggleSelection(data.label as string, data.checked as boolean)}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LcnDropdown;
