import { AuditInfo, ChangelogChangeItem, Program, RelatedPrograms } from '../../api/types';
import { RulesFields } from '../../services/rules';
import { LoadingDataState, PaginatedDataState, PaginatedSortedIdParams } from '../commonTypes';

export type ProgramData = Program & {
  changelogs?: PaginatedDataState<ChangelogChangeItem>;
};

export type ProgramState = {
  [key: number]: LoadingDataState<ProgramData>;
};

export interface ProgramGeneralInfoFields {
  Name: string;
  CreatedBy: AuditInfo | null;
  UpdatedBy: AuditInfo | null;
  LastTicketDate: Date;
  FirstTicketDate?: Date;
  TravelCommencingDate?: Date;
  TravelCommencingOnAfter?: string;
  TravelCompletedDate?: Date;
  BlackoutDates?: Date[][];
  PosCountryCodes?: string[];
  PooCodes?: string[];
  LegProgramIds?: string[];
  TicketProgramIds?: string[];
}

export type ProgramRules = {
  ValidatingCarriers?: string[];
  MarketingCarriers?: string[];
  OperatingCarriers?: string[];
  TourCodes?: string[];
  TicketDesignators?: string[];
  ExcludedFlightNumbers?: string[][];
};

export interface ProgramRulesFields {
  Rules: ProgramRules[];
}

export interface DiscountRules extends RulesFields {
  DiscountPercentage: number;
}

export interface ProgramDiscountsFields {
  Discounts?: DiscountRules[];
}

export interface CommissionRules extends RulesFields {
  CommissionPercentage: number;
}

export interface ProgramCommissionsFields {
  Commissions?: CommissionRules[];
}

export interface Market extends RulesFields {
  Name: string;
}

export interface ProgramMarketsFields {
  Markets: Market[];
}

export interface ProgramOverTheWaterField {
  OverTheWaterCarriers?: string[];
}

export interface ProgramRelatedProgramsField {
  RelatedPrograms?: RelatedPrograms;
}

export enum RelatedProgramsType {
  TrackingProgram = 0,
  CommissionOnCorporate = 1
}

export interface ProgramViewModel
  extends ProgramGeneralInfoFields,
    ProgramRulesFields,
    ProgramMarketsFields,
    ProgramDiscountsFields,
    ProgramCommissionsFields,
    ProgramOverTheWaterField,
    ProgramRelatedProgramsField {
  Id?: number;
  ContractId: number;
  Status: ProgramStatus;
}

export enum ProgramStatus {
  Draft = 0,
  Active = 1,
  Inactive = 2,
  Archived = 3
}

export enum CombinationRules {
  allPrograms = 'All Programs',
  onlyWithItself = 'Only with itself',
}

export interface ProgramStatusProps {
  edit?: boolean;
  update?: boolean;
}


export type ProgramStatusChanged = { Id: number; Status: ProgramStatus; Version: number | null; UpdatedBy: AuditInfo | null }

export type ProgramChangelogPaginatedParams = PaginatedSortedIdParams<number>;
