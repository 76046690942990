import moment from 'moment';
import { ReactElement } from 'react';
import { RulesFields } from '../../services/rules';
import settings from '../../settings';
import { extractValues } from '../LocationsSelector/LocationsSelector';

export type Column = {
  name: string;
  displayName: string;
  formatter?: (value: any) => string | ReactElement;
  alwaysVisible?: boolean;
};

export const rulesFieldsArrayFormatter = (value: RulesFields[]) => (
  <>
    {value &&
      value
        .map((v) => {
          let hasLineElements = false;
          return Object.keys(v)
            .map((f) => {
              const allColumnsObj = allColumns as any;
              const valueRow = v as any;
              const columnKey = Object.keys(allColumnsObj).find((c) => allColumnsObj[c].name === f);
              if (!columnKey) {
                return null;
              }
              let formattedValue: string | ReactElement;
              if (allColumnsObj[columnKey]?.formatter) {
                formattedValue = allColumnsObj[columnKey]?.formatter(valueRow[f]);
              } else if (Array.isArray(valueRow[f])) {
                formattedValue = valueRow[f]
                  .map((vs: any) => vs.toString().trim())
                  .filter((vs: string) => vs)
                  .join(', ');
              } else {
                formattedValue = valueRow[f].toString().trim();
              }
              if (formattedValue) {
                const result = (
                  <>
                    {hasLineElements && ' '}
                    <b>{allColumnsObj[columnKey].displayName}</b>: {formattedValue}
                  </>
                );
                hasLineElements = true;
                return result;
              }
              return formattedValue;
            })
            .filter((el) => el);
        })
        .filter((el) => el.length)
        .map((el, i) => (
          <>
            {(i && (
              <>
                {' '}
                <br /> <b>OR</b> <br />{' '}
              </>
            )) ||
              ''}
            {el}
          </>
        ))}
  </>
);

export const allColumns = {
  Markets: {
    name: 'Markets',
    displayName: 'Markets',
  },
  ValidatingCarriers: {
    name: 'ValidatingCarriers',
    displayName: 'Validating carriers',
  },
  MarketingCarriers: {
    name: 'MarketingCarriers',
    displayName: 'Marketing carriers',
  },
  OperatingCarriers: {
    name: 'OperatingCarriers',
    displayName: 'Operating carriers',
  },
  From: {
    name: 'From',
    displayName: 'From',
    formatter: (value: string[]) => extractValues(value)?.join(', '),
  },
  To: {
    name: 'To',
    displayName: 'To',
    formatter: (value: string[]) => extractValues(value)?.join(', '),
  },
  ToOnly: {
    name: 'ToOnly',
    displayName: 'Direction',
    formatter: (value: string) => (value === 'true' ? 'To' : 'To/From'),
    alwaysVisible: true,
  },
  ClassesOfService: {
    name: 'ClassesOfService',
    displayName: 'Booking Class',
  },
  FareBasis: {
    name: 'FareBasis',
    displayName: 'Fare Basis Code',
    formatter: (value: string) => value?.replace(/\s/g, '').split(',').join(', '),
  },
  POO: {
    name: 'POO',
    displayName: 'POO',
    formatter: (value: string[]) => extractValues(value)?.join(', '),
  },
  POS: {
    name: 'POS',
    displayName: 'POS',
  },
  TicketDesignators: {
    name: 'TicketDesignators',
    displayName: 'Ticket designators',
  },
  TourCodes: {
    name: 'TourCodes',
    displayName: 'Tour codes',
  },
  TicketedDates: {
    name: 'TicketedDates',
    displayName: 'Last Ticket Date',
    formatter: (value: Date) => (value && moment(value).format(settings.dateFormat)) || '',
  },
  TravelCommencementDates: {
    name: 'TravelCommencementDates',
    displayName: 'Travel Commencement Date',
    formatter: (value: Date) => (value && moment(value).format(settings.dateFormat)) || '',
  },
  TravelCompleteDates: {
    name: 'TravelCompleteDates',
    displayName: 'Travel complete dates',
  },
  BlackoutDates: {
    name: 'BlackoutDates',
    displayName: 'Black-out dates',
  },
  Excluding: {
    name: 'Excluding',
    displayName: 'Excluding',
    formatter: rulesFieldsArrayFormatter,
  },
  GDS: {
    name: 'GDS',
    displayName: 'GDS',
  },
};
