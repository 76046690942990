import moment from 'moment';
import { RuleName } from '../../../../../services/rules/RuleName';

export type Column = {
  displayName: string;
  rules: RuleName[];
  formatters?: {
    [r: string]: (value: Array<string | null>) => string;
  };
};

export const listFormatter = (values: Array<string | null>) => values.filter((v) => v).join(', ');

const dateRangeFormatter = (values: Array<string | null>) =>
  `${(values[0] && moment(values[0]).utc().format('MM/DD/YYYY')) || ''}${
    ((values[0] || values[1]) && ' - ') || ''
  }${(values[1] && moment(values[1]).utc().format('MM/DD/YYYY')) || ''}`;

export const flightNumberRangeFormatter = (values: Array<string | null>) => {
  if (values?.filter((x) => x)?.length > 1) {
    return `${values[0]}-${values[1]}`;
  }
  return `${values[0]}`;
};

export const allColumns = {
  Markets: {
    displayName: 'Markets',
    rules: [RuleName.marketIn],
  },
  ValidatingCarriers: {
    displayName: 'Validating carriers',
    rules: [RuleName.validatingCarrierCodeIn],
  },
  MarketingCarriers: {
    displayName: 'Marketing carriers',
    rules: [RuleName.marketingAirlineCodeIn],
  },
  OperatingCarriers: {
    displayName: 'Operating carriers',
    rules: [RuleName.operatingAirlineCodeIn],
  },
  From: {
    displayName: 'From',
    rules: [
      RuleName.departureAirportCodeIn,
      RuleName.departureCountryCodeIn,
      RuleName.departureMetroAreaCodeIn,
      RuleName.departureStateCodeIn,
      RuleName.departureZoneIn,
      RuleName.departureRegionIn,
      RuleName.departureTrafficConferenceIn,
    ],
  },
  To: {
    displayName: 'To',
    rules: [
      RuleName.arrivalAirportCodeIn,
      RuleName.arrivalCountryCodeIn,
      RuleName.arrivalMetroAreaCodeIn,
      RuleName.arrivalRegionIn,
      RuleName.arrivalStateCodeIn,
      RuleName.arrivalZoneIn,
      RuleName.arrivalRegionIn,
      RuleName.arrivalTrafficConferenceIn,
    ],
  },
  ClassesOfService: {
    displayName: 'Classes of service',
    rules: [RuleName.classOfServiceIn],
  },
  FareBasis: {
    displayName: 'Fare basis',
    rules: [RuleName.fareBasisIn],
  },
  POO: {
    displayName: 'POO',
    rules: [RuleName.pooCountryCodeIn],
  },
  POS: {
    displayName: 'POS',
    rules: [RuleName.posCountryCodeIn],
  },
  TicketDesignators: {
    displayName: 'Ticket designators',
    rules: [RuleName.ticketDesignatorIn],
  },
  TourCodes: {
    displayName: 'Tour codes',
    rules: [RuleName.tourCodeIn],
  },
  TicketedDates: {
    displayName: 'Ticketed dates',
    rules: [RuleName.ticketedDateBetween],
    formatters: {
      [RuleName.ticketedDateBetween]: dateRangeFormatter,
    },
  },
  TravelCommencementDates: {
    displayName: 'Travel commencement dates',
    rules: [RuleName.travelCommencementDateBetween],
    formatters: {
      [RuleName.travelCommencementDateBetween]: dateRangeFormatter,
    },
  },
  TravelCompleteDates: {
    displayName: 'Travel complete dates',
    rules: [RuleName.travelCompleteDateBetween],
    formatters: {
      [RuleName.travelCompleteDateBetween]: dateRangeFormatter,
    },
  },
  BlackoutDates: {
    displayName: 'Black-out dates',
    rules: [RuleName.blackOutDatesBetween],
    formatters: {
      [RuleName.blackOutDatesBetween]: dateRangeFormatter,
    },
  },
  ExcludedFlightNumbers: {
    displayName: 'Excluded flight numbers',
    rules: [RuleName.flightNumberNotIn],
    formatters: {
      [RuleName.flightNumberNotIn]: flightNumberRangeFormatter,
    },
  },
  GDS: {
    displayName: 'GDS',
    rules: [RuleName.gdsIn],
  },
};
