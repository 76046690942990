import { initialState as contractListState } from '../store/contractList/contractListReducer';
import { initialState as carriersState } from '../store/carriers/carriersReducer';
import { initialState as confirmationState } from '../store/confirmation/confirmationReducer';
import { initialState as contractState } from '../store/contract/contractReducer';
import { initialState as contractsChangelogsState } from '../store/contractsChangelogs/contractChangelogsSlice';
import { initialState as currentUserState } from '../store/currentUser/currentUserReducer';
import { initialState as marketDataState } from '../store/marketData/marketDataReducer';
import { initialState as modalFilesState } from '../store/modalFiles/modalFilesReducer';
import { initialState as programState } from '../store/program/programReducer';
import { initialState as programListState } from '../store/programList/programListSlice';
import { initialState as relatedProgramListState } from '../store/relatedPrograms/relatedProgramsTypes';
import { initialState as repositoryState } from '../store/repository/repositoryReducer';
import { State } from '../store/State';

export const initialAppState: State = {
  confirmation: confirmationState,
  contract: contractState,
  contractsChangelogs: contractsChangelogsState,
  marketData: marketDataState,
  modalFiles: modalFilesState,
  program: programState,
  programList: programListState,
  relatedProgramList: relatedProgramListState,
  repository: repositoryState,
  carriers: carriersState,
  contractList: contractListState,
  currentUser: currentUserState
};