import { downloadBlob } from '../../app/repository/helper';
import { Storage } from 'aws-amplify';
import { Icon } from 'semantic-ui-react';
import React, { useState } from 'react';
import './AttachedFileInfo.scss';

interface AttachedFileInfoProps {
  fileName: string;
  showIcon: boolean;
  className?: string;
}

const AttachedFileInfo: React.FunctionComponent<AttachedFileInfoProps> = ({
  fileName,
  showIcon,
  className,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleDownloadClick = async (fileName: string) => {
    try {
      setErrorMessage('');
      const response = (await Storage.get(fileName, { download: true })) as any;
      downloadBlob(response.Body, fileName);
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      setErrorMessage(errorMessage);
    }
  };

  return (
    <>
      <div className="attached-file">
        {showIcon && (
          <Icon name="download" color="blue" link onClick={async () => await handleDownloadClick(fileName)} />
        )}
        <div className={`file-name ${className}`} onClick={async () => handleDownloadClick(fileName)}>
          {fileName}
        </div>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </>
  );
};

export default AttachedFileInfo;
