import { LoadingDataState } from '../commonTypes';

export type MarketData = {
  destinations: DestinationSet;
};

export type MarketDataState = LoadingDataState<MarketData>;

export type Destination = {
  name: string;
  code: string;
  type: OptionCategory;
  innerDestinations?: DestinationSet;
  additionalInfo?: { [key: string]: string };
};

export type DestinationSet = { [key in OptionCategory]?: Array<Destination> };

export enum OptionCategory {
  TrafficConference,
  Region,
  Zone,
  Country,
  State,
  MetroArea,
  Airport,
}

export type SelectedOption = {
  type: OptionCategory;
  value: string;
};

export enum GdsOptions {
  Sabre,
  Apollo,
  Worldspan,
  Amadeus,
}
