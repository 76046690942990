import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Checkbox, Dropdown, DropdownOnSearchChangeData } from 'semantic-ui-react';

export type CheckboxDropdownOption = {
  
  readonly text: string;
  readonly value: string | number | undefined | null;
}

export type CheckboxDropdownValue = string | number | undefined | null;

export interface CheckboxDropdownProps<T = CheckboxDropdownValue> {
  label: string;
  options?: Array<CheckboxDropdownOption>;
  value?: Array<T> | null;
  placeholder?: string;
  searchable?: boolean;
  selectAll?: boolean;
  onChange: (data: Array<T> | undefined) => void;
}

const CheckboxDropdown: React.FunctionComponent<CheckboxDropdownProps> = ({
  label,
  options,
  value,
  placeholder,
  searchable,
  selectAll,
  onChange,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const filteredOptions = useMemo(
    () =>
      searchable && searchQuery
        ? options?.filter((o) => o.text.search(new RegExp(searchQuery, 'i')) !== -1)
        : options,
    [options, searchable, searchQuery]
  );
  const [selectedOptions, setSelectedOptions] = useState<Array<CheckboxDropdownValue>>([]);

  useEffect(() => {
    setSelectedOptions(value ?? []);
  }, [value]);

  const handleSearchChange = useCallback(
    (_: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) =>
      setSearchQuery(data.searchQuery),
    [setSearchQuery]
  );

  const handleCheckboxChange = (item: CheckboxDropdownValue, checked?: boolean) => {
    let newSelectedOptions: Array<CheckboxDropdownValue>;
    if (checked) {
      newSelectedOptions = selectedOptions.slice(0);
      newSelectedOptions.push(item);
    } else {
      newSelectedOptions = selectedOptions.filter((v) => v !== item);
    }
    setSelectedOptions(newSelectedOptions);
  };

  const handleSelectAllChange = (checked?: boolean) => {
    const newSelectedOptions = (checked && filteredOptions?.map((o) => o.value)) || [];
    setSelectedOptions(newSelectedOptions);
  };

  const handleDropdownClosed = () => {
    if (searchable) {
      setSearchQuery('');
    }
    onChange(selectedOptions.length ? selectedOptions : undefined);
  };

  return (
    <>
    <label>{label}</label>
    <Dropdown
      selection
      placeholder={placeholder}
      search={searchable}
      onSearchChange={handleSearchChange}
      onBlur={handleDropdownClosed}
    >
      <Dropdown.Menu>
        {selectAll && (
          <Dropdown.Item key="SELECT_ALL">
            <Checkbox
              label="Select All"
              checked={filteredOptions?.length == selectedOptions.length}
              onChange={(_, data) => handleSelectAllChange(data.checked)}
            />
          </Dropdown.Item>
        )}
        {filteredOptions?.map((o) => (
          <Dropdown.Item key={o.value} value={(o.value === null ? undefined : o.value)} text={o.text}>
            <Checkbox
              label={o.text}
              checked={selectedOptions.includes(o.value) || false}
              onChange={(_, data) => handleCheckboxChange(o.value, data.checked)}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
    </>
  );
};

export default CheckboxDropdown;
