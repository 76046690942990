import { createAction } from '@reduxjs/toolkit';
import { ChangelogChangeItem, Program } from '../../api/types';
import {
  ItemLoadingErrorPayloadT,
  PaginatedDataLoadErrorPayload,
  PaginatedDataLoadPayload,
} from '../commonTypes';
import { ProgramChangelogPaginatedParams, ProgramStatusChanged } from './programTypes';

export const clearProgramData = createAction<number>('program/clearProgramData');
export const clearProgramDataWithRelatedProgramId = createAction<number>('program/clearProgramDataWithRelatedProgramId');
export const loading = createAction<number>('program/loading');
export const load = createAction<Program>('program/load');
export const loadingError = createAction<ItemLoadingErrorPayloadT<number>>('program/loadingError');
export const creating = createAction('program/creating');
export const create = createAction<Program>('program/create');
export const creatingError = createAction<string>('program/creatingError');
export const editing = createAction<Program>('program/editing');
export const edit = createAction<Program>('program/edit');
export const editingError = createAction<any>('program/editingError');
export const updateStatuses = createAction<Array<ProgramStatusChanged>>('program/updateStatuses');
export const deleteProgramById = createAction<number>('programs/deleteProgram');
export const deletePrograms = createAction<Array<number>>('programs/deletePrograms');
export const deletingError = createAction<any>('program/deletingError');
export const loadingChangelogs = createAction<ProgramChangelogPaginatedParams>('program/loadingChangelogs');
export const loadChangelogs =
  createAction<PaginatedDataLoadPayload<ChangelogChangeItem, ProgramChangelogPaginatedParams>>(
    'program/loadChangelogs'
  );
export const loadingChangelogsError = createAction<
  PaginatedDataLoadErrorPayload<ProgramChangelogPaginatedParams>
>('program/loadingChangelogsError');
