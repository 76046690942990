import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface AomLoaderProps {
  className?: string;
  active?: boolean;
}

const AomLoader: React.FunctionComponent<AomLoaderProps> = ({ className , active = true }): JSX.Element => {
  return <Dimmer active={active} inverted>
    <Loader active inline='centered' className={className} content="Loading"/>
  </Dimmer>;
};

export default AomLoader;
