import { memoize } from 'lodash';
import { Airport, Region } from '../../api/types';
import { parseOptionCategory } from '../../components/LocationsSelector/LocationsSelector';
import { Destination, DestinationSet, MarketData, OptionCategory, SelectedOption } from './marketDataTypes';

interface mapEntitiesOptions<T> {
  nameSelector?: (item: T) => string | undefined | null;
  itemUpdater?: (entity: Destination, item: T) => void;
  innerDestinationsResolvers?: {
    [key in OptionCategory]?: (sourceList: SetOfCodeDestination, targetSet: DestinationSet, item: T) => void;
  };
  additionalInfoResolvers?: {
    [key: string]: (item: T) => string | undefined | null;
  };
}

interface EntitiesData<T> {
  type: OptionCategory;
  innerDestinations?: OptionCategory[];
  options?: mapEntitiesOptions<T>;
}

type SetOfCodeDestination = { [key in OptionCategory]: { [code: string]: Destination } };

const sortableOptions = [
  OptionCategory.Airport,
  OptionCategory.Country,
  OptionCategory.MetroArea,
  OptionCategory.Region,
  OptionCategory.State,
  OptionCategory.TrafficConference,
  OptionCategory.Zone,
];

export const mappingOptions = {
  [OptionCategory.TrafficConference]: (trafficConferences: string[], _: MarketData) =>
    trafficConferences,
  [OptionCategory.Region]: (regionNames: string[], _: MarketData) => regionNames,
  [OptionCategory.Zone]: (zoneNames: string[], _: MarketData) => zoneNames,
  [OptionCategory.Country]: (countryNames: string[], marketData: MarketData) =>
    mapFromCountries(countryNames, marketData),
  [OptionCategory.MetroArea]: (metroAreas: string[], _: MarketData) => metroAreas,
  [OptionCategory.State]: (states: string[], _: MarketData) => states,
  [OptionCategory.Airport]: (airports: string[], _: MarketData) => airports,
};

export const formatMarketDataOption = (type: OptionCategory, value: string) => `${type}_${value}`;

export const parseMarketDataOptions = memoize((optionValues: string[]) =>
  optionValues.map(
    (v) => ({ type: parseOptionCategory(v.split('_')[0]), value: v.split('_')[1] } as SelectedOption)
  )
);

const coverArea = (areas: Destination[], currentIndex: number, countries: string[]) => {
  for (let i = currentIndex; i < areas.length; i++) {
    const innerCountries = areas[i].innerDestinations?.[OptionCategory.Country]?.map((c) => c.code);
    if (innerCountries?.length && !innerCountries?.find((c) => !countries.includes(c))) {
      const remainingCountries = countries.filter((c) => !innerCountries?.includes(c));
      if (remainingCountries.length) {
        const coveredAreas: Destination[] = coverArea(areas, i + 1, remainingCountries);
        if (coveredAreas?.length) {
          coveredAreas.unshift(areas[i]);
          return coveredAreas;
        }
      } else {
        return [areas[i]];
      }
    }
  }

  return [];
};

export const mapCountriesToGeographicalEntites = (countries: string[], marketData: MarketData) => {
  if (countries.length > 3) {
    const checkedAreaTypes = [OptionCategory.TrafficConference, OptionCategory.Region, OptionCategory.Zone];

    checkedAreaTypes.forEach((item) => {
      const areas = marketData.destinations?.[checkedAreaTypes[item]];
      if (areas?.length) {
        const matchingAreas = coverArea(areas, 0, countries);
        if (matchingAreas.length) {
          return matchingAreas.map((a) => formatMarketDataOption(checkedAreaTypes[item], a.name));
        }
      }
    });
  }
  return countries.map((c) =>
    formatMarketDataOption(OptionCategory.Country, mapCountryNameFromCode(c, marketData) || c)
  );
};

export const mapCountryNameFromCode = (code: string, marketData: MarketData) => {
  return marketData.destinations?.[OptionCategory.Country]?.find((c) => c.code == code)?.name;
};

export const mapFromCountries = (countries: string[], marketData: MarketData) => {
  return (
    countries.map(
      (country) => marketData.destinations?.[OptionCategory.Country]?.find((c) => c.name == country)?.code
    ) ||
    countries.map(
      (country) => marketData.destinations?.[OptionCategory.Country]?.find((c) => c.code == country)?.code
    )
  );
};

export const distinct = (arr: Array<any>): Array<any> => {
  return Array.from(new Set(arr));
};

const destinationsComparer = (c1: Destination, c2: Destination) =>
  (c1.name && c1.name.localeCompare(c2.name)) || -1;

export const mapFromAirports = (airports: Airport[]) => {
  const entitiesList = {
    0: {} as { [key: string]: Destination },
  } as SetOfCodeDestination;

  const data = [
    {
      type: OptionCategory.Airport,
      innerDestinations: undefined,
      options: {
        nameSelector: (item: any) => item.Name,
        additionalInfoResolvers: { city: (item: any) => item.City },
      },
    },
    {
      type: OptionCategory.MetroArea,
      innerDestinations: [OptionCategory.Airport],
      options: { nameSelector: (item: any) => item.MutiAptCityName },
    },
    {
      type: OptionCategory.State,
      innerDestinations: [OptionCategory.MetroArea, OptionCategory.Airport],
    },
    {
      type: OptionCategory.Country,
      innerDestinations: [OptionCategory.State, OptionCategory.MetroArea, OptionCategory.Airport],
      options: {
        nameSelector: (item: any) => item.CountryName,
        itemUpdater: (entity: any, item: any) => {
          if (item.CountryName && item.CountryName !== entity.name) {
            entity.name = item.CountryName;
          }
        },
      },
    },
  ];

  mapEntites(airports, entitiesList, data);
  return entitiesList;
};

export const mapFromRegions = (regions: Region[], entitiesList: SetOfCodeDestination) => {
  const data = [
    {
      type: OptionCategory.Zone,
      innerDestinations: [OptionCategory.Country],
      options: {
        innerDestinationsResolvers2: {
          [OptionCategory.Country]: countryWithStatesInnerDestinationsResolver,
        },
      },
    },
    {
      type: OptionCategory.Region,
      innerDestinations: [OptionCategory.Zone, OptionCategory.Country],
      options: {
        innerDestinationsResolvers: {
          [OptionCategory.Country]: countryWithStatesInnerDestinationsResolver,
        },
      },
    },
    {
      type: OptionCategory.TrafficConference,
      innerDestinations: [OptionCategory.Region, OptionCategory.Country],
      options: {
        innerDestinationsResolvers2: {
          [OptionCategory.Country]: countryWithStatesInnerDestinationsResolver,
        },
      },
    },
  ];

  mapEntites(regions, entitiesList, data);

  const destinationSet = Object.assign(
    {},
    ...Object.entries(entitiesList).map(([k, v]) => ({ [k]: Object.values(v) }))
  ) as DestinationSet;

  sortableOptions.forEach((value) => {
    destinationSet[value]?.sort(destinationsComparer);
  });

  const result = {} as MarketData;
  result.destinations = destinationSet;

  return result;
};

const codesSelectors: { [key in OptionCategory]: (item: any) => string | null | undefined } = {
  [OptionCategory.Airport]: (item: Airport) => item.Code,
  [OptionCategory.MetroArea]: (item: Airport) => item.MutiAptCityCode,
  [OptionCategory.State]: (item: Airport) => item.StateCode,
  [OptionCategory.Country]: (item: any) => item.countryCode || item.CountryCode,
  [OptionCategory.Zone]: (item: Region) => item.ZoneName,
  [OptionCategory.Region]: (item: Region) => item.RegionName,
  [OptionCategory.TrafficConference]: (item: Region) => item.TrafficConference,
};

const mapEntites = <T extends Airport | Region>(
  sourceSet: T[],
  entitiesList: SetOfCodeDestination,
  entitiesDatas: EntitiesData<T>[]
) => {
  Object.values(
    sourceSet.reduce((entities, item) => {
      try {
        entitiesDatas.forEach((entityData) => {
          const code = codesSelectors[entityData.type](item);
          if (code) {
            const codeWithType = `${code}-${entityData.type}`;

            if (!entities[codeWithType]) {
              entities[codeWithType] = {
                type: entityData.type,
                code: code,
                name: entityData.options?.nameSelector?.(item) || code,
                innerDestinations: {},
              };
            } else if (entityData.options?.itemUpdater) {
              entityData.options?.itemUpdater?.(entities[codeWithType], item);
            }

            const entity = entities[codeWithType];

            mapInnerDestinations(entityData, entity, item, entitiesList);
            mapAdditionalInfo(entityData, entity, item);

            if (!entitiesList[entityData.type]) {
              entitiesList[entityData.type] = {};
            }

            if (!entitiesList[entityData.type][code]) {
              entitiesList[entityData.type][code] = entity;
            }
          }
        });

        return entities;
      } catch (e) {
        throw {
          message: `Error mapping entity`,
          entity: item,
          innerException: e,
        };
      }
    }, {} as { [key: string]: Destination })
  );

  return entitiesList;
};

const mapInnerDestinations = <T extends Airport | Region>(
  entityData: EntitiesData<T>,
  entity: Destination,
  item: T,
  entitiesList: SetOfCodeDestination
) => {
  entityData.innerDestinations?.forEach((innerCategory) => {
    if (entityData.options?.innerDestinationsResolvers?.[innerCategory]) {
      entityData.options?.innerDestinationsResolvers?.[innerCategory]?.(
        entitiesList,
        entity.innerDestinations as DestinationSet,
        item
      );
    } else {
      pushMappedEntity(
        entitiesList,
        entity.innerDestinations as DestinationSet,
        innerCategory,
        codesSelectors[innerCategory](item)
      );
    }
  });
};

const mapAdditionalInfo = <T extends Airport | Region>(
  entityData: EntitiesData<T>,
  entity: Destination,
  item: T
) => {
  if (entityData.options?.additionalInfoResolvers) {
    Object.keys(entityData.options.additionalInfoResolvers).forEach((k) => {
      const additionalInfo = entityData.options?.additionalInfoResolvers?.[k]?.(item);
      if (additionalInfo) {
        entity.additionalInfo = entity.additionalInfo || {};
        entity.additionalInfo[k] = additionalInfo;
      }
    });
  }
};

const countryWithStatesInnerDestinationsResolver = (
  sourceList: SetOfCodeDestination,
  targetSet: DestinationSet,
  region: Region
) => {
  if (region.StateCodes?.length) {
    region.StateCodes.forEach((s) => pushMappedEntity(sourceList, targetSet, OptionCategory.State, s));
  } else {
    pushMappedEntity(
      sourceList,
      targetSet,
      OptionCategory.Country,
      codesSelectors[OptionCategory.Country](region)
    );
  }
};

const pushMappedEntity = (
  sourceList: SetOfCodeDestination,
  targetSet: DestinationSet,
  category: OptionCategory,
  code?: string | null
) => {
  if (!code || targetSet[category]?.find((e) => e.code === code)) {
    return;
  }

  const entity = sourceList[category][code];

  if (!entity) {
    return;
  }

  if (!targetSet[category]) {
    targetSet[category] = [];
  }

  targetSet[category]?.push(entity);
};
