import React, { useState } from 'react';
import { useEffect } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Checkbox, Icon } from 'semantic-ui-react';
import settings from '../../settings';
import './DatesSelector.css';

export interface DatesSelectorProps {
  label?: string;
  onChange: (data: Date[][]) => void;
  value?: Date[][];
  multiple?: boolean;
}

const DatesSelector: React.FunctionComponent<DatesSelectorProps> = ({
  label,
  onChange,
  value,
  multiple = true,
}) => {
  const [dates, setDates] = useState<Array<Date | undefined>[]>(value || [[]]);
  const [openDatepickerOnSelect, setOpenDatepickerOnSelect] = useState<boolean>(false);

  const updateDates = (action: (newDates: Array<Date | undefined>[]) => boolean, notify = false) => {
    const newDates = dates.slice(0);
    if (action(newDates)) {
      setDates(newDates);
      if (notify) {
        onChange(newDates.filter((ds) => ds.every((d) => d)).map((d) => d as Date[]));
      }
    }
  };

  const handleRangeChanged = (index: number, isRange: boolean) => {
    updateDates((newDates) => {
      const valueToInsert = isRange ? [dates[index][0], dates[index][0]] : [dates[index][0]];
      newDates.splice(index, 1, valueToInsert);
      return true;
    }, true);
  };

  const handleDateChanged = (index: number, value: Date | Date[], isRange: boolean) => {
    updateDates((newDates) => {
      let valueToInsert: Array<Date | undefined>;
      if (value == null) {
        valueToInsert = isRange ? [undefined, undefined] : [undefined];
      } else {
        if (isRange) {
          valueToInsert = value as Date[];
          if (valueToInsert.length < 2) {
            return false;
          }
        } else {
          valueToInsert = [value as Date];
        }
      }
      newDates.splice(index, 1, valueToInsert);
      return true;
    }, true);
  };

  const handleAddMoreDates = () => {
    updateDates((newDates) => {
      newDates.push([]);
      return true;
    });
  };

  const handleRemoveDates = (index: number) => {
    updateDates((newDates) => {
      newDates.splice(index, 1);
      return true;
    }, true);
  };

  useEffect(() => {
    setDates(value || [[]]);
  }, [value]);

  return (
    <div className="dates-selector">
      {dates.map((d, i) => (
        <React.Fragment key={i + 'dates-selector'}>
          {multiple && (
            <Icon link name="times" color="blue" title="Remove" onClick={() => handleRemoveDates(i)} />
          )}
          <form action={undefined} style={{ display: 'inline-block' }}>
            {' '}
            {/* workaround for form submit in range mode */}
            <SemanticDatepicker
              key={`${i}.${d.length}`}
              clearOnSameDateClick={false}
              format={settings.dateFormat}
              keepOpenOnSelect={openDatepickerOnSelect}
              keepOpenOnClear={true}
              onChange={(e, data) => {
                setOpenDatepickerOnSelect(!e);
                handleDateChanged(i, data.value as Date | Date[], data.type === 'range');
              }}
              value={(d.length > 1 && d[0] ? (d as Date[]) : d[0]) || undefined}
              type={d.length > 1 ? 'range' : 'basic'}
            />
          </form>
          <Checkbox
            className="range"
            label="Range"
            checked={d.length > 1}
            onChange={(_, data) => handleRangeChanged(i, data.checked == true)}
          />
          <br />
        </React.Fragment>
      ))}
      {multiple && (
        <Button basic color="blue" type="button" onClick={handleAddMoreDates}>
          Add More {label || 'Dates'}
        </Button>
      )}
    </div>
  );
};

export default DatesSelector;
