import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Checkbox, Dropdown, Icon, Popup, Ref, Table } from 'semantic-ui-react';
import { FormWithRules } from '../../services/rules';
import { carriersWithGroupsSelector } from '../../store/carriers/carriersSelectors';
import { marketDataSelector } from '../../store/marketData/marketDataSelectors';
import { mapColumnToClassName } from '../RulesViewTable/RulesViewTable';
import { allColumns, Column, ColumnData, defaultControl, SelectAll } from './columns';
import ExcludingForm from './ExcludingForm/ExcludingForm';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './RulesEditTable.css';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

export interface RulesEditTableProps {
  defaultColumns: Column[];
  optionalColumns?: Column[];
  excludingColumns?: Column[];
  currentData?: any;
  form: UseFormReturn<FormWithRules>;
  formFieldName: string;
  label?: string;
  className?: string;
  isAllSelectExist?: boolean;
}

const headerColumnTemplate = (c: Column) => (
  <Table.HeaderCell width={c.width} key={c.name}>
    {c.displayName}
    {c.tooltip && (
      <Popup
        trigger={<Icon name={c.tooltip.icon} />}
        offset={c.tooltip?.offset}
        position={c.tooltip.position}
        content={c.tooltip?.content}
      >
        {c.tooltip?.table}
      </Popup>
    )}
  </Table.HeaderCell>
);

const RulesEditTable: React.FunctionComponent<RulesEditTableProps> = ({
  defaultColumns,
  optionalColumns,
  currentData,
  form,
  formFieldName,
  label,
  className,
  isAllSelectExist,
  excludingColumns,
}) => {
  const [selectedColumns, setSelectedColumns] = useState<Column[]>([]);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState<boolean>(false);
  const { fields, append, remove, move, insert } = useFieldArray({ control: form?.control, name: formFieldName });
  const [refreshState, setRefreshState] = useState([]);

  const marketData = useSelector(marketDataSelector);
  const carrierGroups = useSelector(carriersWithGroupsSelector);
  const columnData = useMemo(
    () => ({ marketData, carrierGroups } as ColumnData),
    [marketData, carrierGroups]
  );

  const handleChangeColumns = (column: Column, added: boolean | undefined) => {
    if (column.name === SelectAll && optionalColumns) {
      if (added) {
        setSelectedColumns(optionalColumns);
        setIsSelectAllSelected(true);
      } else {
        setSelectedColumns([]);
        setIsSelectAllSelected(false);
      }
    } else {
      const newSelectedColumns = selectedColumns.slice(0);
      if (added) {
        newSelectedColumns.push(column);
      } else {
        const columnIndex = newSelectedColumns.indexOf(column);
        newSelectedColumns.splice(columnIndex, 1);
        fields.forEach((f, i) => {
          form.setValue<any>(`${formFieldName}.${i}.${column.name}`, undefined as any);
          column.additionalFields?.forEach((af) =>
            form.setValue<any>(`${formFieldName}.${i}.${af}`, undefined as any)
          );
          form.clearErrors(`${formFieldName}.${i}.${column.name}`);
        });

        if (isAllSelectExist && selectedColumns.length === optionalColumns?.length) {
          setIsSelectAllSelected(false);
        }
      }
      setSelectedColumns(newSelectedColumns);
    }
  };

  useEffect(() => {
    const visibleOptionalColumns = optionalColumns?.filter((c) =>
      fields.find((f: any) => typeof f[c.name] !== 'undefined')
    );
    if (visibleOptionalColumns?.length) {
      setSelectedColumns(visibleOptionalColumns);
    }
  }, []);

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (!name) {
        return;
      }

      if (name.indexOf(allColumns.Excluding.name) >= 0) {
        // refresh the form when excluding is toggled
        setRefreshState([]);
        return;
      }

      const dotIndex = name.lastIndexOf('.');
      const rowName = name.substring(0, dotIndex);
      const fieldName = name.substring(dotIndex + 1);
      if (
        excludingColumns?.find((c) => c.name === fieldName) && // if a column among excluding is changed
        form.getValues(`${rowName}.${allColumns.Excluding.additionalFields?.[0]}`)
      ) {
        // and Excluding is shown
        setRefreshState([]);
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const columnTemplate = (column: Column, rowNumber: number) => (
    <Table.Cell
      negative={(form.formState.errors?.[formFieldName]?.[rowNumber] as any)?.[column.name] != undefined}
      className={
        column.className +
        ' ' +
        mapColumnToClassName[column.name] +
        (((form.formState.errors?.[formFieldName]?.[rowNumber] as any)?.[column.name]?.message &&
          ' error-message') ||
          '')
      }
      width={column.width}
      key={column.name}
      style={{ minWidth: column.minWidth + 'px' }}
    >
      {(column.control || defaultControl)(
        form,
        `${formFieldName}.${rowNumber}.`,
        column,
        currentData,
        columnData
      )}
      {(form.formState.errors?.[formFieldName]?.[rowNumber] as any)?.[column.name] && (
        <p>{(form.formState.errors?.[formFieldName]?.[rowNumber] as any)?.[column.name]?.message}</p>
      )}
    </Table.Cell>
  );

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    move(result.source.index, result.destination.index);
  }

  const tableCells = (field: any, i: number) => {
    return (
      <>
        <Table.Cell style={{ width: '20px' }} textAlign="center">
          <MaterialIcon name="drag_indicator" />
        </Table.Cell>
        {defaultColumns.map((c) => columnTemplate(c, i))}
        {selectedColumns.map((c) => columnTemplate(c, i))}
        <Table.Cell textAlign="center">
          <span>
          <Icon name="copy outline" link color="blue" onClick={() => insert(i+1, field)} />
          <Icon
              name="trash alternate outline"
              color="blue"
              link
              title={`Remove ${label}`}
              onClick={() => remove(i)}
            />
          </span>
        </Table.Cell>
      </>
    )
  };

  const getItemStyle = (draggableStyle: any) => ({
    ...draggableStyle,
    userSelect: 'none',
    position: 'static',
    margin: `8px 0 8px 0`
  });

  return (
    <>
      {optionalColumns && (
        <Dropdown
          button
          basic
          floating
          direction="left"
          className="blue icon add-condition-button"
          labeled
          icon="plus circle"
          text="Add Condition"
          closeOnChange={false}
        >
          <Dropdown.Menu>
            {isAllSelectExist && (
              <Dropdown.Item key={SelectAll}>
                <Checkbox
                  label={SelectAll}
                  checked={isSelectAllSelected}
                  onChange={(e, data) =>
                    handleChangeColumns({ name: SelectAll, displayName: SelectAll }, data.checked)
                  }
                />
              </Dropdown.Item>
            )}
            {Object.values(optionalColumns).map((c) => (
              <Dropdown.Item key={c.displayName}>
                <Checkbox
                  label={c.displayName}
                  checked={selectedColumns.includes(c)}
                  onChange={(e, data) => handleChangeColumns(c, data.checked)}
                />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Table className={`rules-edit-table ${className}`} celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              {defaultColumns.map(headerColumnTemplate)}
              {selectedColumns.map(headerColumnTemplate)}
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Droppable droppableId="droppable">
          {(provided) => (
            <Ref innerRef={provided.innerRef}>
            <Table.Body {...provided.droppableProps}>
              {fields.map((f, i) => {
                const excludingShown =
                  form.getValues(`${formFieldName}.${i}.${allColumns.Excluding.additionalFields?.[0]}`) ||
                  (form.formState?.errors?.[i] as any)?.[allColumns.Excluding.name];
                const rowData = form.getValues(`${formFieldName}.${i}`);
                const data = Object.assign({}, currentData, rowData);
                return (
                  <>
                    <Draggable draggableId={f.id} key={f.id} index={i}>
                      {(provided) => (
                        <Ref innerRef={provided.innerRef}>
                        <Table.Row
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={f.id}
                          className={(excludingShown && 'excluding') || ''}
                          style={getItemStyle(provided.draggableProps.style)}
                        >
                          {tableCells(f, i)}
                        </Table.Row>
                        </Ref>
                        )}
                        </Draggable>
                    {excludingShown && excludingColumns && (
                      <Table.Row key={`${f.id}Excluding`} className="excluding excluding-form">
                        <Table.Cell colSpan={defaultColumns.length + selectedColumns.length + 1}>
                          <ExcludingForm
                            columns={excludingColumns}
                            form={form}
                            formFieldName={formFieldName}
                            formRowIndex={i}
                            formColumnName="Excluding"
                            currentData={data}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                );
              })}
              {provided.placeholder}
              <Table.Row>
                <Table.Cell colSpan={defaultColumns.length + selectedColumns.length + 2}>
                  <span
                    className="link-button"
                    onClick={() => {
                      append({});
                    }}
                  >
                    <Icon name="plus circle" />
                    Add{(label && ` ${label}`) || ''}
                  </span>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            </Ref>
          )}
          </Droppable>
        </Table>
      </DragDropContext>
    </>
  );
};

export default RulesEditTable;
