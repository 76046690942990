import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps, Form, Label, Popup } from 'semantic-ui-react';
import { carriersByCodeSelector } from '../../store/carriers/carriersSelectors';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { loadCarriers } from '../../store/carriers/carriersActions';
import { useAppDispatch } from '../../store/useAppDispatch';

export interface CarriesDropdownProps {
  onChange: (value?: string[]) => void;
  onBlur: (event: React.FocusEvent, data: DropdownProps) => void;
  optionValue: string[];
  value: string[];
  placeholder: string;
}

const CarriesDropdown: React.FunctionComponent<CarriesDropdownProps> = ({
  onBlur,
  optionValue,
  onChange,
  value,
  placeholder,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(loadCarriers());
  }, [dispatch]);

  const dataList = useSelector(carriersByCodeSelector(optionValue));
  const options = useMemo(
    () =>
      dataList.reduce((options, c) => {
        if (options.find((o) => o.value == c.code)) {
          return options;
        }
        options.push({
          text: c.name,
          value: c.code,
          key: c.code,
          tooltip: c.tooltip,
          tooltipHeader: c.tooltipHeader,
        });

        if (c.carriers) {
          options.push(
            ...c.carriers
              .filter((cr) => !options.find((o) => o.value === cr.code))
              .map((cr) => ({
                text: cr.name,
                value: cr.code,
                key: cr.code,
              }))
          );
        }

        return options;
      }, new Array<DropdownItemProps>()),
    [dataList]
  );

  return (
    <>
      <Form.Field>
        <Dropdown
          placeholder={placeholder}
          options={options}
          noResultsMessage="Select carriers"
          onChange={(e, { value }) => {
            onChange(value as string[]);
          }}
          value={value}
          onBlur={onBlur}
          multiple
          renderLabel={(item, index, props) =>
            (item.tooltip && (
              <Popup
                header={item.tooltipHeader}
                className="wrap-newlines"
                content={item.tooltip}
                trigger={<Label content={item.text} {...props} />}
              />
            )) || <Label content={item.text} {...props} />
          }
        />
      </Form.Field>
    </>
  );
};

export default CarriesDropdown;
