import { Contract, GdsData } from '../../api/types';
import { AccountOptions } from '../../app/contracts/CreateContract/CustomerInfo/AccountTypes';
import { mapToAccountType } from '../../app/contracts/CreateContract/CustomerInfo/helper';
import getLocalDate from '../../services/utils/getLocalDate';
import getUTCDate from '../../services/utils/getUTCDate';
import { ContractViewModel } from './contractTypes';

export const mapContractToViewModel = (contract: Contract) =>
  Object.assign<Partial<ContractViewModel>, Contract | null, Partial<ContractViewModel>>({}, contract, {
    Accounts: { accounts: contract?.Accounts || [], groups: [], selectedOptions: [] },
    EndDate: contract?.EndDate ? getLocalDate(contract.EndDate) : undefined,
  }) as ContractViewModel;

export const mapContractFromViewModel = (viewModel: ContractViewModel, originalContract?: Contract) =>
  Object.assign<Partial<Contract>, Contract, ContractViewModel, Partial<Contract>>(
    {},
    originalContract || ({} as Contract),
    viewModel,
    {
      Accounts:
        viewModel.Type !== 'BCD' && viewModel.Accounts?.accounts
          ? mapToAccountType(viewModel.Accounts?.accounts as AccountOptions[])
          : null,
      EndDate: viewModel.EndDate && getUTCDate(viewModel.EndDate).toISOString(),
      GdsInfos: viewModel.GdsInfos?.filter((i: GdsData) => i.Gds || i.PricingIdCode || i.FillingMethod),
    }
  ) as Contract;
