import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import { buttonLoading, hide } from '../../store/confirmation/confirmationActions';
import { confirmationSelector } from '../../store/confirmation/confirmationSelectors';
import { ConfirmationButton } from '../../store/confirmation/confirmationTypes';
import { useAppDispatch } from '../../store/useAppDispatch';

const Confirmation: React.FunctionComponent = () => {
  const confirmation = useSelector(confirmationSelector);
  const dispatch = useAppDispatch();

  const handleButtonClick = async (button: ConfirmationButton) => {
    if (button.onClick) {
      dispatch(buttonLoading({ displayName: button.displayName, isLoading: true }));
      await button.onClick();
      dispatch(buttonLoading({ displayName: button.displayName, isLoading: false }));
    }
    dispatch(hide());
  };

  const buttons = useMemo(
    () => confirmation.buttons || [{ displayName: 'OK', isPrimary: true }],
    [confirmation.buttons]
  );

  return (
    <Modal open={confirmation.displayed}>
      {confirmation.header && <Modal.Header>{confirmation.header}</Modal.Header>}
      <Modal.Content>{confirmation.content}</Modal.Content>
      <Modal.Actions>
        {buttons.map((b) => {
          return (
            <Button
              key={b.displayName}
              color="blue"
              primary={b.isPrimary}
              basic={!b.isPrimary}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onClick={handleButtonClick.bind(this, b)}
              loading={b.isLoading}
            >
              {b.displayName}
            </Button>
          );
        })}
      </Modal.Actions>
    </Modal>
  );
};

export default Confirmation;
