import React, { PropsWithChildren, useState } from 'react';
import { Icon, Menu, SemanticICONS, Sidebar } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import './MainMenu.scss';

const isActive = (location: ReturnType<typeof useLocation>, path: string) => {
  return location.pathname.startsWith(path);
};


type NavigationItem = { path:string, name:string, icon: SemanticICONS }
const MainMenu: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [wide, setWide] = useState(false);
  const location = useLocation();
  
  const navigationList = [
    {
        path: '/contracts',
        name: 'Contracts',
        icon: 'paperclip' as SemanticICONS,
    },

    { 
        path: '/repository',
        name: 'Repository',
        icon: 'folder' as SemanticICONS,
    },
    {
        path: '/usageReport',
        name: 'Usage Report',
        icon: 'briefcase' as SemanticICONS,
    }
  ];
  
  return (
    <Sidebar.Pushable as="div">
      <Sidebar
        as={Menu}
        inverted
        vertical
        visible={true}
        animation="push"
        width={wide ? 'wide' : 'very thin'}
        className="main-menu big"
      >
        <Menu.Item className="collapse-icon" onClick={() => setWide(!wide)}>
          <Icon name="bars" />
          {wide && ' '}
        </Menu.Item>
        {navigationList.map((item: NavigationItem) => (
          <Menu.Item
            key={item.name}
            name={item.name}
            as={Link}
            to={item.path}
            active={isActive(location, item.path)}
            title={item.name}
          >
            {isActive(location, item.path) && <span className="active-icon" />}
            {item.icon && <Icon name={item.icon} />}
            {wide && item.name}
          </Menu.Item>
        ))}
      </Sidebar>
      <Sidebar.Pusher>
        <div>{children}</div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default MainMenu;
