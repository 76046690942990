import { Button, Modal } from 'semantic-ui-react';
import React, { useEffect } from 'react';
import { LoadStateType } from '../../../store/commonTypes';
import { useSelector } from 'react-redux';
import { lastFileLoadStateSelector } from '../../../store/repository/repositorySelector';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { UploadFile } from '../types';
import './UploadFileModal.scss';

interface UploadFileModalProps {
  modal: {
    headerText: string;
    isOpen: boolean;
    onChange: (isOpen: boolean) => void;
    onClose: () => void;
  };
  file: {
    value: UploadFile;
    onChange: (file: UploadFile) => void;
  };
  selectFromRepositoryButton: {
    isVisible: boolean;
    onClick?: () => void;
  };
  submitButton: {
    onClick: (file: UploadFile) => Promise<void>;
    disabled: {
      value: boolean;
      onChange: (disabled: boolean) => void;
    };
  };
  cancelButton: {
    onClick: () => void;
  };
  error: {
    value: string | null;
    onChange: (error: string | null) => void;
  };
}

const UploadFileModal: React.FunctionComponent<UploadFileModalProps> = (props) => {
  const { modal, submitButton, file, selectFromRepositoryButton, error, cancelButton } = props;

  useEffect(() => {
    submitButton.disabled.onChange(!file.value || (error.value?.length as number) > 0);

    return () => {
      submitButton.disabled.onChange(false);
    };
  }, [file.value, error.value?.length]);

  const lastFileUploadState = useSelector(lastFileLoadStateSelector);

  return (
    <>
      <Modal
        size="small"
        onClose={() => {
          modal.onChange(false);
          file.onChange(null);
        }}
        onOpen={() => modal.onChange(true)}
        open={modal.isOpen}
      >
        <Modal.Header>
          <h3>{modal.headerText}</h3>
        </Modal.Header>
        <Modal.Content>
          <FileSelector
            value={file.value}
            onChange={file.onChange}
            onRemoveButtonClick={() => {
              error.onChange('');
            }}
          />
          {error.value && file && <div className="error-message">{error.value}</div>}
        </Modal.Content>
        <Modal.Actions
          style={{ justifyContent: selectFromRepositoryButton.isVisible ? 'space-between' : 'flex-end' }}
        >
          {selectFromRepositoryButton.isVisible && (
            <Button
              className="browse"
              color="blue"
              disabled={file.value != null}
              onClick={() => {
                if (selectFromRepositoryButton.onClick) {
                  selectFromRepositoryButton.onClick();
                }
              }}
            >
              Select from Repository
            </Button>
          )}
          <span>
            <Button basic color="blue" onClick={cancelButton.onClick}>
              Cancel
            </Button>
            <Button
              primary
              loading={lastFileUploadState.type === LoadStateType.Loading}
              color="blue"
              disabled={submitButton.disabled.value}
              onClick={async () => await submitButton.onClick(file.value)}
            >
              Upload
            </Button>
          </span>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default UploadFileModal;
