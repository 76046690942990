import React, { useState } from 'react';
import { Dropdown, Header } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../store/currentUser/currentUserSelectors';
import { Auth } from '@aws-amplify/auth';
import './AomHeader.scss';
import { load } from '../../store/currentUser/currentUserActions';
import AomLogo from './AomLogo/AomLogo';
import { useAppDispatch } from '../../store/useAppDispatch';
import { useNavigate } from 'react-router-dom';


const AomHeader: React.FunctionComponent = () => {
  const currentUser = useSelector(currentUserSelector);
  const navigate = useNavigate();
  
  const getFirstSymbol = (prop: string | undefined) => prop?.[0] || '';

  const initials = getFirstSymbol(currentUser?.name) + getFirstSymbol(currentUser?.surname);

  const [alertMessage, setAlertMessage] = useState('');
  const dispatch = useAppDispatch();
  const logOut = async () => {
    try {
      await Auth.signOut();
      dispatch(load(null));
      navigate('/auth/login');
    } catch (error: Error | unknown) {
      setAlertMessage((error as Error)?.message);
    }
  };

  return (
    <Header className="main-header">
      <AomLogo />
      <Dropdown text={initials} icon={null} button className="icon-dropdown">
        <Dropdown.Menu className="left menu dropdown">
          <Dropdown.Item onClick={logOut}>Log Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {alertMessage && <div className="error">{alertMessage}</div>}
    </Header>
  );
};

export default AomHeader;
