import React, { useEffect } from 'react';
import AomLoader from '../../../components/AomLoader';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { LoadStateType } from '../../../store/commonTypes';
import { loadContract } from '../../../store/contract/contractActions';
import { contractLoadStateSelector } from '../../../store/contract/contractSelectors';
import useParamsSelector from '../../../store/useParamsSelector';
import { useAppDispatch } from '../../../store/useAppDispatch';
import { Outlet, useParams } from 'react-router-dom';

export type ContractParams = {
  contractId: string;
}

export const Contract: React.FunctionComponent = () => {
  const params = useParams<ContractParams>();
  const contractId = params.contractId!;
  const dispatch = useAppDispatch();
  const contractLoadState = useParamsSelector(contractLoadStateSelector, { id: contractId });

  useEffect(() => {
      void dispatch(loadContract(contractId));
  }, [contractId, dispatch]);

  if (contractLoadState.type === LoadStateType.Error) {
    return <ErrorMessage message={contractLoadState.errorMessage} />;
  }

  if (
    contractLoadState.type === LoadStateType.NotLoaded ||
    contractLoadState.type === LoadStateType.Loading
  ) {
    return <AomLoader />;
  }
  return <Outlet/>;
};
