export type LoadingDataState<T> = {
  loadState: LoadState;
  data?: T;
};

export type LoadState = {
  type: LoadStateType;
  errorMessage?: string | null;
};

export enum LoadStateType {
  NotLoaded,
  Loading,
  Loaded,
  Error,
  Editing,
}

export type ItemLoadingErrorPayload = {
  Id: string;
  errorMessage: string;
};

export type ItemLoadingErrorPayloadT<T> = {
  Id: T;
  errorMessage: string;
};

export const ArrayEmpty = [];

export interface IdParams<T> {
  id: T;
}

export type PaginatedDataState<T> = {
  key: string;
  pages: {
    [key: number]: LoadingDataState<Array<T>>;
  };
  totalCount: number;
};

export interface PaginatedDataParams {
  PageNumber: number | null;
  PageSize: number | null;
}

export interface SortedDataParams {
  SortFieldName: string | null;
  OrderBy: boolean | null;
}

export const notLoadedState: LoadState = {
  type: LoadStateType.NotLoaded,
};

export interface ParamsResponsePayload<P, T> {
  params: P,
  data: T[]
}

export interface ParamsLoadErrorPayload<P> {
  params: P;
  errorMessage: string
}

export type PaginatedResult<T> = {
  Data: T[];
  TotalCount: number;
};

export interface PaginatedDataLoadPayload<TEntity, TParameters> {
  params: TParameters;
  data: PaginatedResult<TEntity>;
}

export interface PaginatedDataLoadErrorPayload<R> extends ParamsLoadErrorPayload<R> {
}

export interface PaginatedSortedIdParams<T> extends IdParams<T>, PaginatedDataParams, SortedDataParams { }
