import { createSelector } from '@reduxjs/toolkit';
import { ArrayEmpty } from '../commonTypes';
import { State } from '../State';
import { CombinationRules, ProgramStatus } from '../program/programTypes';
import { MultipleSelectorItem } from '../../components/MultipleSelectorList/MultipleSelectorList';
import { memoize } from 'lodash';
import { ProgramListItem } from './programListTypes';

export const programListSelector = (state: State) => state.programList.data;

export const programListLoadStateSelector = (state: State) => state.programList.loadState;

export const programListSetSelector = memoize((programIds: string[] | null | undefined) =>
  programIds?.length
    ? createSelector(programListSelector, (programList) =>
      programList?.filter((p) => programIds.includes(p.Id.toString()))
    )
    : () => ArrayEmpty);

export const combinationRulesListSelector = memoize(
  (validatingCarriers?: string[], contractType?: string | null, currentProgramId?: number) => createSelector(
    [programListSelector],
    (programList) => {
      const filterPrograms = (program: ProgramListItem) => {
        const isDraftOrActive = (program: ProgramListItem) => {
          return program.Status === ProgramStatus.Draft
            || program.Status === ProgramStatus.Active;
        }

        const isMatchByValidatingCarriers = (program: ProgramListItem) => {
          return !validatingCarriers?.length ||
            program.ValidatingCarriers.find(code => validatingCarriers.includes(code)) != undefined;
        }

        const isMatchByContractType = (program: ProgramListItem) => {
          return !contractType || program.ContractType === contractType;
        }

        return isMatchByContractType(program) &&
          isDraftOrActive(program) &&
          isMatchByValidatingCarriers(program) &&
          program.Id !== currentProgramId;
      }

      const toMultipleSelectorItem = (program: ProgramListItem): MultipleSelectorItem => {
        const name = `${ program.ProgramName } / ${ program.ValidatingCarriers?.join(',') }`;
        return {
          code: program.Id.toString(),
          name: name
        };
      }

      const defaultOptions: MultipleSelectorItem[] = [
        { name: CombinationRules.allPrograms, code: CombinationRules.allPrograms },
        { name: CombinationRules.onlyWithItself, code: CombinationRules.onlyWithItself },
      ];

      return (programList ?? [])
        .filter(program => filterPrograms(program))
        .map(program => toMultipleSelectorItem(program))
        .concat(defaultOptions);
    }
  ),
  (validatingCarriers?: string[], contractType?: string | null, currentProgramId?: number) => {
    if (!validatingCarriers?.length) {
      return '';
    }
    const carriers = validatingCarriers.slice(0);
    carriers.sort((a, b) => a.localeCompare(b));
    return `${carriers.join('_')}_${contractType}_${currentProgramId ?? ''}`;
  }
);

export const combinationRulesByCodeSelector = (codes: string[]) => (state: State) => {
  if (codes[0] === CombinationRules.onlyWithItself || codes[0] === CombinationRules.allPrograms) {
    return [{ name: codes[0], code: codes[0] }];
  } else {
    return (
      state.programList?.data
        ?.filter(({ Id }) => codes.indexOf(Id.toString()) !== -1)
        .map(({ ProgramName, Id }) => ({ name: ProgramName, code: Id.toString() })) ?? []
    );
  }
};
