export const combinePath = (path: string, pathParams?: any, queryParams?: any): string => {
  let resultPath = path;

  if (pathParams) {
    for (const param in pathParams) {
      resultPath = resultPath.replace(`:${param}`, pathParams[param]?.toString());
    }
  }

  if (queryParams) {
    resultPath = resultPath + '?';
    Object.keys(queryParams).forEach(
      (p, i) => (resultPath = resultPath + `${(i && '&') || ''}${p}=${queryParams[p]}`)
    );
  }

  return resultPath;
};
