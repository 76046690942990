import { createSlice } from '@reduxjs/toolkit';
import { LoadStateType, PaginatedDataLoadErrorPayload } from '../commonTypes';
import {
  ContractChangelogsPaginatedDataPayload,
  ContractChangelogsPaginatedResult,
  ContractsChangelogSearchOptions,
  ContractsChangelogsState
} from './contractsChangelogsTypes';
import {
  contractsChangelogsKeySelector,
  contractsChangelogsPageLoadStateSelector
} from './contractsChangelogsSelectors';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { AppDispatch } from '../useAppDispatch';
import { State } from '../State';
import { amplifyApi } from '../../api/AmplifyApi';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';

export const initialState: ContractsChangelogsState = {
  key: '',
  pages: [],
  totalCount: 0,
  filterOptions: {
    ValidatingCarriers: null,
    Users: null,
    ContractTypes: null,
    ChangeDateTo: null,
    ChangeDateFrom: null
  }
};

const checkStateKey = (state: ContractsChangelogsState, params: ContractsChangelogSearchOptions) => {
  const key = contractsChangelogsKeySelector({}, params);
  if (state.key !== key) {
    state.key = key;
    state.pages = [];
    state.totalCount = 0;
  }
};

const slice = createSlice({
  name: "contractChangelogs",
  reducers: {
    loading: (state, action: PayloadAction<ContractsChangelogSearchOptions>) => {
      checkStateKey(state, action.payload);
      state.pages[action.payload.PageNumber ?? 0] = {
        loadState: {
          type: LoadStateType.Loading,
        },
      };
    },
    load: (state, {
      payload: {
        params,
        data,
        filterOptions
      }
    }: PayloadAction<ContractChangelogsPaginatedDataPayload>) => {
      checkStateKey(state, params);
      state.pages[params.PageNumber ?? 0] = {
        loadState: {
          type: LoadStateType.Loaded,
        },
        data: data.Data,
      };
      if (params.IncludeFilterOptions) {
        state.filterOptions = {
          ...filterOptions,
          ChangeDateFrom: params.ChangeDateFrom ?? null,
          ChangeDateTo: params.ChangeDateTo ?? null
        };
      }
      state.totalCount = data.TotalCount;
    },
    loadingError: (state, action: PayloadAction<PaginatedDataLoadErrorPayload<ContractsChangelogSearchOptions>>) => {
      checkStateKey(state, action.payload.params);
      state.pages[action.payload.params.PageNumber ?? 0] = {
        loadState: {
          type: LoadStateType.Error,
          errorMessage: action.payload.errorMessage,
        },
      };
    }
  },
  initialState: initialState
});


export const loadContractsChangelogs = (searchParams: ContractsChangelogSearchOptions) => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const contractsState = contractsChangelogsPageLoadStateSelector(state, searchParams);
    if (contractsState.type === LoadStateType.Loaded || contractsState.type === LoadStateType.Loading) {
      return;
    }

    dispatch(loading(searchParams));

    try {
      const queryStringParameters = createQueryParams(searchParams);
      const { Data, TotalCount, FilterOptions } =
        await amplifyApi.get<ContractChangelogsPaginatedResult>(apiNames.AomUI, apiPaths.contractsChangelogs, { queryStringParameters });
      dispatch(load({
        data: { Data, TotalCount },
        params: searchParams,
        filterOptions: FilterOptions
      }));
    } catch (e: any) {
      const errorMessage = e.response?.errors?.length ? e.response.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(loadingError({ params: searchParams, errorMessage }));
    }
  };
};

const createQueryParams = (params: ContractsChangelogSearchOptions) => {
  let queryParams: { [key: string]: unknown } = { ...params }

  for (const key in queryParams) {
    if ((queryParams[key] === null || queryParams[key] === undefined)) {
      delete queryParams[key];
    }
  }

  return queryParams;
}

export const { loading, loadingError, load } = slice.actions;

export const contractsChangelogsReducer = slice.reducer;
