import { createAction } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { RepositoryFile } from '../../api/types';
import { LoadStateType } from '../commonTypes';
import { State } from '../State';
import { modalLoadStateSelector } from './modalFilesSelector';
import { FilesPaginatedParams } from '../repository/repositoryTypes';
import { AppDispatch } from '../useAppDispatch';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';

export const loadNumberFiles = createAction<number>('modalFiles/loadNumberFiles');
export const load = createAction<{ repositoryFiles: RepositoryFile[]; pageNumber: number }>(
  'modalFiles/load'
);
export const loading = createAction<number>('modalFiles/loading');
export const loadingError = createAction<{ errorMessage: string; pageNumber: number }>(
  'modalFiles/loadingError'
);
export const deleteModalFileByPage = createAction<number>('modalFiles/deleteModalFile');
export const deleteModalFile = createAction<string>('modalFiles/deleteFile');
export const addModalFile = createAction<{ repositoryFile: RepositoryFile; pageSize: number }>(
  'modalFiles/addModalFile'
);

export const loadFilesModal = (modalFilesSearchParams: FilesPaginatedParams) => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    const pageNumber: number = modalFilesSearchParams?.PageNumber || 1;
    const state: State = getState();

    const modalState = modalLoadStateSelector(state, { currentPage: pageNumber });

    if (modalState?.type === LoadStateType.Loading) {
      return;
    }
    dispatch(loading(pageNumber));
    try {
      let repositoryFiles: RepositoryFile[], countFilesResponse: number;

      const response = await API.get(apiNames.AomUI, apiPaths.files, { queryStringParameters: modalFilesSearchParams });
      repositoryFiles = response.Data as RepositoryFile[];
      countFilesResponse = response.Count as number;

      dispatch(load({ repositoryFiles, pageNumber }));
      dispatch(loadNumberFiles(countFilesResponse));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(loadingError({ errorMessage, pageNumber }));
    }
  };
};
