import { API } from 'aws-amplify';
import { FilePageSearchOptions } from '../../../api/types';
import { mapOrderByToRequestType } from '../../../components/SortController/SortController';
import { repositoryOrderBy, repositorySortField, repositoryPageSize } from '../../../constants';
import apiNames from '../../../services/apiNames';
import apiPaths from '../../../services/apiPaths';
import { RouterFilePageState } from './ContractList';

export const getRouterFilePageState = async (fileName: string) => {
  const search = {
    FileName: fileName,
    PageSize: repositoryPageSize,
    OrderBy: mapOrderByToRequestType[repositoryOrderBy],
    SortFieldName: repositorySortField,
  } as FilePageSearchOptions;

  const response = await API.get(apiNames.AomUI, apiPaths.files + '/page', { queryStringParameters: search });
  return {
    fileName: response.FileName || fileName,
    pageNumber: response.PageNumber,
  } as RouterFilePageState;
};
