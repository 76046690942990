import React, { useEffect } from 'react';
import { loadProgramList } from '../../store/programList/programListSlice';
import { useAppDispatch } from '../../store/useAppDispatch';
import { Outlet } from 'react-router-dom';

export const Contracts: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadProgramList());
  }, []);

  return <Outlet />;
};
