import React, { useContext } from 'react';
import { Image, Label } from 'semantic-ui-react';
import logo from '../../../assets/images/logo_aom.svg';
import './AomLogo.scss';
import { ConfigurationContext } from '../../../services/configurationContext';
import { Environment } from '../../../services/utils/configuration';

const AomLogo: React.FC = () => {
  const configuration = useContext(ConfigurationContext);
  const environment = configuration?.environment;
  
  return (
    <div>
      <Image className="logo" src={logo} />
      {environment === null || environment === Environment.DEV || environment === Environment.TST ? (
        <></>
      ) : (
        <>
          <span className="vertical-line"></span>
          <Label className={`environment-label ${environment}`}>{environment?.toUpperCase()}</Label>
        </>
      )}
    </div>
  );
};

export default AomLogo;
