import { createAction } from '@reduxjs/toolkit';
import { State } from '../State';
import { mapFromAirports, mapFromRegions } from './marketDataMapping';
import { marketDataLoadStateSelector } from './marketDataSelectors';
import { MarketData } from './marketDataTypes';
import { LoadStateType } from '../commonTypes';
import { AppDispatch } from '../useAppDispatch';
import { Airport, AirportsSearchOptions, AirportsSearchResult, Region } from '../../api/types';
import { API } from 'aws-amplify';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';
import { airportRecordsBatchLimit } from '../../constants';
import { range } from 'lodash';
import { withLocalStorageCache } from './withLocalStorageCache';

export const loadMarketData = createAction<MarketData>('marketData/load');
export const loadingMarketData = createAction('marketData/loading');
export const loadingError = createAction<string>('marketData/loadingError');

export const loadMarketDataInfo = () => {
    return async (dispatch: AppDispatch, getState: () => State) => {
        const state: State = getState();

        const marketDataLoadState = marketDataLoadStateSelector(state);

        if (
            marketDataLoadState.type == LoadStateType.Loaded ||
            marketDataLoadState.type == LoadStateType.Loading
        ) {
            return;
        }
        dispatch(loadingMarketData());

        try {
            const [airports, regions] = await Promise.all([
                loadAllAirports(),
                loadAllRegions()
            ]);
            const marketData = mapFromRegions(
                regions,
                mapFromAirports(airports)
            );
            dispatch(loadMarketData(marketData));
        } catch (e: any) {
            const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
            dispatch(loadingError(errorMessage));
        }
    };
};

const loadAllRegions = async () => {
    const fetchFromApi = async (): Promise<Region[]> => {
        const regions = await API.get(apiNames.AomUI, apiPaths.regions, {});
        return regions ?? [];
    }

    return await withLocalStorageCache<Region[]>('regions', fetchFromApi);
}

const loadAllAirports = async () => {
    const fetchFromApi = async () => {
        const fetchBatch = async (searchOptions: Partial<AirportsSearchOptions>): Promise<AirportsSearchResult> => {
            return await API.get(apiNames.AomUI, apiPaths.airports, {queryStringParameters: searchOptions});
        }

        const firstBatchWithCount = await fetchBatch({
            Skip: 0,
            Limit: airportRecordsBatchLimit,
            IncludeTotalCount: true
        });
        const numberOfRequests = Math.ceil((firstBatchWithCount.TotalCount ?? 0) / airportRecordsBatchLimit);
        const responses = await Promise.all(range(1, numberOfRequests)
            .map(requestIndex => fetchBatch({
                Skip: requestIndex * airportRecordsBatchLimit,
                Limit: airportRecordsBatchLimit
            })));
        const allResponses = [...responses, firstBatchWithCount];
        return allResponses.flatMap(response => response.Data ?? []);
    }

    return await withLocalStorageCache<Airport[]>('airports', fetchFromApi);
}


