import React, { useState } from 'react';
import { Noop } from 'react-hook-form';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import settings from '../../../settings';

export interface SingleDatesSelectorProps {
  label?: string;
  format?: string;
  onChange: (...event: any[]) => void;
  onBlur: Noop;
  value?: Date | Date[] | null;
  disabled?: boolean;
  clearable?: boolean;
  maxDate?: Date;
}

const SingleDatesSelector: React.FunctionComponent<SingleDatesSelectorProps> = ({
  label,
  onChange,
  onBlur,
  value,
  maxDate,
  format = settings.dateFormat,
  disabled = false,
  clearable = true,
}) => {
  const [openDatepickerOnSelect, setOpenDatepickerOnSelect] = useState<boolean>(false);
  return (
    <SemanticDatepicker
      disabled={disabled}
      clearable={clearable}
      label={label || ''}
      clearOnSameDateClick={false}
      format={format}
      keepOpenOnClear={true}
      keepOpenOnSelect={openDatepickerOnSelect}
      onChange={(e, data) => {
        onChange(data.value);
        setOpenDatepickerOnSelect(!e);
      }}
      onBlur={onBlur}
      value={value}
      maxDate={maxDate}
    />
  );
};

export default SingleDatesSelector;
