import moment from 'moment';
import { Icon, Popup } from 'semantic-ui-react';
import { AuditInfo } from '../../api/types';
import settings from '../../settings';
import React from 'react';
import './AuditUserInfo.css';

export interface AuditUserInfoProps {
  data?: AuditInfo | null;
  hideDate?: boolean;
  className?: string;
}

const AuditUserInfo: React.FunctionComponent<AuditUserInfoProps> = ({ data, hideDate, className }) => {
  if (!data) {
    return null;
  }

  const textToDisplay = (data: AuditInfo) => {
    const fullName = ` ${data.FirstName || ''} ${data.LastName || ''}`;

    if (hideDate) {
      return fullName;
    }

    const utcDate = moment(data.Date).utc().format(settings.dateTimeFormat);
    return `${fullName} ${utcDate} UTC`;
  };

  const onClick = () => navigator.clipboard.writeText(data.Email as string);

  return (
    <>
      {data.Email && (
        <Popup content={data.Email} trigger={<Icon name="copy outline" link onClick={onClick} />} />
      )}
      <span className={`user-info ${className}`}>{textToDisplay(data)}</span>
    </>
  );
};

export default AuditUserInfo;
