import React, { useEffect } from 'react';
import MainMenu from '../components/MainMenu';
import AomHeader from '../components/AomHeader';
import { load } from '../store/currentUser/currentUserActions';
import { useSelector } from 'react-redux';
import { userIsLoggedInSelector } from '../store/currentUser/currentUserSelectors';
import './PrivateApp.scss';
import AomLoader from '../components/AomLoader';
import Confirmation from '../components/Confirmation';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useAppDispatch } from '../store/useAppDispatch';
import { noop } from 'lodash';
import { createUserFromAttributes } from '../services/utils/createUser';
import { Auth, Hub } from 'aws-amplify';
import { redirectUrlKey } from '../constants';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

let refreshTimeout: NodeJS.Timeout;
let clickCount = 0;

export const PrivateApp = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(userIsLoggedInSelector);
  const navigate = useNavigate();
  const location = useLocation();
  
  if (!isLoggedIn) {
    const currentLocation = location.pathname.toString();
    if (currentLocation !== '/') {
      sessionStorage.setItem(redirectUrlKey, currentLocation); 
    }
  } else {
    sessionStorage.removeItem(redirectUrlKey);
  }
  const unsubscribe = Hub.listen('auth', (data) => {
    if (data.payload.event === 'signIn') {
      const redirectUrl = sessionStorage.getItem(redirectUrlKey);
      if (redirectUrl === null) {
        navigate({ pathname: '/' });
        unsubscribe();
        return;
      }

      unsubscribe();      
      navigate({ pathname: redirectUrl }, { replace: true });
    }
  });
  
  useEffect(() => {
    const setRefresh = (session: CognitoUserSession) => {
      clickCount = 0;
      const timeout = session.getIdToken().getExpiration() * 1000 - new Date().getTime() - 60000;
      refreshTimeout = setTimeout(refreshSession as () => void, timeout);
    };

    const refreshSession = async () => {
      if (!clickCount) {
        navigate('/auth/login');
        return;
      }
      const cognitoUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err: any, session: CognitoUserSession) => {
          if (err) {
            navigate('/auth/login');
            return;
          }
          setRefresh(session);
        }
      );
    };

    const setupAuthentication = async () => {
      try {
        const cognitoUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
        const attributes = await Auth.userAttributes(cognitoUser);
        const user = createUserFromAttributes(attributes || []);
        dispatch(load(user));
        const currentSession = await Auth.currentSession();
        setRefresh(currentSession);
      } catch (e) {
        navigate('/auth/login');
      }
    };

    setupAuthentication().catch(noop);

    document.onclick = () => clickCount++;

    return () => {
      clearTimeout(refreshTimeout);
      document.onclick = null;
      clickCount = 0;
    };
  }, []);
  
  return !isLoggedIn ? (
    <AomLoader />
  ) : (
    <>
      <MainMenu>
        <div className="main-content">
          <AomHeader />
          <Outlet />
        </div>
      </MainMenu>
      <Confirmation />
    </>
  );
};