import { createSelector } from '@reduxjs/toolkit';
import { Program } from '../../api/types';
import { IdParams, LoadState, LoadStateType, PaginatedDataParams, SortedDataParams } from '../commonTypes';
import { marketDataSelector } from '../marketData/marketDataSelectors';
import { State, stateSelector } from '../State';
import { createParameterSelector } from '../useParamsSelector';
import { mapFromStoreModel } from './programMapping';
import { newProgramId } from './programReducer';
import { ProgramStatus, ProgramStatusProps } from './programTypes';

export const programsSelector = (state: State) => state.program;

const idParamSelector = createParameterSelector<IdParams<number>, number>((params) => params.id);

const programDataSelector = createSelector(stateSelector, idParamSelector, (state, id) => state.program[id]);

export const programSelector = createSelector(programDataSelector, (programData) => programData?.data);

export const programViewModelSelector = createSelector(programSelector, marketDataSelector, (program, marketData) => {
  return mapFromStoreModel(program as Program, marketData);
});

const notLoadedState: LoadState = { type: LoadStateType.NotLoaded };

export const programLoadStateSelector = createSelector(
  programDataSelector,
  (programData) => programData?.loadState || notLoadedState
);

export const programCreateStateSelector = (state: State) =>
  state.program[newProgramId]?.loadState || notLoadedState;

export const getProgramStatusProps = (program?: Program | null) => {
  const result: ProgramStatusProps = {};

  if (!program) {
    return result;
  }

  if (program.Status === ProgramStatus.Draft) {
    result.edit = true;
  } else if (program.Status === ProgramStatus.Active) {
    result.update = true;
  }

  return result;
};

export const programStatusPropsSelector = createSelector(programSelector, getProgramStatusProps);

const pageNumberParamSelector = createParameterSelector<PaginatedDataParams, number>(
  (params) => params.PageNumber || 0
);
const pageSizeParamSelector = createParameterSelector<PaginatedDataParams, number>(
  (params) => params.PageSize || 0
);
const orderByParamSelector = createParameterSelector<SortedDataParams, boolean | null>(
  (params) => params.OrderBy
);

export const programChangelogsKeySelector = createSelector(
  pageSizeParamSelector,
  orderByParamSelector,
  (pageSize, orderBy) => `${pageSize}_${orderBy}`
);

const programChangelogsPageStateSelector = createSelector(
  programSelector,
  programChangelogsKeySelector,
  pageNumberParamSelector,
  (program, key, pageNumber) =>
    program?.changelogs?.key === key ? program.changelogs.pages?.[pageNumber] : undefined
);

export const programChangelogsPageLoadStateSelector = createSelector(
  programChangelogsPageStateSelector,
  (page) => page?.loadState || notLoadedState
);

export const programChangelogsPageSelector = createSelector(
  programChangelogsPageStateSelector,
  (page) => page?.data
);

export const programChangelogsTotalCountSelector = createSelector(
  programSelector,
  programChangelogsKeySelector,
  (program, key) => (program?.changelogs?.key === key ? program?.changelogs?.totalCount : 0)
);
