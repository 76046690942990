import React from 'react';
import logo from '../../assets/images/logo_aom.svg';
import './Authentication.scss';
import { Outlet } from 'react-router-dom';

export const Authentication: React.FunctionComponent = () => {
  const year = new Date().getFullYear();
  return (
    <div className="authentication">
      <div className="form-container">
        <div className="form">
          <a href="https://www.bcdtravel.com/">
            <img className="logo" src={logo} alt="BCD" />
          </a>
          <div>
            <Outlet />
          </div>
        </div>
        <div className="form-container-footer">
          &copy; {year} BCD Travel, All rights reserved.
          <br />
          <br />
          TripSource&reg; and BCD Travel&reg; are registered trademarks of BCD Group.
          <br />
          <br />
          <a href="https://www.bcdtravel.com/privacy-policy/" className="link">
            Privacy policy
          </a>
          <a href="https://www.bcdtravel.com/legal/" className="link">
            Terms and conditions
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="info">
          <div>
            The workforce is changing.
            <br />
            Transform how you manage travel.
          </div>
          <h1>First impressions last.</h1>
        </div>
      </div>
    </div>
  );
};
