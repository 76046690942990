import { createAction } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import {
  Carrier,
  OperatingCarriersRule,
} from '../../api/types';
import { LoadStateType } from '../commonTypes';
import { State } from '../State';
import { carriersLoadStateSelector } from './carriersSelectors';
import { AppDispatch } from '../useAppDispatch';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';

export const loading = createAction('carriers/loading');
export const load = createAction<Carrier[]>('carriers/load');
export const loadingError = createAction<string>('carriers/loadingError');

export const loadCarriers = () => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const loadState = carriersLoadStateSelector(state);
    if (loadState.type === LoadStateType.Loaded || loadState.type === LoadStateType.Loading) {
      return;
    }

    dispatch(loading());
    try {
      let carriers: Carrier[], operatingCarrierRules: OperatingCarriersRule[]; 
      carriers = await API.get(apiNames.AomUI, apiPaths.carriers, {}) as Carrier[];
      operatingCarrierRules = await API.get(apiNames.AomUI, apiPaths.operatingCarrierRules, {}) as OperatingCarriersRule[];

      mergeCarriersWithRules(carriers, operatingCarrierRules);
      dispatch(load(carriers));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(loadingError(errorMessage));
    }
  };
};

const mergeCarriersWithRules = (carriers: Carrier[], operatingCarrierRules: OperatingCarriersRule[]) => {
  operatingCarrierRules.forEach((rule) => {
    const carrier = carriers.find((c) => c.Code == rule.OperatingCarrier);
    if (carrier) {
      carrier.Rules = rule;
    }
  });
};
