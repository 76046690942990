import { createReducer } from '@reduxjs/toolkit';
import { LoadStateType } from '../commonTypes';
import { load, loading, loadingError } from './carriersActions';
import { CarriersData, CarriersState } from './carriersTypes';
export const initialState: CarriersState = {
  loadState: {
    type: LoadStateType.NotLoaded,
  },
};

const carriersReducer = createReducer<CarriersState>(
  initialState,
  (builder) => {
    builder.addCase(loading, () => {
      return {
        loadState: {
          type: LoadStateType.Loading,
        },
      };
    });
    builder.addCase(load, (state, action) => {
      state.loadState = {
        type: LoadStateType.Loaded,
      };
      state.data = action.payload.reduce((result, c) => {
        result[c.Code] = c;
        return result;
      }, {} as CarriersData);
    });
    builder.addCase(loadingError, (_, action) => {
      return {
        loadState: {
          type: LoadStateType.Error,
          errorMessage: action.payload,
        },
      };
    });
  }
);

export default carriersReducer;
