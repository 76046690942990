import { createSelector } from '@reduxjs/toolkit';
import { escapeRegExp, memoize } from 'lodash';
import { parseOptionCategory } from '../../components/LocationsSelector/LocationsSelector';
import filterArrayLimit from '../../services/utils/filterArrayLimit';
import { State } from '../State';
import { distinct } from './marketDataMapping';
import { Destination, DestinationSet, MarketData, OptionCategory, SelectedOption } from './marketDataTypes';

export const marketDataSelector = (state: State) =>
  state.marketData.data;

export const marketDataLoadStateSelector = (state: State) => state.marketData.loadState;

const pushDestination = (result: DestinationSet, destination: Destination) => {
  if (!result[destination.type]) {
    result[destination.type] = [];
  } else {
    if (result[destination.type]?.find(d => d.code === destination.code)) {
      return;
    }
  }

  result[destination.type]?.push(destination);

  if (!destination.innerDestinations) {
    return;
  }

  Object.keys(destination.innerDestinations).forEach(k => {
    const type = parseOptionCategory(k);
    destination.innerDestinations?.[type]?.forEach(d => pushDestination(result, d));
  });
};

export const filteredMarketDataSelector = memoize(
  (filter?: SelectedOption[]) =>
    createSelector(marketDataSelector, marketData => {
      if (!filter?.length) {
        return marketData;
      }

      const result: MarketData = {
        destinations: {},
      };

      filter.sort((o1, o2) => o1.type - o2.type);
      filter.forEach(o => {
        const optionData = marketData?.destinations[o.type]?.find(
          m => m.code === o.value || m.name === o.value
        );
        if (!optionData) {
          return;
        }

        pushDestination(result.destinations, optionData);
      });

      Object.keys(result.destinations).forEach(k => {
        const type = parseOptionCategory(k);
        result.destinations[type]?.sort((d1, d2) => d1.name.localeCompare(d2.name));
      });

      return result;
    }),
  filter => filter ? JSON.stringify(filter) : ''
);

const maxSearchResultCount = 50;
const maxResultCount = 5;

const isAdditionalInfoMatches =  (searchRegex: RegExp, destination: Destination) => destination.additionalInfo &&
  Object.keys(destination.additionalInfo)
    .map(key=> destination.additionalInfo?.[key] ?? '')
    .find(info => searchRegex.test(info));
const isNameMatches = (searchRegex: RegExp, destination: Destination) => searchRegex.test(destination.name);
const isCodeMatches = (searchRegex: RegExp, destination :Destination) => searchRegex.test(destination.code);
const isMatchDestination = (searchRegex: RegExp, destination: Destination)  =>
  isNameMatches(searchRegex, destination) ||
  isCodeMatches(searchRegex, destination) ||
  isAdditionalInfoMatches(searchRegex, destination);

const searchDestinations = (searchQuery: string, areas: Destination[]): Destination[] => {
  const regExp = new RegExp(escapeRegExp(searchQuery), 'i');

  const canBeCode = (query: string) => query.length <= 3;
  const result: Destination[] = canBeCode(searchQuery) 
    ? filterArrayLimit(areas, destination => isCodeMatches(regExp, destination), maxSearchResultCount) 
    : [];

  if (result.length >= maxSearchResultCount) {
    return result;
  }

  const otherMatches = filterArrayLimit(
    areas,
    destination =>
      isMatchDestination(regExp, destination) && !result.includes(destination),
    maxSearchResultCount - result.length
  );

  return [...result, ...otherMatches];
}

const toDestination = (option: SelectedOption, areas: Destination[]) => {
  const defaultDestination = { code: option.value } as Destination;
  const destination = areas.find(area =>
    area.code === option.value || area.name === option.value);

  return destination ?? defaultDestination;
}

const createMarketDataSelector =
  (type: OptionCategory) =>
  (searchQuery: string, selected: SelectedOption[] | null | undefined, filter?: SelectedOption[]) =>
    createSelector(filteredMarketDataSelector(filter), marketData => {
      const areas = marketData?.destinations?.[type];
      if (!areas) {
        return [];
      }
      
      if (searchQuery.length) {
        return searchDestinations(searchQuery, areas);
      }

      const limitByCountAreas = areas.slice(0, maxResultCount);
      if (!selected?.length) {
        return limitByCountAreas;
      }

      const selectedValues = selected
        .filter(option => option.type == type)
        .map(option => toDestination(option, areas)) ?? [];
      
      return distinct(selectedValues.concat(limitByCountAreas));
    });

export const marketDataTrafficConferencesSearchSelector = createMarketDataSelector(
  OptionCategory.TrafficConference
);

export const marketDataRegionsSearchSelector = createMarketDataSelector(OptionCategory.Region);

export const marketDataZonesSearchSelector = createMarketDataSelector(OptionCategory.Zone);

export const marketDataCountriesSelector = createSelector(
  marketDataSelector,
  marketData => marketData?.destinations?.[OptionCategory.Country] || []
);

export const marketDataCountriesSearchSelector = createMarketDataSelector(OptionCategory.Country);

export const marketDataMetroAreasSearchSelector = createMarketDataSelector(OptionCategory.MetroArea);

export const marketDataAirportsSearchSelector = createMarketDataSelector(OptionCategory.Airport);

export const marketDataStatesSearchSelector = createMarketDataSelector(OptionCategory.State);

export const marketDataCountriesByCodeSelector = (codes: string[]) => (_: State) => codes.map(code => ({ name: code, code: code }));

const searchSelectorList: Array<typeof marketDataTrafficConferencesSearchSelector> = [];
searchSelectorList[OptionCategory.TrafficConference as number] = marketDataTrafficConferencesSearchSelector;
searchSelectorList[OptionCategory.Region as number] = marketDataRegionsSearchSelector;
searchSelectorList[OptionCategory.Zone as number] = marketDataZonesSearchSelector;
searchSelectorList[OptionCategory.Country as number] = marketDataCountriesSearchSelector;
searchSelectorList[OptionCategory.State as number] = marketDataStatesSearchSelector;
searchSelectorList[OptionCategory.MetroArea as number] = marketDataMetroAreasSearchSelector;
searchSelectorList[OptionCategory.Airport as number] = marketDataAirportsSearchSelector;

export const marketDataSearchSelector = (
  searchQuery: string,
  selected: SelectedOption[],
  filter?: SelectedOption[],
  minAreaLevel?: OptionCategory
) =>
  createSelector(
    searchSelectorList
      .filter((_, i) => minAreaLevel === undefined || i <= minAreaLevel)
      .map(s => s(searchQuery, selected, filter)),
    (...marketData) =>
      marketData.reduce((acc, m, i) => {
        acc[i as OptionCategory] = m;
        return acc;
      }, {} as { [key in OptionCategory]?: any[] })
  );
