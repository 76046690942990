const filterArrayLimit = <T>(
  array: Array<T>,
  filter: (item: T) => boolean | string | undefined,
  limit: number
): Array<T> => {
  const result: Array<T> = [];
  for (let i = 0; i < array.length && limit > 0; i++) {
    if (filter(array[i])) {
      result.push(array[i]);
      limit--;
    }
  }
  return result;
};

export default filterArrayLimit;
