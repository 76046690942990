import React from 'react';
import './index.css';
import 'semantic-ui-less/semantic.less';
import 'material-icons/iconfont/outlined.scss';
import { App } from './app/App';
import { Configuration, fetchConfiguration } from './services/utils/configuration';
import { createRoot } from "react-dom/client";
import { Amplify } from 'aws-amplify';
import apiNames from './services/apiNames';
import { Auth } from '@aws-amplify/auth';

fetchConfiguration()
  .then((config) => Amplify.configure(toAmplifyConfiguration(config)))
  .then(() => {
    const elementById = document.getElementById('root') as HTMLElement;
    createRoot(elementById).render(<React.StrictMode><App /></React.StrictMode>);
  })
  .catch((e) => {
    console.log(e);
  });

const toAmplifyConfiguration = (config: Configuration): unknown => {
  return {
    Auth: {
      mandatorySignIn: true,
      region: config.authRegion,
      userPoolId: config.authUserPoolId,
      userPoolWebClientId: config.authUserPoolWebClientId,
      identityPoolId: config.identityPoolId,
      oauth: {
        domain: config.cognitoEndpoint,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: config.redirectUrl,
        redirectSignOut: config.redirectUrl,
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: apiNames.AomUI,
          endpoint: config.uiApiEndpoint,
          custom_header: async () => {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            return {
              Authorization: `Bearer ${ token ?? '' }`,
            };
          },
        },
        {
          name: apiNames.Tools,
          endpoint: config.uiApiEndpoint,
          custom_header: async () => {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            return {
              Authorization: `Bearer ${ token ?? '' }`,
            };
          },
        }
      ]
    },
    Storage: {
      bucket: config.s3_bucket,
      region: config.s3_region,
      identityPoolId: config.identityPoolId,
      level: 'public',
    }
  }
};
