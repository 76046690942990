import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { Configuration, fetchConfiguration } from './utils/configuration';
import apiNames from './apiNames';
import { Auth } from '@aws-amplify/auth';

export const ConfigurationContext = createContext<Configuration | null>(null);

export const ConfigurationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [configuration, setConfiguration] = useState<Configuration | null>(null);
  
  useEffect(() => {
    fetchConfiguration()
      .then(config => {
        setConfiguration(config);
      });
  }, []);

  return <ConfigurationContext.Provider value={ configuration }>{ children }</ConfigurationContext.Provider>;
}

  

