import React from 'react';
import { Icon, Message } from 'semantic-ui-react';

interface ErrorProps {
  message?: string | null;
}

const ErrorMessage: React.FunctionComponent<ErrorProps> = ({ message }) => {
  return (
    <Message warning>
        <Message.Header>
          <Icon name="exclamation circle" />
          Error
        </Message.Header>
        <p>Error loading data: {message}</p>
        <p>Please refresh the page</p>
      </Message>
  );
};

export default ErrorMessage;
