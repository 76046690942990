export enum RuleName {
  arrivalAirportCodeIn = 'arrivalAirportCodeIn',
  arrivalCountryCodeIn = 'arrivalCountryCodeIn',
  arrivalMetroAreaCodeIn = 'arrivalMetroAreaCodeIn',
  arrivalRegionIn = 'arrivalRegionIn',
  arrivalStateCodeIn = 'arrivalStateCodeIn',
  arrivalTrafficConferenceIn = 'arrivalTrafficConferenceIn',
  arrivalZoneIn = 'arrivalZoneIn',
  blackOutDatesBetween = 'blackOutDatesBetween',
  classOfServiceIn = 'classOfServiceIn',
  departureAirportCodeIn = 'departureAirportCodeIn',
  departureCountryCodeIn = 'departureCountryCodeIn',
  departureMetroAreaCodeIn = 'departureMetroAreaCodeIn',
  departureStateCodeIn = 'departureStateCodeIn',
  departureRegionIn = 'departureRegionIn',
  departureTrafficConferenceIn = 'departureTrafficConferenceIn',
  departureZoneIn = 'departureZoneIn',
  fareBasisIn = 'fareBasisIn',
  marketingAirlineCodeIn = 'marketingAirlineCodeIn',
  marketIn = 'marketIn',
  operatingAirlineCodeIn = 'operatingAirlineCodeIn',
  pooCountryCodeIn = 'pooCountryCodeIn',
  pooZoneIn = 'pooZoneIn',
  pooRegionIn = 'pooRegionIn',
  pooTrafficConferenceIn = 'pooTrafficConferenceIn',
  posCountryCodeIn = 'posCountryCodeIn',
  ticketDesignatorIn = 'ticketDesignatorIn',
  ticketedDateBetween = 'ticketedDateBetween',
  tourCodeIn = 'tourCodeIn',
  travelCommencementDateBetween = 'travelCommencementDateBetween',
  travelCompleteDateBetween = 'travelCompleteDateBetween',
  validatingCarrierCodeIn = 'validatingCarrierCodeIn',
  flightNumberNotIn = 'flightNumberNotIn',
  gdsIn = 'gdsIn',
}
