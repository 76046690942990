import { Draft } from '@reduxjs/toolkit';
import { Program, RepositoryFile } from '../api/types';
import { ModalFilesState } from './modalFiles/modalFilesTypes';
import { RepositoryState } from './repository/repositoryTypes';
import { ProgramListItem } from './programList/programListTypes';
import { RuleName } from '../services/rules/RuleName';
import { ProgramStatus } from './program/programTypes';

export interface FileOnPage {
  file: Draft<RepositoryFile>;
  page: number;
}

export const findFileOnPage = (state: Draft<RepositoryState> | Draft<ModalFilesState>, fileName: string) => {
  const pageNumbers = Object.keys(state.files).map((x) => parseInt(x));
  for (let i = 0; i < pageNumbers.length; i++) {
    const file = state.files[pageNumbers[i]].data?.find((x) => `${x.Name}.${x.Extension}` === fileName);
    if (file) {
      return { file, page: pageNumbers[i] } as FileOnPage;
    }
  }
};

export const findFilesByContractId = (
  state: Draft<RepositoryState> | Draft<ModalFilesState>,
  contractId: number
) => {
  const pageNumbers = Object.keys(state.files).map((x) => parseInt(x));
  const filesList = [];
  for (const pageNumber of pageNumbers) {
    const files = state.files[pageNumber].data?.filter((c) => c.ContractId === contractId);

    if (files) {
      filesList.push(...files);
    }
  }
  return filesList;
};

export const toProgramListItem = (newProgram: Program): ProgramListItem => {

  const uniqueValidatingCarriers = Array.from(new Set(newProgram.Definitions?.flatMap(combination =>
    combination.Rules.filter(x => x.RuleName == RuleName.validatingCarrierCodeIn) ?? [])
    .flatMap(rule => rule.Values?.filter(code => code) as string[] ?? []) ?? []));

  const getEndDate = (newProgram: Program): string => {
    const definitions = newProgram.Definitions ?? [];
    if (definitions == null) {
      return null!;
    }
    const ticketedDateBetween = definitions.flatMap(combination =>
      combination.Rules.filter(rule => rule.RuleName == RuleName.ticketedDateBetween));

    if (ticketedDateBetween == null) {
      return null!;
    }

    const lastTicketDateIndex = 1;
    const lastTicketDates = ticketedDateBetween.filter(rule => rule.Values ?? [])
      .map(rule => rule.Values![lastTicketDateIndex]);
    
    return lastTicketDates[0]!;
  }

  return {
    ContractType: newProgram.Contract.Type!,
    EndDate: getEndDate(newProgram),
    Id: newProgram.Id,
    ProgramName: newProgram.ProgramName,
    Status: newProgram.Status as ProgramStatus,
    ValidatingCarriers: uniqueValidatingCarriers
  };
}
