import { fetchConfiguration } from '../../services/utils/configuration';

type CachedValue<T> = {
    payload: T,
    createdAt: string
}
class LocalStorageCache {
    static getValue<T>(key: string, ttl: number): T | null {
        const rawValue = localStorage.getItem(key);
        if (rawValue === null) {
            return null;
        }
        const value = JSON.parse(rawValue) as CachedValue<T>;

        const isExpired = Date.now() - new Date(value.createdAt).getTime() > ttl;
        if (isExpired) {
            localStorage.removeItem(key);
            return null;
        }

        return value.payload;
    }

    static setValue<T>(key: string, value: T): void {
        const cacheValue = {payload: value, createdAt: new Date().toUTCString()};
        const rawValue = JSON.stringify(cacheValue);
        localStorage.setItem(key, rawValue);
    }
}

export const withLocalStorageCache = async <T>(cacheKey: string, valueProvider: () => Promise<T>): Promise<T> => {
    const config = await fetchConfiguration();
    const cached = LocalStorageCache.getValue<T>(cacheKey, config.localStorageCacheLifetimeMs);
    if (cached !== null) {
        return cached;
    }
    const value = await valueProvider();
    LocalStorageCache.setValue(cacheKey, value);

    return value;
}