import { Account, ContractFilterOptions } from '../../api/types';
import {
  PaginatedDataLoadPayload,
  PaginatedDataParams,
  PaginatedDataState,
  SortedDataParams,
} from '../commonTypes';
import { ContractListFilters } from '../../app/contracts/ContractList/ContractListFilterPanel';

export interface ContractListItem {
  Id: number;
  Name: string;
  BusinessId: number;
  EndDate?: string | null;
  FileName?: string | null;
  Accounts?: Array<Account> | null;
  Programs?: Array<{ Id: number; ProgramName: string }>;
  Status: number;
  ValidatingCarriers?: Array<string> | null;
  Version?: number | null;
  Versions?: Array<number> | null;
}

export type ContractListState = PaginatedDataState<ContractListItem> & {
  filterOptions?: ContractFilterOptions | null;
  selectedFilters: SerializableContractListFilters;
  activePage: number;
};

type SerializableContractListFilters =  Omit<ContractListFilters, 'EndDate'> & { EndDate?: Array<string>; } 

export interface ContractListPaginatedParams extends PaginatedDataParams, SortedDataParams {
  SearchQuery?: string;
  HasFileName?: boolean;
  IncludeFilterOptions?: boolean;
  EndDate?: Date[];
  Status?: Array<number> | null;
  ValidatingCarriers?: Array<string> | null;
  Customers?: Array<string> | null;
  Version?: Array<number | null> | null;
  ContractTypes?: Array<string> | null;
}

export enum ContractStatus {
  Draft = 0,
  Active = 1,
  Inactive = 2,
  Archived = 3
}

export interface ContractListPaginatedDataLoadPayload
  extends PaginatedDataLoadPayload<ContractListItem, ContractListPaginatedParams> {
  filterOptions?: ContractFilterOptions | null;
}
