import settings from '../../settings';
import { API } from 'aws-amplify';
import { RepositoryFile } from '../../api/types';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';

//https://docs.amplify.aws/lib/storage/download/q/platform/js/#get
export const downloadBlob = (blob: any, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
};

export const getFileUploadErrorMessage = async (file: File) => {
  const fileExtension = `.${file.name.split('.').pop()}`;
  const fileName = file.name.split('.')[0];
  if (!settings.acceptFileExtensions.some((ext) => ext === fileExtension)) {
    return 'Invalid file type';
  }
  try {
    let fileCount: number;
    const response = await API.get(apiNames.AomUI, apiPaths.files, { queryStringParameters: { FileName: fileName, CountOnly: true } });
    fileCount = response.Count as number;
    if (fileCount > 0) {
      return 'File with this name already exists in the Repository. You can select it from there if needed.';
    }
  } catch (e: any) {
    return e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
  }
};

export const formatCodeshareComment = (comment: string | undefined) => {
  if (!comment) {
    return '';
  }

  const words = comment.split(' ');
  return words.map((word) => word[0] + word.substring(1).toLowerCase()).join(' ');
};

export const getFullFileName = (file: RepositoryFile) => {
  return `${file.Name}.${file.Extension}`;
};
